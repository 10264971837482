import { useCallback, useEffect, useMemo } from 'react';

import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { getOrderPath, getOrderStep } from 'modules/orders/v1/helpers';
import { paths } from 'paths';
import { useAppDispatch, useAppSelector } from 'store';
import { setCurrentStep } from 'store/orders/v1/reducers';
import { PersistService } from 'utils/persist';

import { useInitOrderStateFromParams } from './useInitOrderStateFromParams';
import { useOrderFlow } from './useOrderFlow';

export function useSelectedOrderTypeGuard() {
  const router = useRouter();
  const navigationType = useNavigationType();
  const { pathname } = useLocation();
  const { selectedNetworkType, currentStep } = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();
  const isVPNFlow = useMemo(() => !!PersistService.get('vpn-flow'), []);

  const { initialized } = useInitOrderStateFromParams();
  const { isValidNetworkType } = useOrderFlow();

  const redirectToPath = useCallback(
    (path: string) => {
      if (pathname === path) return;

      return router.replace(path);
    },
    [pathname, router],
  );

  useEffect(() => {
    if (!initialized) return;

    /**
     * VPN flow
     * Redirect to the VPN index order page from any other page
     */
    if (isVPNFlow && !selectedNetworkType && pathname !== paths.order.vpn) {
      return router.replace(paths.order.vpn);
    }

    /**
     * Proxy flow
     * Redirect to the Proxy index order page from any other page
     */
    if (!isVPNFlow && !selectedNetworkType && pathname !== paths.order.index) {
      dispatch(setCurrentStep(null));

      return router.replace(paths.order.index);
    }

    /**
     * Both flows
     * Redirect to Not Found page if user tries to break the whole flow
     */
    if (!isVPNFlow && !isValidNetworkType && pathname !== paths.order.index) {
      return router.replace(paths.notFound);
    }

    /**
     * Both flows
     * Redirect to the proper page depending on the current step
     */
    return redirectToPath(getOrderPath(currentStep));
  }, [
    currentStep,
    dispatch,
    initialized,
    isVPNFlow,
    isValidNetworkType,
    pathname,
    redirectToPath,
    router,
    selectedNetworkType,
  ]);

  useEffect(() => {
    if (navigationType !== NavigationType.Pop) return;

    // Handle native browser behavior and change step in redux
    dispatch(setCurrentStep(getOrderStep(pathname)));
  }, [dispatch, navigationType, pathname]);
}
