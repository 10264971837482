import { useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { Outlet, useLocation } from 'react-router-dom';

import { useCreditCard } from 'hooks/useCreditCard';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { paths } from 'paths';

import { OrderDisabled } from './OrderDisabled';
import { OrderNavigation } from './OrderNavigation';
import { useSelectedOrderTypeGuard } from '../hooks/useSelectedOrderTypeGuard';

export function OrderLayout() {
  const { isOrderingEnabled, isFeatureFlagsLoading } = useFeatureFlag();
  const { isCardLoading } = useCreditCard();
  const { pathname } = useLocation();

  useSelectedOrderTypeGuard();

  const { maxWidth, paddingRight, offset } = useMemo(() => {
    if (pathname === paths.order.index) {
      return { maxWidth: 976, paddingRight: 0, offset: 64 };
    }

    return { maxWidth: 576, paddingRight: 'var(--OrderSidebar-width)', offset: 48 };
  }, [pathname]);

  const content = useMemo(() => {
    if (isFeatureFlagsLoading || isCardLoading) {
      return (
        <Stack flex="1 1 auto" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress size={64} />
        </Stack>
      );
    }

    if (!isOrderingEnabled) {
      return <OrderDisabled />;
    }

    return (
      <Stack
        pr={{ xs: 0, lg: paddingRight }}
        sx={{
          '--OrderContent-paddingBlock': {
            xs: 'calc(var(--OrderNavigation-height) + 32px)',
            md: `calc(var(--OrderNavigation-height) + ${offset}px)`,
          },
          '--OrderContent-paddingInline': { xs: '16px', md: '0px' },
          '--OrderContent-padding':
            'var(--OrderContent-paddingBlock) var(--OrderContent-paddingInline) calc(var(--OrderContent-paddingBlock) + 64px) var(--OrderContent-paddingInline)',
          '--OrderContent-maxWidth': `${maxWidth}px`,
        }}
      >
        <Stack
          p="var(--OrderContent-padding)"
          m="var(--OrderContent-margin)"
          width="var(--OrderContent-width)"
          maxWidth="var(--OrderContent-maxWidth)"
        >
          <Outlet />
        </Stack>
      </Stack>
    );
  }, [isCardLoading, isFeatureFlagsLoading, isOrderingEnabled, maxWidth, offset, paddingRight]);

  return (
    <Stack
      component="main"
      flex="1 1 auto"
      minHeight="100%"
      bgcolor={{ xs: 'var(--mui-palette-background-paper)', md: 'var(--mui-palette-background-default)' }}
      sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
    >
      <OrderNavigation />

      {content}
    </Stack>
  );
}
