import { type ReactNode, useCallback, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon, type IconProps } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useBalance } from 'hooks/useBalance';
import { useSession } from 'hooks/useSession';
import { useTopUpModalContext } from 'modules/billing/modals/TopUpModal/context';
import { TopUpStatus } from 'store/accounts/types';
import type { Nullable } from 'types';

import { useTopUpStatus } from './useTopUpStatus';

type BaseVariant = {
  title?: Nullable<string>;
  subtitle?: Nullable<string>;
  action?: ReactNode;
};

type IconVariant = BaseVariant & {
  variant: 'icon';
  icon: IconProps;
};

type LoaderVariant = BaseVariant & {
  variant: 'loader';
};

type Variant = IconVariant | LoaderVariant;

function isIconVariant(variant: Variant): variant is IconVariant {
  return variant.variant === 'icon';
}

type StatusStepProps = {
  isOrderFlow: boolean;
};

export function StatusStep({ isOrderFlow }: StatusStepProps) {
  const { t } = useTranslation();
  const { topUpId, values, setStep, setValues } = useTopUpModalContext();
  const posthog = usePostHog();
  const hideModal = useHideModal();

  const { session } = useSession();
  const { refetch: getBalance } = useBalance();
  const { data } = useTopUpStatus(topUpId);

  const handleClose = useCallback(
    (isSuccess = false) => {
      posthog.capture(posthogEvents[isOrderFlow ? 'orderTopUp' : 'topUp'][isSuccess ? 'success' : 'failure'], {
        ...(isSuccess && { amount: values?.amount.toString() }),
      });

      if (isSuccess) {
        GA_EVENTS.topUpSuccess(values?.amount ?? 0, session?.userId);
        getBalance();
        hideModal();
      } else {
        setStep('top-up-form');
        setValues(null);
      }
    },
    [getBalance, hideModal, isOrderFlow, posthog, session?.userId, setStep, setValues, values?.amount],
  );

  const { action, subtitle, title, ...variant } = useMemo<Variant>(() => {
    if (!data || data.status === TopUpStatus.PENDING) {
      return { variant: 'loader', subtitle: t('account.modals.status.topUp.pending.subtitle') };
    }

    if (data?.status === TopUpStatus.COMPLETED) {
      return {
        variant: 'icon',
        icon: { name: 'tick-circle', color: 'var(--mui-palette-success-main)' },
        title: t('account.modals.status.topUp.success.title'),
        subtitle: t('account.modals.status.topUp.success.subtitle'),
        action: (
          <Button fullWidth onClick={() => handleClose(true)}>
            {t('account.modals.status.topUp.success.action')}
          </Button>
        ),
      };
    }

    return {
      variant: 'icon',
      icon: { name: 'close-circle', color: 'var(--mui-palette-error-main)' },
      title: t('account.modals.status.topUp.failure.title'),
      subtitle: t('account.modals.status.topUp.failure.subtitle'),
      action: (
        <Button fullWidth onClick={() => handleClose()}>
          {t('account.modals.status.topUp.failure.action')}
        </Button>
      ),
    };
  }, [data, handleClose, t]);

  return (
    <Stack spacing={4} alignItems="center">
      <Stack spacing={1} alignItems="center" textAlign="center">
        {isIconVariant(variant) ? (
          <Icon size={40} name={variant.icon.name} color={variant.icon.color} />
        ) : (
          <CircularProgress size={64} />
        )}

        {(title || subtitle) && (
          <>
            {title && <Typography variant="headline-2">{title}</Typography>}

            {subtitle && (
              <Typography variant="body-1" color="neutral.500">
                {subtitle}
              </Typography>
            )}
          </>
        )}
      </Stack>

      {action}
    </Stack>
  );
}
