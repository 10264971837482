import { accordionClasses } from '@mui/material/Accordion';
import type { Components, Theme } from '@mui/material/styles';

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  styleOverrides: {
    root: {
      '--Accordion-boxShadow': 'none',
      '--Accordion-gap': '8px',
      '--Accordion-expanded-margin': '0px',

      boxShadow: 'var(--Accordion-boxShadow)',
      display: 'flex',
      flexDirection: 'column',
      gap: 'var(--Accordion-gap)',

      [`&.${accordionClasses.expanded}`]: {
        margin: 'var(--Accordion-expanded-margin)',
      },
    },
  },
};
