import { type ReactNode, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
import { PaymentVendorIcon, type PaymentVendor } from 'components/PaymentVendorIcon';
import { useTopUpModalContext } from 'modules/billing/modals/TopUpModal/context';
import { PaymentOption, type TopUpPaymentOptionType } from 'modules/billing/types';
import type { Option } from 'types';

import { CreditCardLabel } from './CreditCardLabel';

type PaymentMethodAttributes = {
  label: ReactNode;
  icon: PaymentVendor;
};

type Options = Record<TopUpPaymentOptionType, PaymentMethodAttributes>;

type PaymentMethodProps = Option<TopUpPaymentOptionType> & {
  isSelected?: boolean;
};

export function PaymentMethod({ value, subLabel: paymentFee, isSelected }: PaymentMethodProps) {
  const { creditCard } = useTopUpModalContext();
  const { t } = useTranslation();

  const { icon, label } = useMemo<PaymentMethodAttributes>(() => {
    const options: Options = {
      alipay: { icon: 'ali-pay', label: `common:paymentOptions.${PaymentOption.ALIPAY}` },
      crypto: { icon: 'coingate', label: `common:paymentOptions.${PaymentOption.CRYPTO}` },
      paypal: { icon: 'pay-pal', label: `common:paymentOptions.${PaymentOption.PAYPAL}` },
      'another-card': { icon: 'another-card', label: <CreditCardLabel type="another-card" /> },
      'new-card': { icon: 'default', label: <CreditCardLabel type="new-card" /> },
      'existing-card': {
        icon: (creditCard?.brand as PaymentVendor) ?? 'default',
        label: <CreditCardLabel type="existing-card" creditCard={creditCard} />,
      },
      blik: { icon: 'blik', label: `common:paymentOptions.${PaymentOption.BLIK}` },
      giropay: { icon: 'giropay', label: `common:paymentOptions.${PaymentOption.GIROPAY}` },
      ideal: { icon: 'ideal', label: `common:paymentOptions.${PaymentOption.IDEAL}` },
      sofort: { icon: 'klarna', label: `common:paymentOptions.${PaymentOption.SOFORT}` },
      p24: { icon: 'p24', label: `common:paymentOptions.${PaymentOption.P24}` },
    };

    return options[value];
  }, [creditCard, value]);

  return (
    <Stack direction="row" flex="1 1 auto" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center" flex="1 1 auto">
        <PaymentVendorIcon type={icon} size={32} />

        <Stack>
          <Typography variant="body-2">{typeof label === 'string' ? t(label) : label}</Typography>

          {value !== 'another-card' && (
            <Typography variant="caption" color="text.secondary">
              {t('common:form.paymentFee', { paymentFee })}
            </Typography>
          )}
        </Stack>
      </Stack>

      {isSelected && (
        <Stack spacing={1} direction="row" alignItems="center">
          <Icon name="check" size="small" color="var(--mui-palette-primary-main)" />
        </Stack>
      )}
    </Stack>
    // <Grid container width="100%" gap={1}>
    //   <Grid item display="flex" alignItems="center">
    //     <PaymentVendorIcon type={icon} size={32} />
    //   </Grid>

    //   <Grid item display="flex" flexDirection="column" justifyContent="center">
    //     <Typography variant="body-2">{typeof label === 'string' ? t(label) : label}</Typography>

    //     {type !== 'another-card' && (
    //       <Typography variant="caption" color="text.secondary">
    //         {t('common:form.paymentFee', { paymentFee })}
    //       </Typography>
    //     )}
    //   </Grid>

    //   {isSelected && (
    //     <Grid item display="flex" alignItems="center" marginLeft="auto">
    //       <Icon name="check" size="small" color="primary.main" />
    //     </Grid>
    //   )}
    // </Grid>
  );
}
