export const paths = {
  index: '/',
  notFound: '/404',
  auth: {
    signIn: '/login',
    mfa: '/login/mfa',
    signUp: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    logout: '/logout',
    switch: '/switch',
    oAuth: {
      google: 'connect/google',
      github: 'connect/github',
    },
  },
  marketplace: {
    index: '/marketplace',
  },
  order: {
    index: '/order',
    vpn: '/order/vpn',
    proxy: '/order/proxy',
    details: '/order/details',
    checkout: '/order/checkout',
    v2: {
      index: '/order/v2',
      details: '/order/v2/details',
      checkout: '/order/v2/checkout',
    },
  },
  account: {
    index: '/account',
    general: '/account/general',
    notifications: '/account/notifications',
    security: '/account/security',
    identityVerification: '/account/id-verification',
    payments: '/account/billing',
  },
  payments: {
    callback: '/payments/callback',
  },
  proxies: {
    index: '/proxies',
    active: '/proxies?preset=active',
    inactive: '/proxies?preset=inactive',
    expiring: '/proxies?preset=expiring',
    replace: '/proxies/replace',
  },
  vpns: {
    index: '/vpn',
  },
  referrals: {
    index: '/referrals',
  },
  featureRequest: {
    index: '/feature-request',
  },
  reseller: {
    index: '/reseller',
  },
  apiKeys: {
    index: '/api-keys',
  },
  external: {
    asnLookup: 'https://asnlookup.com',
    needHelp: 'https://support.proxy-cheap.com/support/solutions/folders/103000180798',
    faq: 'https://support.proxy-cheap.com',
    whatIsMyIp: 'https://www.whatismyiplookup.com/',
    apiDocumentation: 'https://app.swaggerhub.com/apis-docs/Proxy-Cheap/API',
    termsAndConditions: 'https://www.proxy-cheap.com/terms-and-conditions',
    privacyPolicy: 'https://www.proxy-cheap.com/privacy-policy',
    stripe: 'https://www.stripe.com',
    whmcs: 'https://marketplace.whmcs.com/product/6889-whitelabel-proxy-reseller-module',
    chromeExtension:
      'https://chrome.google.com/webstore/detail/proxy-cheap-proxy-manager/gdpehpfhegefkjelaifkdbppjbhilaom',
    vpnKnowledgeBase: 'https://support.proxy-cheap.com/support/solutions/103000080739',
    trustpilotReview: 'https://www.trustpilot.com/evaluate/proxy-cheap.com',
  },
  quiz: {
    index: '/quiz',
  },
};
