import { useCallback, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useSession } from 'hooks/useSession';
import { getOrderStep } from 'modules/orders/v2/helpers';
import { useOrderState } from 'modules/orders/v2/hooks';
import { paths } from 'paths';

import type { OrderNavigationItemProps } from './OrderNavigationItem';

export function useOrderNavigationItems() {
  const { pathname } = useLocation();

  const { isProfileDetailsValid, isSessionLoading } = useSession();

  const setOrderStep = useOrderState((state) => state.setStep);
  const resetOrder = useOrderState((state) => state.resetOrder);

  /**
   * The initial set of order navigation items
   */
  const items = useMemo(() => {
    return [
      { title: 'order.v2.setup.title', href: paths.order.v2.index },
      { title: 'order.v2.details.title', href: paths.order.v2.details },
      { title: 'order.v2.checkout.title', href: paths.order.v2.checkout },
    ];
  }, []);

  /**
   * Checks if the order navigation item is clickable
   *
   * User can only go back - so any further navigation item should be disabled
   */
  const isItemEnabled = useCallback(
    (href: string) => {
      const orderPaths = items.map((p) => p.href);

      const currentPathIndex = orderPaths.findIndex((p) => p === pathname);

      return orderPaths.slice(0, currentPathIndex).includes(href);
    },
    [items, pathname],
  );

  /**
   * Handles navigation between order flow steps
   */
  const onClick = useCallback(
    (href: string) => {
      const step = getOrderStep(href);

      if (!step) {
        return resetOrder();
      }

      setOrderStep(step);
    },
    [resetOrder, setOrderStep],
  );

  return useMemo<OrderNavigationItemProps[]>(() => {
    return items
      .filter(({ href }) => href !== paths.order.v2.details || (!isProfileDetailsValid && !isSessionLoading))
      .map((item) => ({ ...item, disabled: !isItemEnabled(item.href), onClick: () => onClick(item.href) }));
  }, [isItemEnabled, isProfileDetailsValid, isSessionLoading, items, onClick]);
}
