import { useCallback, useEffect, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { SUPPORTED_VPN_MONTHS } from 'modules/orders/v1/OrderVPNPage/consts';
import { useExtendVPNPeriodMutation, useGetOrderPriceMutation } from 'store/api';
import { toOrderConfigurationPayload } from 'store/orders/v1/helpers';
import type { OrderPriceModel } from 'store/orders/v1/models';
import { NetworkType } from 'store/proxies/types';
import type { AxiosBaseQueryError } from 'store/types';
import { ToastManager } from 'utils/toast';

import { type ExtendPrice, VPNExtendPeriodForm, type VPNExtendPeriodValues } from './VPNExtendPeriodForm';

type HandleSubmit = FormikConfig<VPNExtendPeriodValues>['onSubmit'];

export type VPNExtendPeriodModalProps = {
  vpnId: number;
};

export function VPNExtendPeriodModal({ vpnId }: VPNExtendPeriodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [extendPeriod] = useExtendVPNPeriodMutation();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [prices, setPrices] = useState<ExtendPrice[]>([]);

  const [getOrderPrice, { isLoading }] = useGetOrderPriceMutation();

  const initialValues = useMemo<VPNExtendPeriodValues>(() => {
    return { periodInMonths: 1 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ periodInMonths }) => {
      const foundPrice = prices?.find(({ months }) => months === periodInMonths);

      if (!foundPrice) {
        ToastManager.error(t('vpns.modals.extendPeriod.failure'));

        return;
      }

      try {
        await extendPeriod({ vpnId, periodInMonths }).unwrap();

        GA_EVENTS.vpnPeriodExtended({
          userId: session?.userId,
          value: foundPrice.totalPrice,
          vpnId,
          period: periodInMonths,
          networkType: NetworkType.VPN,
        });

        posthog?.capture(posthogEvents.vpn.extendPeriod.success, { vpnId, periodInMonths });

        ToastManager.success(t('vpns.modals.extendPeriod.success'));
        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.message && typeof err.message === 'string') {
          ToastManager.error(t(`errors:${err.message}`));
        } else {
          ToastManager.error(t('vpns.modals.extendPeriod.failure'));
        }

        posthog?.capture(posthogEvents.vpn.extendPeriod.failed, { vpnId, periodInMonths });
      }
    },
    [extendPeriod, hideModal, posthog, prices, session?.userId, t, vpnId],
  );

  useEffect(() => {
    Promise.all(
      SUPPORTED_VPN_MONTHS.map(async (months) =>
        getOrderPrice(toOrderConfigurationPayload({ networkType: NetworkType.VPN, timePeriod: `${months}m` })),
      ),
    ).then((responsePrices) => {
      setPrices(
        SUPPORTED_VPN_MONTHS.map((months, index) => {
          const currentMonthPriceData = (responsePrices[index] as { data: OrderPriceModel }).data;

          return {
            months,
            totalPrice: currentMonthPriceData.subtotal,
          };
        }),
      );
    });
  }, [getOrderPrice]);

  return (
    <Formik<VPNExtendPeriodValues> initialValues={initialValues} onSubmit={onSubmit}>
      <VPNExtendPeriodForm prices={prices} isLoading={isLoading} />
    </Formik>
  );
}
