import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { useBulkExtendProxyBandwidthMutation, useExtendProxyBandwidthMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { AxiosBaseQueryError } from 'store/types';
import { ToastManager } from 'utils/toast';

import { ExtendBandwidthForm, type ExtendBandwidthValues } from './ExtendBandwidthForm';

type HandleSubmit = FormikConfig<ExtendBandwidthValues>['onSubmit'];

export type ExtendBandwidthModalProps = {
  proxies: ProxyModel[];
};

export function ExtendBandwidthModal({ proxies }: ExtendBandwidthModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const [extendProxyBandwidth] = useExtendProxyBandwidthMutation();
  const [bulkExtendProxyBandwidth] = useBulkExtendProxyBandwidthMutation();

  const initialValues = useMemo<ExtendBandwidthValues>(() => {
    return { bandwidth: 1 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ bandwidth }) => {
      const proxyIds = proxies.map(({ id }) => id);

      try {
        if (proxyIds.length > 1) {
          await bulkExtendProxyBandwidth({ ids: proxyIds, trafficInGB: bandwidth }).unwrap();

          proxyIds.forEach((proxyId) => {
            const foundProxy = proxies.find(({ id }) => id === proxyId);

            GA_EVENTS.proxyBandwidthPurchased({
              userId: session?.userId,
              value: singleItemPrice,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              networkType: foundProxy!.networkType,
              proxy_id: proxyId,
              bandwidth,
            });
          });

          posthog?.capture(posthogEvents.proxy.bulk.extendBandwidth.success, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          await extendProxyBandwidth({ proxyId: proxyIds[0], bandwidth }).unwrap();

          GA_EVENTS.proxyBandwidthPurchased({
            userId: session?.userId,
            value: singleItemPrice,
            networkType: proxies[0].networkType,
            proxy_id: proxyIds[0],
            bandwidth,
          });

          posthog?.capture(posthogEvents.proxy.extendBandwidth.success, { proxyId: proxyIds[0], bandwidth });
        }

        ToastManager.success(t('proxies.modals.extendBandwidth.success'));

        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.message && typeof err.message === 'string') {
          ToastManager.error(t(`errors:${err.message}`));
        } else {
          ToastManager.error(t('proxies.modals.extendPeriod.failure'));
        }

        if (proxyIds.length > 1) {
          posthog?.capture(posthogEvents.proxy.bulk.extendBandwidth.failed, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          posthog?.capture(posthogEvents.proxy.extendBandwidth.failed, { proxyId: proxyIds[0], bandwidth });
        }
      }
    },
    [bulkExtendProxyBandwidth, extendProxyBandwidth, hideModal, posthog, proxies, session?.userId, singleItemPrice, t],
  );

  return (
    <Formik<ExtendBandwidthValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <ExtendBandwidthForm proxies={proxies} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
