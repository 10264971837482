import { useCallback, useMemo } from 'react';

import type { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Flag } from 'components/Flag';
import { Select } from 'components/form-partials/base';
import { posthogEvents } from 'constants/posthogEvents';
import { useAppSelector } from 'store';
import { toLanguageDTO } from 'store/accounts/helpers';
import { Language } from 'store/accounts/models';
import { useChangeLanguageMutation } from 'store/api';
import type { Option } from 'types';

type LanguageDropdownProps = {
  iconOnly?: boolean;
  fullWidth?: boolean;
};

export function LanguageDropdown({ iconOnly = false, fullWidth = false }: LanguageDropdownProps) {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, accessToken } = useAppSelector((state) => state.auth);
  const posthog = usePostHog();
  const [changeLanguage] = useChangeLanguageMutation();

  const getFlagForLanguage = useCallback((language: Language) => {
    if (language === Language.CN) return 'CN';

    if (language === Language.VI) return 'VN';

    if (language === Language.ES) return 'ES';

    if (language === Language.RU) return 'RU';

    return 'US';
  }, []);

  const getLanguageValue = useCallback((language: string) => {
    if (language.includes('en-') || language.includes('en_')) return 'en' as Language;

    return language as Language;
  }, []);

  const options = useMemo<Array<Option<Language>>>(
    () => Object.entries(Language).map(([key, value]) => ({ label: t(`common:languages.${key}`), value })),
    [t],
  );

  const handleChangeLanguage = useCallback(
    ({ target: { value: language } }: SelectChangeEvent<Language>) => {
      try {
        i18n.changeLanguage(language);

        if (isAuthenticated && accessToken) {
          changeLanguage({ language: toLanguageDTO(language as Language) });
        }

        posthog.capture(posthogEvents.common.languageChanged, { language: toLanguageDTO(language as Language) });
      } catch {
        // noop
      }
    },
    [accessToken, changeLanguage, i18n, isAuthenticated, posthog],
  );

  const renderCustomValue = useCallback(
    (value: Language) => {
      if (iconOnly) {
        return (
          <Stack alignItems="center" justifyContent="center">
            <Flag size={16} countryCode={getFlagForLanguage(value)} />
          </Stack>
        );
      }
    },
    [getFlagForLanguage, iconOnly],
  );

  const renderCustomOption = useCallback(
    (option: Option<Language>) => {
      return (
        <Stack flex="1 1 auto" alignItems="center" justifyContent="center">
          <Flag size={16} countryCode={getFlagForLanguage(option.value)} />
        </Stack>
      );
    },
    [getFlagForLanguage],
  );

  return (
    <Select
      name="language"
      size="small"
      value={getLanguageValue(i18n.language)}
      options={options}
      fullWidth={fullWidth}
      onChange={handleChangeLanguage}
      getListItemLeftAdornment={(option) => <Flag size={16} countryCode={getFlagForLanguage(option.value)} />}
      {...(iconOnly && { renderCustomOption, renderCustomValue })}
    />
  );
}
