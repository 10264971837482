import { useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { useDebounce } from 'hooks/useDebounce';
import type { PaymentOption } from 'modules/billing/types';
import type { OrderSetupValues } from 'modules/orders/v2/types';
import { useGetOrderServicePriceMutation } from 'store/api';
import { toOrderSetupPayload } from 'store/orders/v2/helpers';
import type { Nullable } from 'types';

import { useOrderState } from './useOrderState';

export function useOrderPrice(values: Nullable<OrderSetupValues>, paymentOption?: PaymentOption) {
  const [internalValues, setInternalValues] = useState(values);
  const debouncedValues = useDebounce(internalValues);

  const couponCode = useOrderState((state) => state.couponCode);
  const setPrice = useOrderState((state) => state.setPrice);
  const setPriceLoading = useOrderState((state) => state.setPriceLoading);

  const [getOrderPrice, { isLoading, isUninitialized }] = useGetOrderServicePriceMutation();

  useEffect(() => {
    (async () => {
      try {
        if (!debouncedValues || debouncedValues?.serviceId === 'vpn') return;

        const mappedValues = toOrderSetupPayload({ ...debouncedValues, couponCode });

        const response = await getOrderPrice({
          ...mappedValues,
          ...(paymentOption && { payment: { method: paymentOption, metadata: 'blank' } }),
        }).unwrap();

        setPrice(response);
      } catch {
        // TODO: Add error handling for coupon codes

        setPrice(null);
      }
    })();
  }, [couponCode, debouncedValues, getOrderPrice, paymentOption, setPrice]);

  useEffect(() => {
    if (!values || !internalValues) return;

    if (isEqual(values, internalValues)) {
      return;
    }

    setInternalValues(values);
  }, [internalValues, values]);

  useEffect(() => {
    if (isUninitialized) return;

    setPriceLoading(isLoading);
  }, [isLoading, isUninitialized, setPrice, setPriceLoading]);

  return useMemo(() => ({ isLoading }), [isLoading]);
}
