import Slide from '@mui/material/Slide';
import type { Components, Theme } from '@mui/material/styles';

export const MuiSnackbar: Components<Theme>['MuiSnackbar'] = {
  defaultProps: {
    TransitionComponent: Slide,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  },
  styleOverrides: {
    root: {
      '--Snackbar-background': 'var(--mui-palette-primary-900)',
      '--Snackbar-borderRadius': '16px',
      '--Snackbar-bottom': '32px',
      '--Snackbar-minWidth': '360px',
      '--Snackbar-paddingBlock': '16px',
      '--Snackbar-paddingInline': '24px',
      '--Snackbar-zIndex': 1299,

      background: 'var(--Snackbar-background)',
      borderRadius: 'var(--Snackbar-borderRadius)',
      bottom: 'var(--Snackbar-bottom)',
      minWidth: 'var(--Snackbar-minWidth)',
      paddingBlock: 'var(--Snackbar-paddingBlock)',
      paddingInline: 'var(--Snackbar-paddingInline)',
      zIndex: 'var(--Snackbar-zIndex)',
    },
  },
};
