import { useCallback, useState } from 'react';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { RouterLink } from 'components/RouterLink';
import { useStripeContext } from 'modules/billing/Stripe';
import { paths } from 'paths';
import { useLazyGetBillingPreferencesQuery, useLazyGetCreditCardQuery } from 'store/api';
import { ToastManager } from 'utils/toast';

export function AddCreditCardForm() {
  const { error, handleSetup, handleSubmit } = useStripeContext();
  const hideModal = useHideModal();

  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isFormReady, setFormReady] = useState(false);

  const [getBillingPreferences] = useLazyGetBillingPreferencesQuery();
  const [getCreditCard] = useLazyGetCreditCardQuery();

  const onStart = useCallback(() => setLoading(true), []);

  const onFailure = useCallback(() => setLoading(false), []);

  const onSuccess = useCallback(async () => {
    await getBillingPreferences();
    await getCreditCard();

    setLoading(false);
    ToastManager.success(t('account.modals.card.success'));

    return hideModal();
  }, [getBillingPreferences, getCreditCard, hideModal, t]);

  return (
    <form
      onSubmit={(event) =>
        handleSubmit({ event, onFailure, onStart, onSuccess: () => handleSetup({ onSuccess, onFailure }) })
      }
      noValidate
    >
      <Stack spacing={4}>
        <Stack spacing={2}>
          <PaymentElement onReady={() => setFormReady(true)} />

          <Collapse in={Boolean(error)} unmountOnExit>
            <Alert>{error?.message}</Alert>
          </Collapse>

          <Stack direction="row" spacing={2}>
            <Button color="secondary" fullWidth onClick={hideModal} disabled={isLoading}>
              {t('common:back')}
            </Button>

            <Button type="submit" fullWidth disabled={!isFormReady} loading={isLoading}>
              {t('common:buttons.continue')}
            </Button>
          </Stack>

          <Stack alignItems="center">
            <Typography variant="caption" color="text.secondary">
              {t('order.checkout.trust')}
            </Typography>

            <Typography variant="caption" color="text.secondary">
              <Trans i18nKey="order.checkout.stripe">
                All payment methods are safe and processed by
                <Link component={RouterLink} href={paths.external.stripe} target="_blank">
                  Stripe
                </Link>
              </Trans>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}
