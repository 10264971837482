import { useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useUplinkSpeedFormatter } from 'hooks/useUplinkSpeedFormatter';
import { useGetUpgradeUplinkSpeedPriceQuery } from 'store/api';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

import { BalanceSummary } from '../../components/BalanceSummary';

export type UpgradeUplinkSpeedValues = {
  uplinkSpeed: number;
};

export type UpgradeUplinkSpeedFormProps = {
  proxyIds: number[];
  price: number;
  setPriceForSingle: (price: number) => void;
};

export function UpgradeUplinkSpeedForm({ proxyIds, price, setPriceForSingle }: UpgradeUplinkSpeedFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, setFieldValue, handleSubmit, isSubmitting, isValid } = useFormikContext<UpgradeUplinkSpeedValues>();

  const prepareUplinkSpeedLabel = useUplinkSpeedFormatter();

  const {
    data: speedPrices,
    isFetching: isPriceFetching,
    refetch,
  } = useGetUpgradeUplinkSpeedPriceQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const speedOptions = useMemo<Array<Option<number>>>(() => {
    return speedPrices?.map(({ mbit }) => ({ value: mbit, label: prepareUplinkSpeedLabel(mbit) })) ?? [];
  }, [speedPrices, prepareUplinkSpeedLabel]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.upgrade')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, isPriceFetching, t]);

  useEffect(() => {
    // no cached data for new instance, prevent errors when calling refetching without initial fetch
    if (speedPrices) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // set 1st by default if not found
  useEffect(() => {
    if (speedPrices?.length) {
      const foundPrice = speedPrices.find(({ mbit }) => mbit === values.uplinkSpeed);

      if (!foundPrice) {
        setFieldValue('uplinkSpeed', speedPrices[0].mbit);
      }
    }
  }, [speedPrices, values.uplinkSpeed, setFieldValue]);

  useEffect(() => {
    if (speedPrices) {
      const foundPrice = speedPrices.find(({ mbit }) => mbit === values.uplinkSpeed);

      if (foundPrice) {
        setPriceForSingle(+foundPrice.price.toFixed(2));
      }
    }
  }, [speedPrices, values.uplinkSpeed, setPriceForSingle]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.upgradeBandwidthSpeed.title')}
      subtitle={t('proxies.modals.upgradeBandwidthSpeed.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        <FormikAutocomplete size="small" disableClearable options={speedOptions} name="uplinkSpeed" />

        <Stack spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isPriceFetching} price={price} />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(price ?? 0)}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
