import { type ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Outlet, useSearchParams } from 'react-router-dom';

import { Logo } from 'components/Logo';
import { RouterLink } from 'components/RouterLink';
import { LanguageDropdown } from 'modules/common/LanguageDropdown';
import { paths } from 'paths';
import { PersistService } from 'utils/persist';

type AuthLayoutProps = {
  children?: ReactNode;
  withoutVector?: boolean;
};

export function AuthLayout({ children, withoutVector }: AuthLayoutProps) {
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const returnTo = searchParams.get('returnTo');

    if (returnTo) {
      PersistService.set('redirect.url', returnTo);
    }
  }, [searchParams]);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ xs: '1fr', lg: `repeat(${withoutVector ? 1 : 2}, 1fr)` }}
      position="relative"
      minHeight="100vh"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={{ xs: 2, lg: 4 }}
        position={{ xs: 'absolute', md: 'fixed' }}
        left={0}
        right={0}
        zIndex={3}
        bgcolor={{ xs: 'var(--mui-palette-common-white)', lg: 'transparent' }}
      >
        <Box display="inline-flex" component={RouterLink} href={paths.index}>
          <Logo white={!isMobile && !withoutVector} />
        </Box>

        <Box display="inline-flex" justifyContent="flex-end">
          <LanguageDropdown iconOnly />
        </Box>
      </Stack>

      {!withoutVector && (
        <Box display={{ xs: 'none', lg: 'flex' }} bgcolor="var(--mui-palette-primary-900)" position="relative">
          <Box
            position="absolute"
            height="100%"
            width="100%"
            top={0}
            right={0}
            bottom={0}
            sx={{
              backgroundImage: 'url("/resources/vectors/auth.svg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
            }}
          />
        </Box>
      )}

      <Stack
        flex="1 1 auto"
        alignItems="center"
        justifyContent={withoutVector ? 'center' : 'initial'}
        bgcolor="var(--mui-palette-common-white)"
        zIndex={2}
        pt="calc(var(--AuthHeader-height) + 32px)"
        pb={{ xs: 6, md: 0 }}
      >
        {children ?? <Outlet />}
      </Stack>
    </Box>
  );
}
