import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useBulkChangeProxyAuthenticationTypeMutation, useUpdateAuthenticationTypeMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { UpdateAuthenticationTypePayload } from 'store/proxies/payloads';
import { AuthenticationType } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import type { FormErrors } from 'utils/form';
import { ToastManager } from 'utils/toast';

import {
  ChangeAuthenticationMethodForm,
  type ChangeAuthenticationMethodValues,
} from './ChangeAuthenticationMethodForm';

type HandleSubmit = FormikConfig<ChangeAuthenticationMethodValues>['onSubmit'];

export type ChangeAuthenticationMethodModalProps = {
  proxies: ProxyModel[];
};

export function ChangeAuthenticationMethodModal({ proxies }: ChangeAuthenticationMethodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [updateAuthTypeSingle] = useUpdateAuthenticationTypeMutation();
  const [updateAuthTypeBulk] = useBulkChangeProxyAuthenticationTypeMutation();
  const hideModal = useHideModal();
  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const initialValues = useMemo<ChangeAuthenticationMethodValues>(() => {
    if (proxies.length > 1)
      return {
        type: AuthenticationType.USERNAME_PASSWORD,
        ipWhitelist: [''],
      };

    const [{ authentication }] = proxies;

    return {
      type:
        authentication.username && authentication.password
          ? AuthenticationType.USERNAME_PASSWORD
          : AuthenticationType.IP_WHITELIST,

      ipWhitelist: authentication.whitelistedIps?.length > 0 ? authentication.whitelistedIps : [''],
    };
  }, [proxies]);

  const validate = useCallback(
    ({ ipWhitelist, type }: ChangeAuthenticationMethodValues) => {
      const errors: FormErrors = {};

      const filteredWhitelist = ipWhitelist.filter((i) => Boolean(i.length));

      if (type === AuthenticationType.IP_WHITELIST && filteredWhitelist.length === 0) {
        errors.globalError = t('errors:minItems', { field: t('common:form.ipWhitelist'), min: 1 }) ?? '';
      }

      return errors;
    },
    [t],
  );

  const mapResponseKeyIntoFormKey = useCallback((key: keyof UpdateAuthenticationTypePayload) => {
    if (key === 'ips') return 'ipWhitelist';
    if (key === 'newAuthType') return 'type';

    throw new Error('Unsupported key');
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ type, ipWhitelist }, { setErrors, setSubmitting }) => {
      try {
        const ips = type === AuthenticationType.IP_WHITELIST ? ipWhitelist.filter((v) => v.length) : [];

        if (proxies.length > 1) {
          await updateAuthTypeBulk({ ids: proxyIds, authType: type, ips }).unwrap();

          posthog?.capture(posthogEvents.proxy.bulk.authenticationType.success, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          await updateAuthTypeSingle({ proxyId: proxies[0].id, newAuthType: type, ips }).unwrap();

          posthog?.capture(posthogEvents.proxy.authenticationType.success, {
            proxyId: proxies[0].id,
            authenticationType: type,
          });
        }

        ToastManager.success(t('proxies.modals.changeAuthMethod.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error, mapResponseKeyIntoFormKey);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        setSubmitting(false);

        if (proxies.length > 1) {
          posthog?.capture(posthogEvents.proxy.bulk.authenticationType.failed, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          posthog?.capture(posthogEvents.proxy.authenticationType.failed, {
            proxyId: proxies[0].id,
            authenticationType: type,
          });
        }
      }
    },
    [hideModal, mapResponseKeyIntoFormKey, posthog, proxies, proxyIds, t, updateAuthTypeBulk, updateAuthTypeSingle],
  );

  return (
    <Formik<ChangeAuthenticationMethodValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      <ChangeAuthenticationMethodForm proxyIds={proxyIds} />
    </Formik>
  );
}
