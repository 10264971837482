import {
  type ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';

import type { FormikHelpers } from 'formik';
import { usePostHog } from 'posthog-js/react';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useCreditCard } from 'hooks/useCreditCard';
import { useIdentityVerificationStatus } from 'modules/accounts/hooks';
import { toPaymentOption } from 'modules/billing/helpers';
import type { TopUpPaymentOptionType } from 'modules/billing/types';
import type { CreditCardModel, TopUpPaymentFeeModel } from 'store/accounts/models';
import { useGetTopUpFeesMutation, useTopUpMutation } from 'store/api';
import type { ContextChildren, Nullable } from 'types';
import { getValidationErrors } from 'utils/error';

import type { HandleTopUpFn, TopUpModalStep, TopUpValues } from './types';

type TTopUpModalContext = {
  fee: Nullable<TopUpPaymentFeeModel>;
  isFeeLoading: boolean;
  handleUpdateFee: (
    amount: number,
    paymentType: TopUpPaymentOptionType,
    setErrors: FormikHelpers<TopUpValues>['setErrors'],
  ) => Promise<void>;

  creditCard?: CreditCardModel;
  isCardLoading: boolean;

  step: TopUpModalStep;
  setStep: Dispatch<SetStateAction<TopUpModalStep>>;
  values: Nullable<TopUpValues>;
  setValues: Dispatch<SetStateAction<Nullable<TopUpValues>>>;

  topUpId: Nullable<string>;

  isTopUpLoading: boolean;
  handleTopUp: HandleTopUpFn;

  handleCancelModal: () => void;
};

const TopUpModalContext = createContext<TTopUpModalContext | undefined>(undefined);

type TopUpModalContextProviderProps = {
  isOrderFlow?: boolean;
  children: ContextChildren<TTopUpModalContext>;
};

function TopUpModalContextProvider({ isOrderFlow, children }: TopUpModalContextProviderProps) {
  const [fee, setFee] = useState<Nullable<TopUpPaymentFeeModel>>(null);
  const [step, setStep] = useState<TopUpModalStep>('top-up-form');
  const [values, setValues] = useState<Nullable<TopUpValues>>(null);
  const [topUpId, setTopUpId] = useState<Nullable<string>>(null);

  const posthog = usePostHog();
  const hideModal = useHideModal();

  const { creditCard, isCardLoading } = useCreditCard();
  const [getTopUpFee, { isLoading: isFeeLoading }] = useGetTopUpFeesMutation();
  const [topUp, { isLoading: isTopUpLoading }] = useTopUpMutation();

  /**
   * Handles top up fee update
   * Retrieves a payment fee from the API and sets the final price.
   */
  const handleUpdateFee = useCallback(
    async (amount: number, paymentType: TopUpPaymentOptionType, setErrors: FormikHelpers<TopUpValues>['setErrors']) => {
      try {
        const response = await getTopUpFee({ amount, paymentOption: toPaymentOption(paymentType) }).unwrap();

        setFee(response);
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }
      }
    },
    [getTopUpFee],
  );

  /**
   * Handles top-up process
   * Sends a request to the API `POST /account/billing/top-up`
   */
  const handleTopUp = useCallback<HandleTopUpFn>(
    async ({ values: { amount, type }, onError, onSuccess, paymentMethodId }) => {
      try {
        const response = await topUp({
          amount,
          paymentOption: toPaymentOption(type),
          paymentMethodMetadata: paymentMethodId,
        }).unwrap();

        setTopUpId(response.id);

        /**
         * Payment that requires redirection to a separate page without the `<Stripe />` integration.
         * For example: CoinGate
         */
        if (response.type === 'redirect') {
          window.open(response.data, '_blank');
        }

        return onSuccess(response);
      } catch (error) {
        if (onError) {
          return onError(error);
        }

        setStep('top-up-form');
        setValues(null);
        posthog.capture(posthogEvents[isOrderFlow ? 'orderTopUp' : 'topUp'].failure);
      }
    },
    [isOrderFlow, posthog, topUp],
  );

  /**
   * Handles modal cancel
   *
   * This needs to be unified across all of the modal steps.
   */
  const handleCancelModal = useCallback(() => {
    posthog?.capture(posthogEvents[isOrderFlow ? 'orderTopUp' : 'topUp'].canceled);

    return hideModal();
  }, [hideModal, isOrderFlow, posthog]);

  const value = useMemo<TTopUpModalContext>(() => {
    return {
      fee,
      isFeeLoading,
      handleUpdateFee,

      creditCard,

      isCardLoading,

      step,
      setStep,
      values,
      setValues,

      topUpId,

      isTopUpLoading,
      handleTopUp,

      handleCancelModal,
    };
  }, [
    creditCard,
    fee,
    handleCancelModal,
    handleTopUp,
    handleUpdateFee,
    isCardLoading,
    isFeeLoading,
    isTopUpLoading,
    step,
    topUpId,
    values,
  ]);

  return (
    <TopUpModalContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </TopUpModalContext.Provider>
  );
}

export function useTopUpModalContext() {
  const context = useContext(TopUpModalContext);

  if (context === undefined) {
    throw new Error('Cannot use "useTopUpModalContext" outside the "<TopUpModalContextProvider />" context provider');
  }

  return context;
}

export function withTopUpModalContextProvider<T extends object>(Component: ComponentType<T>) {
  const displayName = Component.displayName || Component.name || 'Component';

  function WrappedComponent(props: T) {
    const { shouldForceIDVerification } = useIdentityVerificationStatus();

    const renderDisabled = useMemo(() => shouldForceIDVerification(), [shouldForceIDVerification]);

    if (renderDisabled) {
      return null;
    }

    return (
      <TopUpModalContextProvider {...props}>
        <Component {...props} />
      </TopUpModalContextProvider>
    );
  }

  WrappedComponent.displayName = `withTopUpModalContextProvider(${displayName})`;

  return WrappedComponent;
}
