import { menuItemClasses } from '@mui/material/MenuItem';
import type { Components, Theme } from '@mui/material/styles';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: { disableRipple: true },
  styleOverrides: {
    root: {
      '--MenuItem-background': 'var(--mui-palette-common-white)',
      '--MenuItem-paddingBlock': '8px',
      '--MenuItem-paddingInline': '8px',

      '--MenuItem-divider': '1px solid var(--mui-palette-divider)',

      '--MenuItem-hover-background': 'var(--mui-palette-neutral-50)',
      '--MenuItem-selected-background': 'var(--mui-palette-neutral-50)',

      backgroundColor: 'var(--MenuItem-background)',
      paddingBlock: 'var(--MenuItem-paddingBlock)',
      paddingInline: 'var(--MenuItem-paddingInline)',

      [`&:not(.${menuItemClasses.selected}):hover`]: {
        backgroundColor: 'var(--MenuItem-hover-background)',
      },

      '&:not(:last-child)': {
        borderBottom: 'var(--MenuItem-divider)',
      },

      [`&.${menuItemClasses.selected}`]: {
        backgroundColor: 'var(--MenuItem-selected-background)',

        '&:hover': {
          backgroundColor: 'var(--MenuItem-selected-background)',
        },
      },
    },
  },
};
