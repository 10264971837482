import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { CannyChangelogButton } from 'components/Canny/CannyChangelogButton';
import { Logo } from 'components/Logo';
import { RouterLink } from 'components/RouterLink';
import { LanguageDropdown } from 'modules/common/LanguageDropdown';
import { paths } from 'paths';
import { useGetVPNQuery } from 'store/api';
import { VPNStatus } from 'store/vpns/types';

import { AccountButton } from './AccountButton';

type NavigationProps = {
  offset?: number;
};

export function Navigation({ offset = 0 }: NavigationProps) {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { data, isLoading } = useGetVPNQuery();

  const shouldHideTitle = useMemo(() => {
    if (pathname.startsWith(paths.vpns.index) && isLoading) return true;

    return pathname.startsWith(paths.vpns.index) && data?.status !== VPNStatus.ACTIVE;
  }, [data?.status, isLoading, pathname]);

  const title = useMemo(() => {
    if (pathname === '/') return 'dashboardOverview';

    if (matchPath(`${paths.proxies.index}/:proxyId`, pathname)) {
      return 'proxyOverview';
    }

    return pathname.split('/').find(Boolean);
  }, [pathname]);

  return (
    <Stack
      component="header"
      bgcolor="var(--Navigation-background)"
      position="fixed"
      top={offset}
      left={{ xs: 0, md: 'var(--Sidebar-width)' }}
      right={0}
      zIndex="var(--Navigation-zIndex)"
      sx={(theme) => ({
        transition: theme.transitions.create('all', { duration: 225 }),
      })}
    >
      <Stack
        direction="row"
        flex="1 1 auto"
        justifyContent="space-between"
        alignItems="center"
        minHeight="var(--Navigation-height)"
        maxHeight="var(--Navigation-height)"
        borderBottom="var(--Navigation-border)"
        px={{ xs: 2, md: 4 }}
        py={2}
      >
        <Box display={{ xs: 'inline-flex', md: 'none' }} component={RouterLink} href={paths.index}>
          <Logo wide={false} />
        </Box>

        <Box position="relative" left={0} right={0} textAlign="center">
          {!shouldHideTitle && <Typography variant="headline-2">{t(`titles.${title}`)}</Typography>}
        </Box>

        <Stack direction="row" spacing={2} alignItems="center">
          <CannyChangelogButton />
          <LanguageDropdown iconOnly />
          <AccountButton />
        </Stack>
      </Stack>
    </Stack>
  );
}
