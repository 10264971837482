import { paths } from 'paths';
import type { OrderStep } from 'store/orders/v2/types';

export const ORDER_PATH_TO_STEP_MAP: Record<string, OrderStep> = {
  [paths.order.v2.checkout]: 'checkout',
  [paths.order.v2.details]: 'details',
  [paths.order.v2.index]: 'setup',
};

export const ORDER_STEP_TO_PATH_MAP: Record<OrderStep, string> = {
  checkout: paths.order.v2.checkout,
  details: paths.order.v2.details,
  setup: paths.order.v2.index,
};
