import { useCallback, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormikInput } from 'components/form-partials/formik';
import { Icon } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { useSession } from 'hooks/useSession';
import { useGetProxyIPWhitelistQuery } from 'store/api';

export type IPValues = {
  ipWhitelist: string[];
};

type IPWhitelistFormProps = {
  proxyIds: number[];

  globalErrorKey?: string;
};

export function IPWhitelistForm<Values extends IPValues>({
  proxyIds,
  globalErrorKey = 'globalError',
}: IPWhitelistFormProps) {
  const { t } = useTranslation();
  const { session } = useSession();
  const { errors, values, setFieldValue } = useFormikContext<Values>();

  const { data: whitelistData } = useGetProxyIPWhitelistQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const MAX_IPS = useMemo(() => whitelistData?.allowedWhitelistIpCount ?? 1, [whitelistData?.allowedWhitelistIpCount]);

  const handleAddNewIPField = useCallback(() => {
    setFieldValue('ipWhitelist', values.ipWhitelist.concat(['']));
  }, [setFieldValue, values.ipWhitelist]);

  const setIP = useCallback(() => {
    const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

    if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp ?? '');

    const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

    return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp ?? '');
  }, [session?.userIp, setFieldValue, values.ipWhitelist]);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Stack spacing={4}>
          {values.ipWhitelist.map((_, index) => (
            <Stack key={`ip-whitelist-${index}`} direction="row" spacing={2} alignItems="center">
              <FormikInput fullWidth name={`ipWhitelist[${index}]`} />

              {index > 0 ? (
                <IconButton
                  disableRipple
                  onClick={() =>
                    setFieldValue(
                      'ipWhitelist',
                      values.ipWhitelist.filter((__, itemIndex) => itemIndex !== index),
                    )
                  }
                >
                  <Icon name="minus-circle" size="small" color="var(--mui-palette-error-main)" />
                </IconButton>
              ) : (
                <Tooltip title={t('order.tooltips.ipWhitelist')}>
                  <IconButton>
                    <Icon name="question" color="rgba(var(--mui-palette-text-primaryChannel) / 0.24)" size="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>

        <Collapse in={values.ipWhitelist.length < MAX_IPS} unmountOnExit>
          <Link variant="caption" color="primary.main" onClick={handleAddNewIPField}>
            {t('common:buttons.addAnother')}
          </Link>
        </Collapse>
      </Stack>

      {session?.userIp && (
        <Link variant="caption" color="text.secondary" onClick={setIP} underline="always">
          {t('common:buttons.setYourCurrentIp', { ip: session.userIp })}
        </Link>
      )}

      <Collapse in={!!getIn(errors, globalErrorKey)} unmountOnExit>
        <Typography variant="body-2" color="var(--mui-palette-error-main)">
          {getIn(errors, globalErrorKey)}
        </Typography>
      </Collapse>
    </Stack>
  );
}
