import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { Outlet } from 'react-router-dom';

import { useOrderState } from 'modules/orders/v2/hooks';

import { OrderNavigation } from './OrderNavigation';
import { SecurePayment } from './SecurePayment';

export function OrderLayout() {
  const selectedServiceId = useOrderState((state) => state.selectedServiceId);

  const content = useMemo(() => {
    return (
      <Stack
        pr={{ xs: 0, lg: selectedServiceId === 'vpn' ? 0 : 'var(--OrderSidebar2-width)' }}
        sx={(theme) => ({
          '--OrderContent-paddingBlock': 'calc(var(--OrderNavigation-height) + 32px)',
          '--OrderContent-paddingInline': { xs: '16px', md: '0px' },
          '--OrderContent-padding':
            'var(--OrderContent-paddingBlock) var(--OrderContent-paddingInline) calc(var(--OrderContent-paddingBlock) + 64px) var(--OrderContent-paddingInline)',
          '--OrderContent-maxWidth': '800px',

          transition: theme.transitions.create('padding-right', { duration: 225 }),
        })}
      >
        <Stack
          p="var(--OrderContent-padding)"
          m="var(--OrderContent-margin)"
          width="var(--OrderContent-width)"
          maxWidth="var(--OrderContent-maxWidth)"
        >
          <Stack spacing={8}>
            <Outlet />

            <SecurePayment />
          </Stack>
        </Stack>
      </Stack>
    );
  }, [selectedServiceId]);

  return (
    <Stack
      component="main"
      flex="1 1 auto"
      minHeight="100%"
      bgcolor="var(--mui-palette-background-default)"
      sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
    >
      <OrderNavigation />

      {content}
    </Stack>
  );
}
