import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';
import type { ProxyModel } from 'store/proxies/models';

type MultiUpdatesAccordionProps = {
  proxyIds: Array<ProxyModel['id']>;
  customLabel?: string;
};

export function MultiUpdatesAccordion({ proxyIds, customLabel }: MultiUpdatesAccordionProps) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary>
        <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
          {customLabel ?? t('proxies.my-proxies.multiaction.title', { count: proxyIds.length })}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {proxyIds.map((proxyId, index) => (
          <Link
            key={proxyId}
            variant="body-2"
            color="primary.main"
            component={RouterLink}
            href={`${paths.proxies.index}/${proxyId}`}
            target="_blank"
          >
            {proxyId}
            {index !== proxyIds.length - 1 && ', '}
          </Link>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
