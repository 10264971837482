import { useCallback, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useSession } from 'hooks/useSession';

export type GlobalIPWhitelistValues = {
  ipWhitelist: string[];
};

export function GlobalIPWhitelistForm() {
  const { t } = useTranslation();
  const { session } = useSession();
  const hideModal = useHideModal();
  const { dirty, isSubmitting, isValid, values, handleSubmit, setFieldValue } =
    useFormikContext<GlobalIPWhitelistValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
        {t('common:buttons.update')}
      </Button>,
    ];
  }, [dirty, hideModal, isSubmitting, isValid, t]);

  const setIP = useCallback(() => {
    const isAnyFieldEmpty = values.ipWhitelist.some((item) => !item.length);

    if (!isAnyFieldEmpty) return setFieldValue('ipWhitelist[0]', session?.userIp || '');

    const emptyIndex = values.ipWhitelist.findIndex((item) => !item.length);

    return setFieldValue(`ipWhitelist[${emptyIndex}]`, session?.userIp || '');
  }, [session?.userIp, setFieldValue, values.ipWhitelist]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.globalIPWhitelist.title')}
      subtitle={t('proxies.modals.globalIPWhitelist.subtitle')}
      actions={actions}
      disablePortal
    >
      <Stack spacing={4}>
        <Stack spacing={2}>
          {values.ipWhitelist.map((_, index) => (
            <FormikInput key={`ip-whitelist-${index}`} fullWidth name={`ipWhitelist[${index}]`} />
          ))}
        </Stack>

        <Collapse in={!!session?.userIp} unmountOnExit>
          <Stack alignItems="center">
            <Link variant="caption" color="text.secondary" onClick={setIP} underline="always" textAlign="center">
              {t('common:buttons.setYourCurrentIp', { ip: session?.userIp })}
            </Link>
          </Stack>
        </Collapse>
      </Stack>
    </ModalContainer>
  );
}
