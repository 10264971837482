import { toNetworkType } from 'store/orders/v1/helpers';

import type { ExperimentDTO, FeaturesDTO, MaintenanceWindowDTO } from './dtos';
import type { ExperimentModel, FeaturesModel, MaintenanceWindowModel } from './models';
import { Experiment } from './types';

export function toExperimentModel(from: ExperimentDTO): ExperimentModel;
export function toExperimentModel(from: ExperimentDTO[]): ExperimentModel[];

export function toExperimentModel(from: ExperimentDTO | ExperimentDTO[]): ExperimentModel | ExperimentModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toExperimentModel(item));
  }

  if (from.id === Experiment.AppreciationBanner) return from;

  const { configuration, ...rest } = from;

  return {
    ...rest,
    configuration: {
      availableFor: configuration.proxyServiceTypes.map((item) => toNetworkType(item)),
      couponCode: configuration.proxyCouponCode ?? null,
    },
  };
}

export function toFeaturesModel(from: FeaturesDTO): FeaturesModel {
  return from;
}

export function toMaintenanceWindowModel(from: MaintenanceWindowDTO): MaintenanceWindowModel;
export function toMaintenanceWindowModel(from: MaintenanceWindowDTO[]): MaintenanceWindowModel[];

export function toMaintenanceWindowModel(
  from: MaintenanceWindowDTO | MaintenanceWindowDTO[],
): MaintenanceWindowModel | MaintenanceWindowModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toMaintenanceWindowModel(item));
  }

  return from;
}
