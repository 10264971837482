import { ReactComponent as CPUIcon } from 'assets/icons/computers/cpu.svg';
import { ReactComponent as Driver2Icon } from 'assets/icons/computers/driver-2.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/computers/mobile.svg';
import { ReactComponent as MonitorMobileIcon } from 'assets/icons/computers/monitor-mobile.svg';

export const computers = {
  cpu: CPUIcon,
  'driver-2': Driver2Icon,
  mobile: MobileIcon,
  'monitor-mobile': MonitorMobileIcon,
};
