import { type ChangeEvent, useCallback, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Button } from 'components/Button';
import { FormikInput, FormikSwitch } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';

export type ProxyAutoExtendBandwidthValues = {
  autoExtendBandwidth: number;
  isAutoExtendEnabled: boolean;
};

export function ProxyAutoExtendBandwidthForm() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit, setFieldValue } =
    useFormikContext<ProxyAutoExtendBandwidthValues>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseFloat(e.target.value.trim());

      setFieldValue('autoExtendBandwidth', Number.isNaN(parsedValue) ? 1 : parsedValue);
    },
    [setFieldValue],
  );

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="save" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.extend')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.autoExtendBandwidth.title')}
      subtitle={t('proxies.modals.autoExtendBandwidth.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="title">{t('common:form.isAutoExtendEnabled')}</Typography>

          <FormikSwitch name="isAutoExtendEnabled" type="checkbox" disabled={isSubmitting} />
        </Stack>

        <Collapse in={values.isAutoExtendEnabled}>
          <NumericFormat
            fullWidth
            name="autoExtendBandwidth"
            suffix=" GB"
            value={values.autoExtendBandwidth}
            onChange={handleChange}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            customInput={Field}
            component={FormikInput}
          />
        </Collapse>
      </Stack>
    </ModalContainer>
  );
}
