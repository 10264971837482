import { useCallback, useEffect, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import type { EnableSoftwareTwoFactorModel } from 'store/accounts/models';
import { useEnableSoftwareTwoFactorMutation } from 'store/api';
import type { Nullable } from 'types';
import { getValidationErrors } from 'utils/error';

import {
  SoftwareTwoFactorAuthenticationForm,
  type SoftwareTwoFactorAuthenticationValues,
} from './SoftwareTwoFactorAuthenticationForm';

type HandleSubmit = FormikConfig<SoftwareTwoFactorAuthenticationValues>['onSubmit'];

export type SoftwareTwoFactorAuthenticationModalProps = {
  onConfirm: () => void;
};

export function SoftwareTwoFactorAuthenticationModal({ onConfirm }: SoftwareTwoFactorAuthenticationModalProps) {
  const posthog = usePostHog();
  const [configuration, setConfiguration] = useState<Nullable<EnableSoftwareTwoFactorModel>>(null);

  const [enableSoftwareTwoFactor, { isLoading }] = useEnableSoftwareTwoFactorMutation();

  const getInitialConfiguration = useCallback(async () => {
    const response = await enableSoftwareTwoFactor({ code: null }).unwrap();

    setConfiguration(response);
  }, [enableSoftwareTwoFactor]);

  const initialValues = useMemo<SoftwareTwoFactorAuthenticationValues>(() => {
    return { code: '' };
  }, []);

  const validationSchema = useMemo<Yup.Schema<SoftwareTwoFactorAuthenticationValues>>(() => {
    return Yup.object().shape({
      code: Yup.string().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ code }, { setErrors }) => {
      try {
        await enableSoftwareTwoFactor({ code }).unwrap();

        posthog?.capture(posthogEvents.account.security.twoFactor.enabled.success, { type: 'totp' });

        onConfirm();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.account.security.twoFactor.enabled.failed, { type: 'totp' });
      }
    },
    [enableSoftwareTwoFactor, onConfirm, posthog],
  );

  useEffect(() => {
    getInitialConfiguration();
  }, [getInitialConfiguration]);

  return (
    <Formik<SoftwareTwoFactorAuthenticationValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <SoftwareTwoFactorAuthenticationForm configuration={configuration} isLoading={isLoading} />
    </Formik>
  );
}
