import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { posthogEvents } from 'constants/posthogEvents';
import { useDisableHardwareTwoFactorMutation, useDisableSoftwareTwoFactorMutation } from 'store/api';
import { getValidationErrors } from 'utils/error';

import { PasswordForm, type PasswordValues } from './PasswordForm';

type HandleSubmit = FormikConfig<PasswordValues>['onSubmit'];

type PasswordModalsValues = {
  password: string;
};

export type PasswordModalProps = {
  onConfirm: (type: string) => void;
  type: 'totp' | 'webauthn';
};

export function PasswordModal({ type, onConfirm }: PasswordModalProps) {
  const posthog = usePostHog();
  const [disableHardwareTwoFactor] = useDisableHardwareTwoFactorMutation();
  const [disableSoftwareTwoFactor] = useDisableSoftwareTwoFactorMutation();

  const initialValues = useMemo<PasswordValues>(() => {
    return { password: '' };
  }, []);

  const validationSchema = useMemo<Yup.Schema<PasswordValues>>(() => {
    return Yup.object().shape({
      password: Yup.string().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ password }, { setErrors }) => {
      try {
        if (type === 'totp') {
          await disableSoftwareTwoFactor({ password }).unwrap();
        } else {
          await disableHardwareTwoFactor({ password }).unwrap();
        }

        onConfirm(type);
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog.capture(posthogEvents.account.security.twoFactor.disabled.failed, { type });
      }
    },
    [disableHardwareTwoFactor, disableSoftwareTwoFactor, onConfirm, posthog, type],
  );

  return (
    <Formik<PasswordModalsValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <PasswordForm />
    </Formik>
  );
}
