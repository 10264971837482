import { useMemo, useRef } from 'react';

import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import type { Action, Nullable } from 'types';

import { BulkActionsMenu } from './BulkActionsMenu';

export type TableItemID = string | number;

type ActionType = {
  action: Action;
};

type ActionsType = {
  actions: Action[];
};

export type BulkActionsType = (ActionType | ActionsType) & {
  selectedOne: string;
  selectedMultiple: string;
  selectedAll: string;
  selectAllAction: string;
};

export type BulkActionsProps = BulkActionsType & {
  ids?: TableItemID[];
  total: number;
  isOpen?: boolean;
  isAllSelected: boolean;
  onSelectAll: () => void;
  onCloseClick: () => void;

  isAllButtonDisabled?: boolean;
};

export function BulkActions({
  ids = [],
  isOpen = false,
  selectedOne,
  selectedMultiple,
  selectedAll,
  selectAllAction,
  total,
  isAllSelected,
  isAllButtonDisabled,
  onCloseClick,
  onSelectAll,
  ...props
}: BulkActionsProps) {
  const { t } = useTranslation();

  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const label = useMemo(() => {
    if (isAllSelected) {
      return selectedAll;
    }

    return ids.length === 1 ? selectedOne : selectedMultiple;
  }, [ids.length, isAllSelected, selectedAll, selectedMultiple, selectedOne]);

  return (
    <Snackbar ref={containerRef} open={isOpen}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 0.5 }} alignItems={{ md: 'center' }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              label={isAllSelected ? total : ids.length}
              sx={{
                '--Chip-background': 'var(--mui-palette-common-white)',
                '--Chip-color': 'var(--mui-palette-primary-main)',
              }}
            />

            <Typography variant="body-2" color="var(--mui-palette-common-white)">
              {label}
            </Typography>
          </Stack>

          <Fade in={!isAllSelected && !isAllButtonDisabled} unmountOnExit>
            <Link variant="body-2" color="neutral.200" onClick={() => onSelectAll()} underline="always">
              {selectAllAction}
            </Link>
          </Fade>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          {'action' in props ? (
            <Button size="medium" onClick={props.action.onClick}>
              {props.action.label}
            </Button>
          ) : (
            props.actions.length > 0 && (
              <BulkActionsMenu
                actions={props.actions}
                anchorRef={containerRef}
                MenuProps={{
                  anchorOrigin: { vertical: -16, horizontal: 'right' },
                  transformOrigin: { vertical: 'bottom', horizontal: 'right' },
                }}
                renderIndicator={({ onClick }) => (
                  <Button size="medium" onClick={(e) => onClick(e)}>
                    {t('common:buttons.takeAnAction')}...
                  </Button>
                )}
              />
            )
          )}

          <IconButton onClick={onCloseClick}>
            <Icon name="cross" size="large" color="var(--mui-palette-neutral-400)" />
          </IconButton>
        </Stack>
      </Stack>
    </Snackbar>
  );
}
