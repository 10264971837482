export const posthogEvents = {
  account: {
    general: {
      profile: {
        success: 'user_details_edited',
        failure: 'user_details_edit_failed',
      },
    },
    notifications: {
      disabled: 'notifications_all_disabled',
      enabled: 'notifications_all_enabled',
    },
    security: {
      password: {
        success: 'user_password_reset',
        failed: 'user_password_reset_failed',
      },
      twoFactor: {
        disabled: {
          success: 'security_2fa_disabled',
          failed: 'security_2fa_disable_failed',
        },
        enabled: {
          success: 'security_2fa_enabled',
          failed: 'security_2fa_enable_failed',
        },
      },
    },
    billing: {
      invoices: {
        view: 'account_invoices_view',
        bulk: {
          success: 'account_invoices_download_success',
          failed: 'account_invoices_download_failed',
        },
      },
      invoice: {
        view: 'account_invoice_view',
      },
      transactions: {
        view: 'account_transactions_view',
      },
    },
  },

  auth: {
    signIn: {
      success: 'user_logged_in',
      failed: 'user_login_failed',
    },
    signUp: {
      success: 'user_registered',
      failed: 'user_registration_failed',
    },
    signOut: 'user_logged_out',
    oAuth: 'user_login_oauth',
    multiFactor: {
      started: 'user_login_2fa',
      success: 'user_2fa_sign_in_success',
      failed: 'user_2fa_sign_in_failed',
    },
    forgotPassword: {
      success: 'user_forgot_password_success',
      failed: 'user_forgot_password_failed',
    },
    resetPassword: {
      success: 'user_reset_password_success',
      failed: 'user_reset_password_failed',
    },
  },

  common: {
    languageChanged: 'user_language_changed',
    switchedToVPNCheap: 'switched_to_vpn_cheap',
    review: {
      dismiss: 'review_dismiss_clicked',
      leaveReview: 'leave_a_review_clicked',
    },
  },

  email: {
    confirmation: {
      resent: 'email_confirmation_resent',
      displayed: 'order_email_confirmation_displayed',
    },
  },

  proxy: {
    extendBandwidth: {
      success: 'proxy_bandwidth_extended',
      failed: 'proxy_bandwidth_extend_failed',
    },
    extendPeriod: {
      success: 'proxy_period_extended',
      failed: 'proxy_period_extend_failed',
    },
    reactivated: {
      success: 'proxy_reactivated',
      failed: 'proxy_reactivated_failed',
    },
    autoExtend: {
      enabled: {
        success: 'proxy_auto_extend_enabled',
        failed: 'proxy_auto_extend_enable_failed',
      },
      disabled: {
        success: 'proxy_auto_extend_disabled',
        failed: 'proxy_auto_extend_disable_failed',
      },
    },
    autoExtendBandwidth: {
      enabled: {
        success: 'proxy_auto_extend_bandwidth_enabled',
        failed: 'proxy_auto_extend_bandwidth_enable_failed',
      },
      disabled: {
        success: 'proxy_auto_extend_bandwidth_disabled',
        failed: 'proxy_auto_extend_bandwidth_disable_failed',
      },
    },
    changeProtocol: {
      success: 'proxy_protocol_changed',
      failed: 'proxy_protocol_change_failed',
    },
    downloadCredentials: {
      success: 'proxy_credentials_download',
      failed: 'proxy_credentials_download_failed',
    },
    globalIPWhitelist: {
      success: 'proxy_globalIpWhitelist_modified',
      failed: 'proxy_globalIpWhitelist_modify_failed',
    },
    ipWhitelist: {
      success: 'proxy_ipWhitelist_modified',
      failed: 'proxy_ipWhitelist_modify_failed',
    },
    note: {
      added: {
        success: 'proxy_note_added',
        failed: 'proxy_note_add_failed',
      },
      deleted: {
        success: 'proxy_note_deleted',
        failed: 'proxy_note_delete_failed',
      },
    },
    regenerateCredentials: {
      success: 'proxy_credentials_regenerated',
      failed: 'proxy_credentials_regeneration_failed',
    },
    upgradeBandwidthSpeed: {
      success: 'proxy_upgrade_bandwidth_speed',
      failed: 'proxy_upgrade_bandwidth_speed_failed',
    },
    upgradeThreads: {
      success: 'proxy_upgrade_threads',
      failed: 'proxy_upgrade_threads_failed',
    },
    authenticationType: {
      success: 'proxy_authentication_type_modified',
      failed: 'proxy_authentication_type_modify_failed',
    },
    port: {
      success: 'proxy_port_changed',
      failed: 'proxy_port_change_failed',
    },
    bulk: {
      extendBandwidth: {
        success: 'proxy_bandwidth_extended_bulk',
        failed: 'proxy_bandwidth_extend_bulk_failed',
      },
      extendPeriod: {
        success: 'proxy_period_extended_bulk',
        failed: 'proxy_period_extend_bulk_failed',
      },
      autoExtend: {
        success: 'proxy_auto_extend_bulk',
        failed: 'proxy_auto_extend_bulk_failed',
      },
      changeProtocol: {
        success: 'proxy_protocol_changed_bulk',
        failed: 'proxy_protocol_change_bulk_failed',
      },
      authenticationType: {
        success: 'proxy_authentication_type_modified_bulk',
        failed: 'proxy_authentication_type_modify_bulk_failed',
      },
    },
    actions: {
      details: 'proxy_action_details_click',
      autoExtend: 'proxy_action_auto_extend_click',
      changeAuthenticationMethod: 'proxy_action_change_authentication_method_click',
      changeProtocol: 'proxy_action_change_protocol_click',
      downloadCredentials: 'proxy_action_download_credentials_click',
      extendBandwidth: 'proxy_action_extend_bandwidth_click',
      extendPeriod: 'proxy_action_extend_period_click',
      reactivate: 'proxy_action_reactivate_click',
    },
    generateCredentials: 'rotating_residential_creds_generated',
    generateNetNutCredentials: 'rotating_residential_net_nut_credentials_generated',
    replacement: {
      proxyReplacementInitiated: 'proxy_replacement_initiated',
      proxyReplacementSuccess: 'proxy_replacement_success',
      proxyReplacementFailure: 'proxy_replaced_failure',
    },
  },

  marketplace: {
    dealSelected: 'marketplace_deal_selected',
  },

  vpn: {
    autoExtend: {
      enabled: {
        success: 'vpn_auto_extend_enabled',
        failed: 'vpn_auto_extend_enable_failed',
      },
      disabled: {
        success: 'vpn_auto_extend_disabled',
        failed: 'vpn_auto_extend_disable_failed',
      },
    },
    extendPeriod: {
      success: 'vpn_period_extended',
      failed: 'vpn_period_extend_failed',
    },
  },

  // Funnels
  topUp: {
    click: 'topup_click',
    success: 'topup_success',
    failure: 'topup_failure',
    canceled: 'topup_canceled',
  },

  orderTopUp: {
    click: 'order_topup_click',
    success: 'order_topup_success',
    failure: 'order_topup_failure',
    canceled: 'order_topup_canceled',
  },

  order: {
    started: 'order_started',
    continued: 'order_continued',
    typeSelected: 'order_proxy_type_selected',
    vpnPlanSelected: 'order_vpn_plan_selected',
    codeApplied: 'order_coupon_code_applied',
    invalidCode: 'order_invalid_coupon_code',
    exit: 'order_exit',
    outOfStock: 'order_out_of_stock',
    numberOfTrialProxiesExceeded: 'order_trial_exceeded',

    // extended metrics
    visited: 'order_proxy_visited',
    packageTypeSelected: 'order_proxy_package_type_selected',
    ipvSelected: 'order_proxy_ipv_selected',
    countrySelected: 'order_proxy_country_selected',
    ispSelected: 'order_proxy_isp_selected',
    periodSelected: 'order_proxy_period_selected',
    proxyEditClicked: 'order_proxy_edit_clicked',
    configurationDeadEnd: 'order_proxy_configuration_dead_end',

    countryRequest: {
      success: 'order_country_request_success',
      failure: 'order_country_request_failure',
    },

    personalDetails: {
      visited: 'order_user_details_visited',
      skipped: 'order_user_details_skipped',
      filled: 'order_user_details_filled',
      failed: 'order_user_details_failed',
    },

    checkout: {
      visited: 'order_checkout_visited',
      paymentChosen: 'order_checkout_payment_chosen',
      initiated: 'order_checkout_initiated',
      executed: 'order_executed',
      failure: 'order_failure',
    },

    packages: {
      standard: 'order_standard_proxy',
      premium: 'order_premium_proxy',
    },

    staticResidentialTimePeriod: 'order_success_static_time_period_amount',
    rotatingBandwidth: 'order_success_rotating_bandwidth_amount',

    setupCompleted: 'order_proxy_setup_form_completed',
    experiencedUser: 'experienced_user',
    newbieUser: 'newbie_user',
    quizClicked: 'order_quiz_clicked',

    v2: {
      countrySelected: 'order_v2_proxy_country_selected',
      ispSelected: 'order_v2_proxy_isp_selected',
      periodSelected: 'order_v2_proxy_period_selected',
    },
  },

  quiz: {
    started: 'quiz_started',
    purpose: 'quiz_purpose',
    targetCountry: 'quiz_target_country',
    targetState: 'quiz_target_state',
    targetDomain: 'quiz_target_domain',
    selectedRecommendation: 'quiz_selected_recommendation',
  },
};
