import { useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikSwitch } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';

export type ProxyAutoExtendValues = {
  isAutoExtendEnabled: boolean;
};

type ProxyAutoExtendFormProps = {
  isLoading?: boolean;
};

export function ProxyAutoExtendForm({ isLoading }: ProxyAutoExtendFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, handleSubmit } = useFormikContext<ProxyAutoExtendValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth disabled={isLoading} loading={isSubmitting}>
        {t('common:buttons.confirm')}
      </Button>,
    ];
  }, [hideModal, isLoading, isSubmitting, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.autoExtend.title')}
      subtitle={t('proxies.modals.autoExtend.subtitle')}
      actions={actions}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="title">{t('common:form.isAutoExtendEnabled')}</Typography>

        {isLoading ? (
          <Skeleton width={40} height={24} />
        ) : (
          <FormikSwitch name="isAutoExtendEnabled" type="checkbox" disabled={isSubmitting} />
        )}
      </Stack>
    </ModalContainer>
  );
}
