import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import { ORDER_PATH_TO_STEP_MAP, ORDER_STEP_TO_PATH_MAP } from 'modules/orders/v2/constants';
import type { OrderSetupValues } from 'modules/orders/v2/types';
import { paths } from 'paths';
import type { OrderStep, PlanID, ServiceID } from 'store/orders/v2/types';
import type { Nullable } from 'types';

export function isOrderStateMalformed(
  serviceId: ServiceID,
  planId: Nullable<PlanID>,
  setup: Nullable<OrderSetupValues>,
): boolean {
  switch (serviceId) {
    case 'vpn':
      return !isNil(planId) || !isNil(setup);
    case 'rotating-mobile':
    case 'rotating-residential':
    case 'static-datacenter-ipv6':
      return !isNil(planId) || isNil(setup);
    case 'static-datacenter-ipv4':
    case 'static-residential-ipv4':
      return isNil(planId) || isNil(setup);
    default:
      return false;
  }
}

export function isOrderStepMalformed(currentStep: OrderStep, pathname: string) {
  switch (currentStep) {
    case 'checkout':
      return !isEqual(pathname, paths.order.v2.checkout);
    case 'details':
      return !isEqual(pathname, paths.order.v2.details);
    case 'setup':
      return !isEqual(pathname, paths.order.v2.index);
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`${currentStep} is not supported - Please do not mess with the logic here.`);
  }
}

export function getOrderStep(path: string): Nullable<OrderStep> {
  const currentStep = ORDER_PATH_TO_STEP_MAP[path];

  return currentStep ?? null;
}

export function getOrderPath(step: OrderStep): string {
  return ORDER_STEP_TO_PATH_MAP[step];
}
