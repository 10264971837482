import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
import { PaymentVendorIcon } from 'components/PaymentVendorIcon';

export function SecurePayment() {
  const { t } = useTranslation();

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between" alignItems="center">
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems="center">
        <Icon name="shield-tick" color="var(--mui-palette-primary-main)" />

        <Stack textAlign={{ xs: 'center', md: 'start' }}>
          <Typography variant="title">{t('order.v2.payments.title')}</Typography>

          <Typography variant="body-2" color="text.secondary">
            {t('order.v2.payments.subtitle')}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={{ xs: 1, md: 2 }} flexWrap="wrap" justifyContent="center" alignItems="center">
        <PaymentVendorIcon type="visa" size={32} />
        <PaymentVendorIcon type="maestro" size={32} />
        <PaymentVendorIcon type="master-card" size={32} />
        <PaymentVendorIcon type="amex" size={32} />
        <PaymentVendorIcon type="pay-pal" size={32} />
        <PaymentVendorIcon type="ali-pay" size={32} />
        <PaymentVendorIcon type="coingate" size={32} />
      </Stack>
    </Stack>
  );
}
