import { loadingButtonClasses } from '@mui/lab/LoadingButton';
import { buttonClasses } from '@mui/material/Button';
import type { Components, Theme } from '@mui/material/styles';

function getContainedVars(color: string): Record<string, string> {
  return {
    '--Button-contained-background': `var(--mui-palette-${color}-main)`,
    '--Button-contained-color': `var(--mui-palette-${color}-contrastText)`,
  };
}

function getOutlinedVars(color: string): Record<string, string> {
  const vars = {
    '--Button-outlined-background': 'transparent',
    '--Button-outlined-color': `var(--mui-palette-${color}-main)`,
    '--Button-outlined-border': `var(--mui-palette-${color}-main)`,
    '--Button-outlined-hover-background': `var(--mui-palette-${color}-hovered)`,
    '--Button-outlined-active-background': `var(--mui-palette-${color}-activated)`,
  };

  if (color === 'secondary') {
    vars['--Button-outlined-background'] = 'var(--mui-palette-common-white)';
    vars['--Button-outlined-color'] = 'var(--mui-palette-neutral-950)';
    vars['--Button-outlined-border'] = 'rgba(16, 26, 51, 0.12)';
    vars['--Button-outlined-hover-background'] = 'var(--mui-palette-neutral-50)';
    vars['--Button-outlined-active-background'] = 'var(--mui-palette-neutral-100)';
  }

  return vars;
}

function getTextVars(color: string): Record<string, string> {
  const vars = {
    '--Button-text-color': `var(--mui-palette-${color}-main)`,
    '--Button-text-hover-background': `var(--mui-palette-${color}-hovered)`,
    '--Button-text-active-background': `var(--mui-palette-${color}-activated)`,
  };

  if (color === 'secondary') {
    vars['--Button-text-color'] = 'var(--mui-palette-neutral-950)';
    vars['--Button-text-hover-background'] = 'var(--mui-palette-neutral-50)';
    vars['--Button-text-active-background'] = 'var(--mui-palette-neutral-100)';
  }

  return vars;
}

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: { size: 'large', variant: 'contained' },
  styleOverrides: {
    root: {
      borderRadius: 'var(--Button-borderRadius)',
      minHeight: 'var(--Button-minHeight)',
      minWidth: 'unset',
      paddingBlock: 'var(--Button-paddingBlock)',
      paddingInline: 'var(--Button-paddingInline)',

      fontSize: 'var(--Button-fontSize)',
      lineHeight: 'var(--Button-lineHeight)',
      fontWeight: 'var(--Button-fontWeight, 700)',

      textTransform: 'none',
      whiteSpace: 'nowrap',

      '&:focus-visible': { outline: '2px solid var(--mui-palette-primary-main)' },

      [`&.${buttonClasses.disabled}.${loadingButtonClasses.loading}`]: { opacity: 1 },
    },

    endIcon: { margin: 0, marginLeft: 'var(--Button-icon-gap)' },
    startIcon: { margin: 0, marginRight: 'var(--Button-icon-gap)' },

    text: {
      color: 'var(--Button-text-color)',

      '&:hover': { backgroundColor: 'var(--Button-text-hover-background)' },
      '&:active': { backgroundColor: 'var(--Button-text-active-background)' },

      [`&.${buttonClasses.disabled}`]: {
        color: 'var(--Button-text-color)',
        opacity: 0.48,

        [`&.${loadingButtonClasses.loading}`]: {
          [`& .${loadingButtonClasses.loadingIndicator}`]: { color: 'var(--Button-textColor)' },
        },
      },
    },
    textPrimary: getTextVars('primary'),
    textSecondary: getTextVars('secondary'),
    textSuccess: getTextVars('success'),
    textError: getTextVars('error'),
    textWarning: getTextVars('warning'),
    textInfo: getTextVars('info'),

    outlined: {
      color: 'var(--Button-outlined-color)',
      borderColor: 'var(--Button-outlined-border)',
      backgroundColor: 'var(--Button-outlined-background)',

      '&:hover': {
        borderColor: 'var(--Button-outlined-border)',
        backgroundColor: 'var(--Button-outlined-hover-background)',
      },
      '&:active': { backgroundColor: 'var(--Button-outlined-active-background)' },

      [`&.${buttonClasses.disabled}`]: {
        opacity: 0.48,
        color: 'var(--Button-outlined-color)',
        borderColor: 'var(--Button-outlined-border)',

        [`&.${loadingButtonClasses.loading}`]: {
          backgroundColor: 'var(--Button-outlined-background)',
          borderColor: 'var(--Button-outlined-border)',

          [`& .${loadingButtonClasses.loadingIndicator}`]: {
            color: 'var(--Button-outlined-color)',
          },
        },
      },
    },
    outlinedPrimary: getOutlinedVars('primary'),
    outlinedSecondary: getOutlinedVars('secondary'),
    outlinedSuccess: getOutlinedVars('success'),
    outlinedError: getOutlinedVars('error'),
    outlinedWarning: getOutlinedVars('warning'),
    outlinedInfo: getOutlinedVars('info'),

    contained: {
      boxShadow: 'none',
      backgroundColor: 'var(--Button-contained-background)',

      '&:hover': { boxShadow: 'none' },

      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: 'var(--Button-contained-background)',
        color: 'var(--Button-contained-color)',
        opacity: 0.48,

        [`&.${loadingButtonClasses.loading}`]: {
          backgroundColor: 'var(--Button-contained-background)',

          [`& .${loadingButtonClasses.loadingIndicator}`]: {
            color: 'var(--Button-contained-color)',
          },
        },
      },
    },
    containedPrimary: getContainedVars('primary'),
    containedSecondary: getContainedVars('secondary'),
    containedSuccess: getContainedVars('success'),
    containedError: getContainedVars('error'),
    containedWarning: getContainedVars('warning'),
    containedInfo: getContainedVars('info'),

    sizeSmall: {
      '--Button-borderRadius': '8px',
      '--Button-minHeight': '28px',
      '--Button-paddingBlock': '4px',
      '--Button-paddingInline': '8px',
      '--Button-fontSize': '14px',
      '--Button-lineHeight': '20px',

      '--Button-icon-gap': '4px',
    },
    sizeMedium: {
      '--Button-borderRadius': '8px',
      '--Button-minHeight': '36px',
      '--Button-paddingBlock': '8px',
      '--Button-paddingInline': '12px',
      '--Button-fontSize': '14px',
      '--Button-lineHeight': '20px',

      '--Button-icon-gap': '6px',
    },
    sizeLarge: {
      '--Button-borderRadius': '8px',
      '--Button-minHeight': '48px',
      '--Button-paddingBlock': '12px',
      '--Button-paddingInline': '16px',
      '--Button-fontSize': '16px',
      '--Button-lineHeight': '24px',

      '--Button-icon-gap': '8px',
    },
  },
};
