import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import * as Yup from 'yup';

import { shouldBeHandledByStripe } from 'modules/billing/helpers';
import { useTopUpModalContext } from 'modules/billing/modals/TopUpModal/context';
import { buildTopUpPaymentOptions } from 'modules/billing/modals/TopUpModal/helpers';
import type { TopUpValues } from 'modules/billing/modals/TopUpModal/types';
import type { TopUpPaymentOptionType } from 'modules/billing/types';

import { TopUpForm } from './TopUpForm';
import { TopUpFormLoading } from './TopUpFormLoading';

type TopUpStepProps = {
  initialValues?: Partial<TopUpValues>;
};

type HandleSubmit = FormikConfig<TopUpValues>['onSubmit'];

export function TopUpStep({ initialValues: propInitialValues }: TopUpStepProps) {
  const { creditCard, isCardLoading, values: topUpValues, handleTopUp, setStep, setValues } = useTopUpModalContext();
  const options = useMemo(() => buildTopUpPaymentOptions(creditCard), [creditCard]);

  const initialValues = useMemo<TopUpValues>(
    () => ({
      ...options[0],
      ...propInitialValues,
      ...topUpValues,
      amount: propInitialValues?.amount ?? topUpValues?.amount ?? 9,
    }),
    [options, propInitialValues, topUpValues],
  );

  const validationSchema = useMemo<Yup.Schema<TopUpValues>>(() => {
    return Yup.object().shape({
      paymentFee: Yup.string().required(),
      type: Yup.mixed<TopUpPaymentOptionType>()
        .oneOf([
          'alipay',
          'another-card',
          'blik',
          'crypto',
          'existing-card',
          'giropay',
          'ideal',
          'sofort',
          'new-card',
          'p24',
          'paypal',
        ])
        .required(),
      amount: Yup.number().minimumTopUpValue().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async (values) => {
      setValues(values);

      if (shouldBeHandledByStripe(values.type)) {
        return setStep('stripe');
      }

      await handleTopUp({ values, onSuccess: () => setStep('status') });
    },
    [handleTopUp, setStep, setValues],
  );

  if (isCardLoading && !creditCard) {
    return <TopUpFormLoading />;
  }

  return (
    <Formik<TopUpValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnChange
      validateOnMount
    >
      <TopUpForm options={options} />
    </Formik>
  );
}
