import { useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { MultiUpdatesAccordion } from 'modules/proxies/modals/components';
import { useGetChangeAuthenticationTypeOptionsQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { AuthenticationType } from 'store/proxies/types';
import type { Option } from 'types';

import { IPWhitelistForm } from './IPWhitelistForm';

export type ChangeAuthenticationMethodValues = {
  type: AuthenticationType;
  ipWhitelist: string[];
};

export type ChangeAuthenticationMethodFormProps = {
  proxyIds: Array<ProxyModel['id']>;
};

export function ChangeAuthenticationMethodForm({ proxyIds }: ChangeAuthenticationMethodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext<ChangeAuthenticationMethodValues>();

  const { data: authTypeResponse, isLoading: isAuthTypeLoading } = useGetChangeAuthenticationTypeOptionsQuery(
    proxyIds[0],
  );

  const options = useMemo<Array<Option<AuthenticationType>>>(() => {
    return (
      authTypeResponse?.availableAuthenticationTypes.map((value) => {
        return { value, label: t(`common:form.${value}`) };
      }) ?? []
    );
  }, [authTypeResponse, t]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.change')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeAuthMethod.title')}
      subtitle={t('proxies.modals.changeAuthMethod.subtitle')}
      actions={actions}
    >
      <Stack direction="column" spacing={4}>
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <FormikAutocomplete
          disableClearable
          options={options}
          name="type"
          helperText={
            values.type === AuthenticationType.USERNAME_PASSWORD && !isAuthTypeLoading
              ? t('common:form.helpers.credentials')
              : undefined
          }
        />

        <Collapse in={values.type === AuthenticationType.IP_WHITELIST && !isAuthTypeLoading} unmountOnExit>
          <IPWhitelistForm proxyIds={proxyIds} />
        </Collapse>
      </Stack>
    </ModalContainer>
  );
}
