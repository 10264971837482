import { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { BalanceSummary } from 'modules/proxies/modals/components';
import { useGetReactivateProxyPriceQuery } from 'store/api';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

export type ReactivateProxyValues = {
  periodInMonths: number;
};

export type ReactivateProxyFormProps = {
  proxyId: number;
};

export function ReactivateProxyForm({ proxyId }: ReactivateProxyFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const { values, handleSubmit, isSubmitting, isValid } = useFormikContext<ReactivateProxyValues>();

  const { data: reactivationPrice, isFetching: isPriceFetching } = useGetReactivateProxyPriceQuery({
    proxyId,
    periodInMonths: values.periodInMonths,
  });

  const periodOptions = useMemo<Array<Option<number>>>(() => {
    return [1, 2, 3, 6, 12].map((count) => ({
      value: count,
      label: t('common:form.month', { count }),
    }));
  }, [t]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.reactivate')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, isPriceFetching, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.reactivate.title')}
      subtitle={t('proxies.modals.reactivate.subtitle')}
      actions={actions}
    >
      <Stack direction="column" rowGap={4}>
        <FormikAutocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack direction="column" spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isPriceFetching} price={reactivationPrice?.finalPrice} />

          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
                {t('proxies.modals.reactivate.proxy')}
              </Typography>

              {isPriceFetching ? (
                <Skeleton height={20} width={100} />
              ) : (
                <Typography variant="body-2">{CurrencyFormatter.format(reactivationPrice?.unitPrice || 0)}</Typography>
              )}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
                {t('proxies.modals.reactivate.fee')}
              </Typography>

              {isPriceFetching ? (
                <Skeleton height={20} width={100} />
              ) : (
                <Typography variant="body-2">
                  {CurrencyFormatter.format(reactivationPrice?.additionalAmountAfterDiscount || 0)}
                </Typography>
              )}
            </Stack>
          </>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">
                {CurrencyFormatter.format(reactivationPrice?.finalPrice ?? 0)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
