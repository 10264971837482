import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import type { Option } from 'types';

export type ChangeThreadsValues = {
  threads: number;
};

type ChangeThreadsFormProps = {
  options: Array<Option<number>>;
  isLoading?: boolean;
};

export function ChangeThreadsForm({ options, isLoading }: ChangeThreadsFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<ChangeThreadsValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.change')}
      </Button>,
    ];
  }, [hideModal, isLoading, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.changeThreads.title')}
      subtitle={t('proxies.modals.upgradeThreads.subtitle')}
      actions={actions}
    >
      <FormikAutocomplete size="small" disableClearable options={options} name="threads" />
    </ModalContainer>
  );
}
