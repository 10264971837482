import { useCallback, useMemo } from 'react';

export function useZendeskAPI() {
  const openZendeskChat = useCallback(() => {
    window.zE?.('messenger', 'open');
  }, []);

  const hideZendeskChat = useCallback(() => {
    window.zE?.('messenger', 'close');
    window.zE?.('messenger', 'hide');
  }, []);

  const showZendeskChat = useCallback(() => {
    window.zE?.('messenger', 'show');
  }, []);

  return useMemo(
    () => ({ openZendeskChat, hideZendeskChat, showZendeskChat }),
    [hideZendeskChat, openZendeskChat, showZendeskChat],
  );
}
