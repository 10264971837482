import { useCallback, useMemo } from 'react';

import { usePostHog } from 'posthog-js/react';

import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useAppDispatch, useAppSelector } from 'store';
import { setCurrentStep } from 'store/orders/v1/reducers';
import type { OrderStep } from 'store/orders/v1/types';
import { NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';
import { PersistService } from 'utils/persist';

export function useOrderFlow() {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const isNewCustomer = useIsNewCustomer();

  const { selectedNetworkType } = useAppSelector((state) => state.order);

  const isValidNetworkType = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Object.values(NetworkType).includes(selectedNetworkType!);
  }, [selectedNetworkType]);

  const isNetworkTypeSelected = useMemo(() => !!selectedNetworkType, [selectedNetworkType]);

  const isVPNFlow = useMemo(() => !!PersistService.get('vpn-flow'), []);

  const isMarketplaceFlow = useMemo(() => !!PersistService.get<boolean>('marketplace-flow'), []);

  const startNewOrderFlow = useCallback(
    (step: Nullable<OrderStep>) => {
      if (step === 'vpn-plan') {
        PersistService.set('vpn-flow', 'true');
      }

      posthog?.capture(posthogEvents.order.started, { isNewCustomer });
      dispatch(setCurrentStep(step));
    },
    [dispatch, isNewCustomer, posthog],
  );

  return { isNetworkTypeSelected, isVPNFlow, isMarketplaceFlow, isValidNetworkType, startNewOrderFlow };
}
