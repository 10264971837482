import { accordionSummaryClasses } from '@mui/material';
import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

export const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: { expandIcon: <Icon name="chevron-down" size="small" color="var(--mui-palette-neutral-500)" /> },
  styleOverrides: {
    root: {
      '--AccordionSummary-padding': '0px',
      '--AccordionSummary-minHeight': 'auto',
      '--AccordionSummary-width': '100%',

      '--AccordionSummary-expanded-margin': '0px',

      minHeight: 'var(--AccordionSummary-minHeight) !important',
      padding: 'var(--AccordionSummary-padding)',
      width: 'var(--AccordionSummary-width)',

      [`& .${accordionSummaryClasses.content}, & .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]:
        { margin: 'var(--AccordionSummary-expanded-margin)' },
    },
  },
};
