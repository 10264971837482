import type { ChangeEvent } from 'react';

import MuiPagination from '@mui/material/Pagination';
import type { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Select } from 'components/form-partials/base';
import type { Option } from 'types';

export type PaginationProps = {
  page: number;
  perPage: number;
  pages: number;
  options?: Array<Option<number>>;
  label?: string;
  disabled?: boolean;
  onPageChange: (e: ChangeEvent<unknown>, newPage: number) => void;
  onPerPageChange?: (e: SelectChangeEvent<number>) => void;
};

export const DEFAULT_OPTIONS: Array<Option<number>> = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export function Pagination({
  page,
  perPage = 20,
  pages,
  options = DEFAULT_OPTIONS,
  label,
  disabled,
  onPageChange,
  onPerPageChange,
}: PaginationProps) {
  return (
    <Stack
      spacing={{ xs: 4, sm: 0 }}
      direction={{ xs: 'column-reverse', sm: 'row' }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack flex="1 1 auto">
        {pages > 1 && <MuiPagination disabled={disabled} count={pages} page={page} onChange={onPageChange} />}
      </Stack>

      {!!perPage && onPerPageChange && options && (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
          {label && (
            <Typography variant="body-2" color="text.secondary">
              {label}
            </Typography>
          )}

          <Select
            name="perPage"
            value={perPage}
            onChange={onPerPageChange}
            options={options}
            disabled={disabled}
            size="small"
          />
        </Stack>
      )}
    </Stack>
  );
}
