import { useEffect, useRef } from 'react';

import { TopUpStatus } from 'store/accounts/types';
import { useGetTopUpQuery } from 'store/api';
import type { Nullable } from 'types';

export function useTopUpStatus(id: Nullable<string>) {
  const skip = useRef(!id);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const query = useGetTopUpQuery({ id: id! }, { pollingInterval: 5_000, skip: skip.current });

  useEffect(() => {
    if (!id) {
      skip.current = false;
    }

    if (!query.data) return;

    if (![TopUpStatus.CANCELED, TopUpStatus.COMPLETED].includes(query.data.status)) return;

    skip.current = true;
  }, [query.data, id]);

  return query;
}
