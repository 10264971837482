import type { TokenModel } from 'store/auth/models';
import type { Nullable } from 'types';

type PersistKey =
  | 'accessToken'
  | 'refreshToken'
  | 'auth.mfa.required'
  | 'X-Switch-User'
  | 'retry-lazy-refreshed'
  | 'order-proxy-current-step'
  | 'order-proxy-network-type'
  | 'order-proxy-configuration'
  | 'order-proxy-discount-code'
  | 'order-proxy-fields-configurations'
  | 'marketplace-flow'
  | 'vpn-flow'
  | 'token-expiry-time'
  | 'language'
  | 'redirect.url'
  | 'quiz'
  | 'quiz.closed'
  | 'review.dismissed'
  // ! New Order flow
  | 'order.step';

export class PersistService {
  static get<T>(key: PersistKey): Nullable<T> {
    const value = localStorage.getItem(key);

    if (!value) return null;

    return JSON.parse(value) as T;
  }

  static set<T>(key: PersistKey, data: Nullable<T>) {
    // Remove only when data equal to null
    if (data === null) return this.remove(key);

    localStorage.setItem(key, JSON.stringify(data));
  }

  static remove(key: PersistKey) {
    localStorage.removeItem(key);
  }

  static multipleRemove(keys: PersistKey[]) {
    keys.forEach(this.remove);
  }

  static getCredentials() {
    return {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      impersonatedEmail: localStorage.getItem('X-Switch-User'),
    };
  }

  static setCredentials({ accessToken, refreshToken }: TokenModel) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  static setImpersonatedUser(email: string) {
    localStorage.setItem('X-Switch-User', email);
  }

  static getImpersonatedUser() {
    return localStorage.getItem('X-Switch-User');
  }

  static removeCredentials() {
    this.multipleRemove(['accessToken', 'refreshToken', 'X-Switch-User', 'token-expiry-time']);
  }

  static onSignOut() {
    this.multipleRemove([
      'auth.mfa.required',
      'accessToken',
      'refreshToken',
      'X-Switch-User',
      'token-expiry-time',
      'marketplace-flow',
      'order-proxy-configuration',
      'order-proxy-current-step',
      'order-proxy-discount-code',
      'order-proxy-fields-configurations',
      'order-proxy-network-type',
      'quiz',
      'review.dismissed',
      'vpn-flow',
    ]);
  }
}
