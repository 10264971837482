import type { Components, Theme } from '@mui/material/styles';

import { MuiAccordion } from './Accordion';
import { MuiAccordionDetails } from './AccordionDetails';
import { MuiAccordionSummary } from './AccordionSummary';
import { MuiAlert } from './Alert';
import { MuiAutocomplete } from './Autocomplete';
import { MuiBadge } from './Badge';
import { MuiButton } from './Button';
import { MuiCard } from './Card';
import { MuiCheckbox } from './Checkbox';
import { MuiChip } from './Chip';
import { MuiCssBaseline } from './CssBaseline';
import { MuiFab } from './Fab';
import { MuiFilledInput } from './FilledInput';
import { MuiFormControlLabel } from './FormControlLabel';
import { MuiFormGroup } from './FormGroup';
import { MuiFormHelperText } from './FormHelperText';
import { MuiFormLabel } from './FormLabel';
import { MuiIconButton } from './IconButton';
import { MuiInput } from './Input';
import { MuiInputAdornment } from './InputAdornment';
import { MuiInputBase } from './InputBase';
import { MuiInputLabel } from './InputLabel';
import { MuiLink } from './Link';
import { MuiList } from './List';
import { MuiListItem } from './ListItem';
import { MuiListItemAvatar } from './ListItemAvatar';
import { MuiListItemButton } from './ListItemButton';
import { MuiListItemIcon } from './ListItemIcon';
import { MuiListItemText } from './ListItemText';
import { MuiMenu } from './Menu';
import { MuiMenuItem } from './MenuItem';
import { MuiModal } from './Modal';
import { MuiOutlinedInput } from './OutlinedInput';
import { MuiPaginationItem } from './PaginationItem';
import { MuiPopover } from './Popover';
import { MuiRadio } from './Radio';
import { MuiSelect } from './Select';
import { MuiSkeleton } from './Skeleton';
import { MuiSnackbar } from './Snackbar';
import { MuiStack } from './Stack';
import { MuiSvgIcon } from './SvgIcon';
import { MuiSwitch } from './Switch';
import { MuiTab } from './Tab';
import { MuiTable } from './Table';
import { MuiTableCell } from './TableCell';
import { MuiTableHead } from './TableHead';
import { MuiTableRow } from './TableRow';
import { MuiTabs } from './Tabs';
import { MuiTooltip } from './Tooltip';
import { MuiTypography } from './Typography';

export const components: Components<Theme> = {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAlert,
  MuiAutocomplete,
  MuiBadge,
  MuiButton,
  MuiCard,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiFab,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormGroup,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInput,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemAvatar,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemText,
  MuiMenu,
  MuiMenuItem,
  MuiModal,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiPopover,
  MuiRadio,
  MuiSelect,
  MuiSkeleton,
  MuiSnackbar,
  MuiStack,
  MuiSvgIcon,
  MuiSwitch,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiTabs,
  MuiTooltip,
  MuiTypography,
};
