import isNil from 'lodash/isNil';

import { COUNTRIES } from 'constants/countries';
import { OUT_OF_STOCK } from 'constants/labels';
import type { PaymentOption } from 'modules/billing/types';
import { toCurrency } from 'store/accounts/helpers';
import { NetworkType } from 'store/proxies/types';
import type { Optional, Period, Option, PartialRecord, Nullable } from 'types';
import { parseTimePeriod } from 'utils/values';

import { SERVICE_WEIGHTS } from './constants';
import type {
  CountryAvailabilityMetadataDTO,
  ISPAvailabilityMetadataDTO,
  OrderSetupExecuteDTO,
  OrderSetupPriceDTO,
  OrderSetupWithMetadataDTO,
  PeriodAvailabilityMetadataDTO,
  PlanDTO,
  PlanPricingMetadataDTO,
  ServicePricingMetadataDTO,
  ServicesWithMetadataDTO,
} from './dtos';
import type {
  OrderSetupExecuteModel,
  OrderSetupFormModel,
  OrderSetupModel,
  OrderSetupPriceModel,
  PlanModel,
  PlanPricingModel,
  ServiceModel,
  ServicePricingModel,
} from './models';
import type { OrderExecutePayload, OrderSetupPayload, PeriodPayload, ServiceDetailsPayload } from './payloads';
import type { ServiceID } from './types';

// #region Utilities
function formatTimePeriod(from: Nullable<string>): PeriodPayload {
  const parsedTimePeriod = parseTimePeriod(from);

  if (!parsedTimePeriod) return { unit: 'months', value: 1 };

  const { format, value } = parsedTimePeriod;

  return { unit: format, value };
}
// #endregion Utilities

// #region Mappers for DTOs
export function toNetworkType(from: ServiceID): NetworkType {
  if (from === 'rotating-mobile') {
    return NetworkType.Mobile;
  }

  if (from === 'rotating-residential') {
    return NetworkType.Residential;
  }

  if (from === 'static-datacenter-ipv4' || from === 'static-datacenter-ipv6') {
    return NetworkType.Datacenter;
  }

  if (from === 'static-residential-ipv4') {
    return NetworkType.ResidentialStatic;
  }

  return NetworkType.VPN;
}

function toPlanPricingModel({ discount, subtotal, total, unit }: PlanPricingMetadataDTO): PlanPricingModel {
  return { discount, subtotal, total, unit };
}

function toServicePricingModel({ discount, subtotal, total, unit }: ServicePricingMetadataDTO): ServicePricingModel {
  return { discount, subtotal, total, unit };
}

export function toPlanModel({ id, label }: PlanDTO, pricing: PlanPricingMetadataDTO): PlanModel {
  return { id, label, pricing: toPlanPricingModel(pricing) };
}

export function toServiceModel({ metadata, services }: ServicesWithMetadataDTO): ServiceModel[] {
  return services
    .map<ServiceModel>(({ id, label, countries = [], plans = [] }) => {
      const servicePricing = metadata.pricing.services[id];

      if (servicePricing.plans) {
        const plansPricing = servicePricing.plans;

        return {
          id,
          label,
          countries,
          plans: plans.map((plan) => toPlanModel(plan, plansPricing[plan.id])),
          pricing: toServicePricingModel(servicePricing),
          isOrderable: metadata.orderable.services[id],
          weight: SERVICE_WEIGHTS[id],
          isVisible: metadata.visibility.services[id],
        };
      }

      return {
        id,
        label,
        countries,
        plans: [],
        pricing: toServicePricingModel(servicePricing),
        isOrderable: metadata.orderable.services[id],
        weight: SERVICE_WEIGHTS[id],
        isVisible: metadata.visibility.services[id],
      };
    })
    .filter(({ isVisible }) => isVisible)
    .sort((a, b) => a.weight - b.weight);
}

function toCountryOptionsModel(
  countries: string[],
  availability?: CountryAvailabilityMetadataDTO,
): Array<Option<string, { availability: string }>> {
  return countries
    .map((code) => {
      const country = COUNTRIES.find((c) => c.value === code);

      if (!country) return null;

      return { ...country, parameters: { availability: availability?.[code] ?? OUT_OF_STOCK } };
    })
    .filter((v) => !isNil(v));
}

function toISPOptionsModel(
  isps: Record<string, Array<{ id: string; label: string }>>,
  availability?: ISPAvailabilityMetadataDTO,
): Record<string, Array<Option<string, { availability: string }>>> {
  return Object.entries(isps).reduce<Record<string, Array<Option<string, { availability: string }>>>>(
    (acc, [country, data]) => ({
      ...acc,
      [country]: data.map(({ id, label }) => ({
        value: id,
        label,
        parameters: { availability: availability?.[country][id] ?? OUT_OF_STOCK },
      })),
    }),

    {},
  );
}

function toPackageOptionsModel(from: Array<{ id: string; name: string }>): Array<Option<string>> {
  return from.map(({ id }) => ({ label: id, value: id }));
}

function toPeriodOptionsModel(
  periods: PartialRecord<Period, number[]>,
  availability?: PeriodAvailabilityMetadataDTO,
): Record<Period, Array<Option<string, Optional<{ count: number; limit: number }>>>> {
  return Object.entries(periods).reduce<
    Record<Period, Array<Option<string, Optional<{ count: number; limit: number }>>>>
  >(
    (acc, [period, data]) => {
      if (period === 'days') {
        return {
          ...acc,
          [period as Period]: data.map((value) => ({
            value: `${value}d`,
            label: value === 7 ? 'common:trial.weekTrial' : 'common:form.day',
          })),
        };
      }

      return {
        ...acc,
        [period as Period]: data.map((value) => ({
          value: `${value}m`,
          label: 'common:form.month',
          parameters: { availability: availability?.[period as Period]?.[value.toString()] },
        })),
      };
    },
    { days: [], months: [] },
  );
}

export function toOrderSetupModel({
  countries = [],
  isps = {},
  packages = [],
  periods = {},
  metadata,
}: OrderSetupWithMetadataDTO): OrderSetupModel {
  const { availability, visibility } = metadata;

  return {
    autoExtend: {
      isVisible: visibility.autoExtend,
    },
    autoExtendTraffic: {
      isVisible: visibility.autoExtendTraffic,
      availability: availability?.autoExtend?.traffic ?? null,
    },
    countries: {
      isVisible: visibility.countries,
      options: toCountryOptionsModel(countries, availability.countries),
    },
    isps: {
      isVisible: visibility.isps,
      options: toISPOptionsModel(isps, availability.isps),
    },
    packages: {
      isVisible: visibility.packages,
      options: toPackageOptionsModel(packages),
    },
    periods: {
      isVisible: visibility.periods,
      options: toPeriodOptionsModel(periods, availability.periods),
    },
    plans: {
      isVisible: visibility.plans,
    },
    quantity: {
      isVisible: visibility.quantity,
      availability: availability?.quantity ?? null,
    },
    traffic: {
      isVisible: visibility.traffic,
    },
  };
}

export function toOrderSetupPriceModel({ currency, ...from }: OrderSetupPriceDTO): OrderSetupPriceModel {
  return { ...from, currency: toCurrency(currency) };
}

export function toOrderSetupExecuteModel(from: OrderSetupExecuteDTO): OrderSetupExecuteModel {
  return from;
}

// #endregion Mappers for DTOs

// #region Mappers for Payloads
export function buildServiceDetailsPayload({ service, ...payload }: ServiceDetailsPayload) {
  if (!service.plans.length) return null;

  if ('planId' in payload) {
    return { planId: payload.planId };
  }

  return { country: payload.country };
}

export function toOrderSetupPayload(from: OrderSetupFormModel): OrderSetupPayload {
  const {
    serviceId,
    bandwidth,
    autoExtendBandwidth,
    isAutoExtendEnabled,
    isp,
    package: proxyPackage,
    period,
    planId,
    country,
    quantity,
    couponCode,
  } = from;

  if (serviceId === 'vpn') throw new Error('VPN is not supported');

  if (serviceId === 'rotating-mobile') {
    return {
      serviceId: 'rotating-mobile',
      traffic: bandwidth,
      couponCode,
      planId: null,
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  if (serviceId === 'rotating-residential') {
    return {
      serviceId: 'rotating-residential',
      traffic: bandwidth,
      couponCode,
      planId: null,
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  if (serviceId === 'static-datacenter-ipv4') {
    return {
      serviceId: 'static-datacenter-ipv4',
      couponCode,
      planId,
      quantity,
      country,
      period: formatTimePeriod(period),
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  if (serviceId === 'static-datacenter-ipv6') {
    return {
      serviceId: 'static-datacenter-ipv6',
      couponCode,
      planId,
      country,
      packageId: proxyPackage ?? '50',
      period: formatTimePeriod(period),
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  return {
    serviceId: 'static-residential-ipv4',
    couponCode,
    planId,
    quantity,
    country,
    ispId: isp,
    period: formatTimePeriod(period),
    autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
  };
}

export function toOrderExecutePayload(
  from: OrderSetupFormModel,
  method?: PaymentOption,
  metadata?: Nullable<string>,
): OrderExecutePayload {
  return {
    ...toOrderSetupPayload(from),
    payment: { method, metadata: metadata ?? undefined },
  };
}
// #endregion Mappers for Payload
