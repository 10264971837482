import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useGetProxyQuery, useUpsertProxyNoteMutation } from 'store/api';
import type { Nullable } from 'types';
import { ToastManager } from 'utils/toast';

import { ProxyNoteForm, type ProxyNoteValues } from './ProxyNoteForm';

type HandleSubmit = FormikConfig<ProxyNoteValues>['onSubmit'];

export type ProxyNoteModalProps = {
  proxyId: number;
  initialNote: Nullable<string>;
};

export function ProxyNoteModal({ initialNote = null, proxyId }: ProxyNoteModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const { data: proxy } = useGetProxyQuery(proxyId);
  const [upsertNote] = useUpsertProxyNoteMutation();

  const initialValues = useMemo<ProxyNoteValues>(() => {
    return { note: proxy?.note ?? '' };
  }, [proxy?.note]);

  const validationSchema = useMemo<Yup.Schema<ProxyNoteValues>>(() => {
    return Yup.object().shape({
      note: initialNote ? Yup.string().default('').max(120) : Yup.string().required().max(120),
    });
  }, [initialNote]);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ note }, { setSubmitting }) => {
      try {
        await upsertNote({ proxyId, note: note.length > 0 ? note : null });

        posthog?.capture(
          note.length > 0 ? posthogEvents.proxy.note.added.success : posthogEvents.proxy.note.deleted.success,
          { proxyId },
        );

        ToastManager.success(t(`proxies.modals.note.success.${initialNote ? 'edit' : 'add'}`));

        hideModal();
      } catch {
        ToastManager.error(t(`proxies.modals.note.failure.${initialNote ? 'edit' : 'add'}`));

        posthog?.capture(
          note.length > 0 ? posthogEvents.proxy.note.added.failed : posthogEvents.proxy.note.deleted.failed,
          { proxyId },
        );
      } finally {
        setSubmitting(false);
      }
    },
    [hideModal, initialNote, posthog, proxyId, t, upsertNote],
  );

  return (
    <Formik<ProxyNoteValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <ProxyNoteForm />
    </Formik>
  );
}
