import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

export function Loader() {
  const { t } = useTranslation();

  return (
    <Stack spacing={4} alignItems="center">
      <CircularProgress size={64} />

      <Typography variant="body-1" color="neutral.500">
        {t('account.modals.status.topUp.pending.subtitle')}
      </Typography>
    </Stack>
  );
}
