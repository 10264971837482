import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';

export function TopUpFormLoading() {
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="title" color="neutral.500">
            {t('common:form.currencyAmount', { currency: 'USD' })}
          </Typography>

          <Box boxShadow="var(--mui-shadows-2)" borderRadius={1} px={2} py={1.5}>
            <Skeleton width="100%" height={20} />
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="title" color="neutral.500">
          {t('common:form.paymentMethod')}
        </Typography>

        <Stack
          spacing={2}
          direction="row"
          flex="1 1 auto"
          alignItems="center"
          boxShadow="var(--mui-shadows-2)"
          borderRadius={1.5}
          px={2}
          py={1.5}
        >
          <Skeleton width={32} height={32} />

          <Stack spacing={0.5} flex="1 1 auto">
            <Skeleton width="100%" height={20} />

            <Skeleton width="100%" height={12} />
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2} divider={<Divider />}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body-2" color="text.secondary">
            {t('common:form.subtotal')}
          </Typography>

          <Skeleton width={100} height={20} />
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="title" color="text.secondary">
            {t('common:form.total')}
          </Typography>

          <Skeleton width={100} height={32} />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button color="secondary" fullWidth disabled>
          {t('common:buttons.cancel')}
        </Button>

        <Button fullWidth color="primary" disabled>
          {t('common:buttons.continue')}
        </Button>
      </Stack>
    </Stack>
  );
}
