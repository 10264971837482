import { type MouseEvent, useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'components/modals/context';
import { useShowModal } from 'modules/common/GlobalModals';
import type { CreditCardModel } from 'store/accounts/models';
import { useDeleteCreditCardMutation, useUpdateAutoTopUpPreferencesMutation } from 'store/api';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { type AutoTopUpValues, ManageCreditCardForm } from './ManageCreditCardForm';

type HandleSubmit = FormikConfig<AutoTopUpValues>['onSubmit'];

export type ManageCreditCardModalProps = {
  initiallyEnabled: boolean;
  initialAmount: number;
  initialThreshold: number;
  card: CreditCardModel;
};

export function ManageCreditCardModal({
  initiallyEnabled,
  initialAmount,
  initialThreshold,
  card,
}: ManageCreditCardModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const showModal = useShowModal();

  const [removeCreditCard] = useDeleteCreditCardMutation();
  const [updateAutoTopUpPreferences] = useUpdateAutoTopUpPreferencesMutation();

  const initialValues = useMemo<AutoTopUpValues>(() => {
    return {
      isEnabled: initiallyEnabled,
      amount: initialAmount,
      threshold: initialThreshold,
    };
  }, [initialAmount, initialThreshold, initiallyEnabled]);

  const validationSchema = useMemo<Yup.Schema<AutoTopUpValues>>(() => {
    return Yup.object().shape({
      isEnabled: Yup.boolean(),
      amount: Yup.number().when('isEnabled', (isEnabled, field) =>
        isEnabled.every(Boolean) ? field.required().min(5) : field,
      ),
      threshold: Yup.number().when('isEnabled', (isEnabled, field) =>
        isEnabled.every(Boolean) ? field.required().min(5) : field,
      ),
    });
  }, []);

  const onConfirm = useCallback(async () => {
    await removeCreditCard().unwrap();

    ToastManager.success(t('account.modals.cardRemoval.success'));

    hideModal();
  }, [hideModal, removeCreditCard, t]);

  const handleCardRemove = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      return showModal('credit.card.remove', {
        title: t('account.modals.cardRemoval.title'),
        subtitle: t('account.modals.cardRemoval.subtitle'),
        onConfirm,
        confirmLabel: t('common:buttons.remove'),
        confirmDanger: true,
        onCancel: () => showModal('credit.card.manage', { initiallyEnabled, initialAmount, initialThreshold, card }),
        withLoader: true,
      });
    },
    [card, initialAmount, initialThreshold, initiallyEnabled, onConfirm, showModal, t],
  );

  const onSubmit = useCallback<HandleSubmit>(
    async ({ amount, isEnabled, threshold }, { setErrors }) => {
      try {
        await updateAutoTopUpPreferences({
          isEnabled: !!isEnabled,
          ...(!!isEnabled && { amount, threshold }),
        }).unwrap();

        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }
      }
    },
    [hideModal, updateAutoTopUpPreferences],
  );

  return (
    <Formik<AutoTopUpValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnChange
    >
      <ManageCreditCardForm card={card} onCardRemove={handleCardRemove} />
    </Formik>
  );
}
