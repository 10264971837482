import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';

import { COUNTRIES } from 'constants/countries';
import { US_STATES } from 'constants/usStates';
import { toCurrency } from 'store/accounts/helpers';
import { toMaintenanceWindowModel } from 'store/common/helpers';
import type { SupportedCountry } from 'store/orders/v1/dtos';
import { toSupportedISPsModel } from 'store/orders/v1/helpers';
import type { Nullable, Paginate } from 'types';
import { isPaginatedDTO } from 'utils/guards';

import {
  type BulkAutoExtendSettingsDTO,
  type ChangeAuthenticationTypeDTO,
  type ChangeProtocolDTO,
  type ChangeThreadsDTO,
  type ChangeUplinkSpeedDTO,
  type DiagnosticRoutineDTO,
  type HostnameDTO,
  type IPWhitelistDTO,
  type ProxyActionDTO,
  type ProxyAdminDetailsDTO,
  type ProxyAdminPricesDTO,
  type ProxyAdminServerDTO,
  type ProxyAdminSubnetDTO,
  type ProxyAdminVendorDTO,
  type ProxyAuthenticationDTO,
  type ProxyBandwidthDTO,
  type ProxyChangelogDTO,
  type ProxyConnectionDTO,
  type ProxyDTO,
  type ProxyEventDTO,
  type ProxyExtensionPriceDTO,
  type ProxyISPDTO,
  type ProxyLocationDTO,
  type ProxyOverviewDTO,
  type ProxyReplacementCheckDTO,
  type ProxyReplacementDetailsDTO,
  type ProxyReplacementOptionsDTO,
  type ProxyRouteDTO,
  ProxyStatusDTO,
  type ProxySubnetDTO,
  type ProxySummaryDTO,
  type UpgradeThreadsPriceDTO,
  type UpgradeUplinkSpeedPriceDTO,
} from './dtos';
import {
  type BulkAutoExtendSettingsModel,
  type ChangeAuthenticationTypeModel,
  type ChangeProtocolModel,
  type ChangeThreadsModel,
  type ChangeUplinkSpeedModel,
  type DiagnosticRoutineModel,
  type HostnameModel,
  type IPWhitelistModel,
  ProxyActionModel,
  type ProxyAdminDetailsModel,
  type ProxyAdminPricesModel,
  type ProxyAdminServerModel,
  type ProxyAdminSubnetModel,
  type ProxyAdminVendorModel,
  type ProxyAuthenticationModel,
  type ProxyBandwidthModel,
  type ProxyChangelogModel,
  type ProxyConnectionModel,
  type ProxyEventModel,
  type ProxyExtensionPriceModel,
  type ProxyISPModel,
  type ProxyLocationModel,
  type ProxyModel,
  type ProxyOverviewModel,
  type ProxyReplacementDetailsModel,
  type ProxyReplacementOptionsModel,
  type ProxyRouteModel,
  ProxyStatus,
  type ProxySubnetModel,
  type ProxySummaryModel,
  type UpgradeThreadsPriceModel,
  type UpgradeUplinkSpeedPriceModel,
} from './models';
import { type CountryOption, NetworkType, ServiceType } from './types';

// #region Common
export function toServiceType(from: NetworkType): ServiceType;
export function toServiceType(from: NetworkType[]): ServiceType[];

export function toServiceType(from: NetworkType | NetworkType[]): ServiceType | ServiceType[] {
  if (Array.isArray(from)) {
    return from.map((item) => toServiceType(item));
  }

  if (from === NetworkType.Datacenter) return ServiceType.PROXY_DATACENTER;

  if (from === NetworkType.Mobile) return ServiceType.PROXY_MOBILE;

  if (from === NetworkType.Residential) return ServiceType.PROXY_RESIDENTIAL;

  if (from === NetworkType.ResidentialStatic) return ServiceType.PROXY_RESIDENTIAL_STATIC;

  return ServiceType.VPN;
}

export function toNetworkType(from: ServiceType): NetworkType;
export function toNetworkType(from: ServiceType[]): NetworkType[];

export function toNetworkType(from: ServiceType | ServiceType[]): NetworkType | NetworkType[] {
  if (Array.isArray(from)) {
    return from.map((item) => toNetworkType(item));
  }

  if (from === ServiceType.PROXY_DATACENTER) return NetworkType.Datacenter;

  if (from === ServiceType.PROXY_MOBILE) return NetworkType.Mobile;

  if (from === ServiceType.PROXY_RESIDENTIAL) return NetworkType.Residential;

  if (from === ServiceType.PROXY_RESIDENTIAL_STATIC) return NetworkType.ResidentialStatic;

  return NetworkType.VPN;
}

export function toProxyActionModel(from: ProxyActionDTO | ProxyActionModel): ProxyActionModel {
  if (from === ProxyActionModel.SHOW_DETAILS) return ProxyActionModel.SHOW_DETAILS;

  return ProxyActionModel[from];
}

export function toProxyAdminServerModel(from: ProxyAdminServerDTO): ProxyAdminServerModel;
export function toProxyAdminServerModel(from: ProxyAdminServerDTO[]): ProxyAdminServerModel[];

export function toProxyAdminServerModel(
  from: ProxyAdminServerDTO | ProxyAdminServerDTO[],
): ProxyAdminServerModel | ProxyAdminServerModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAdminServerModel(item));
  }

  return from;
}

export function toProxyAdminSubnetModel(from: ProxyAdminSubnetDTO): ProxyAdminSubnetModel;
export function toProxyAdminSubnetModel(from: ProxyAdminSubnetDTO[]): ProxyAdminSubnetModel[];

export function toProxyAdminSubnetModel(
  from: ProxyAdminSubnetDTO | ProxyAdminSubnetDTO[],
): ProxyAdminSubnetModel | ProxyAdminSubnetModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAdminSubnetModel(item));
  }

  return from;
}

export function toProxyAdminVendorModel(from: ProxyAdminVendorDTO): ProxyAdminVendorModel;
export function toProxyAdminVendorModel(from: ProxyAdminVendorDTO[]): ProxyAdminVendorModel[];

export function toProxyAdminVendorModel(
  from: ProxyAdminVendorDTO | ProxyAdminVendorDTO[],
): ProxyAdminVendorModel | ProxyAdminVendorModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAdminVendorModel(item));
  }

  return from;
}

export function toProxyAdminPricesModel(from: ProxyAdminPricesDTO): ProxyAdminPricesModel;
export function toProxyAdminPricesModel(from: ProxyAdminPricesDTO[]): ProxyAdminPricesModel[];

export function toProxyAdminPricesModel(
  from: ProxyAdminPricesDTO | ProxyAdminPricesDTO[],
): ProxyAdminPricesModel | ProxyAdminPricesModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAdminPricesModel(item));
  }

  return from;
}

export function toProxyAuthenticationModel(from: ProxyAuthenticationDTO): ProxyAuthenticationModel;
export function toProxyAuthenticationModel(from: ProxyAuthenticationDTO[]): ProxyAuthenticationModel[];

export function toProxyAuthenticationModel(
  from: ProxyAuthenticationDTO | ProxyAuthenticationDTO[],
): ProxyAuthenticationModel | ProxyAuthenticationModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAuthenticationModel(item));
  }

  return from;
}

export function toProxyBandwidthModel(from: ProxyBandwidthDTO): ProxyBandwidthModel;
export function toProxyBandwidthModel(from: ProxyBandwidthDTO[]): ProxyBandwidthModel[];

export function toProxyBandwidthModel(
  from: ProxyBandwidthDTO | ProxyBandwidthDTO[],
): ProxyBandwidthModel | ProxyBandwidthModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyBandwidthModel(item));
  }

  return from;
}

export function toProxyConnectionModel(from: ProxyConnectionDTO): ProxyConnectionModel;
export function toProxyConnectionModel(from: ProxyConnectionDTO[]): ProxyConnectionModel[];

export function toProxyConnectionModel(
  from: ProxyConnectionDTO | ProxyConnectionDTO[],
): ProxyConnectionModel | ProxyConnectionModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyConnectionModel(item));
  }

  const { hostnames, ...rest } = from;

  return { ...rest, hostnames: Array.isArray(hostnames) ? null : hostnames };
}

export function toProxyLocationModel(from: ProxyLocationDTO): ProxyLocationModel;
export function toProxyLocationModel(from: ProxyLocationDTO[]): ProxyLocationModel[];

export function toProxyLocationModel(
  from: ProxyLocationDTO | ProxyLocationDTO[],
): ProxyLocationModel | ProxyLocationModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyLocationModel(item));
  }

  const { countryCode, regionCode } = from;

  const country = COUNTRIES.find((c) => c.value === countryCode) ?? null;
  const region = US_STATES.find((r) => r.value === regionCode) ?? null;

  return { country, region };
}

export function toProxyRouteModel(from: ProxyRouteDTO): ProxyRouteModel;
export function toProxyRouteModel(from: ProxyRouteDTO[]): ProxyRouteModel[];

export function toProxyRouteModel(from: ProxyRouteDTO | ProxyRouteDTO[]): ProxyRouteModel | ProxyRouteModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyRouteModel(item));
  }

  const { proxyService, server, ...rest } = from;

  return { ...rest, proxyId: proxyService, serverId: server };
}

export function toProxyStatus(from: ProxyStatusDTO, expiresAt: Nullable<string>): ProxyStatus {
  const threeDaysBefore = dayjs(expiresAt).subtract(3, 'day');

  if (from === ProxyStatusDTO.ACTIVE && dayjs().isBetween(threeDaysBefore, dayjs(expiresAt))) {
    return ProxyStatus.EXPIRING_SOON;
  }

  return ProxyStatus[ProxyStatusDTO[from]];
}
// #endregion Common

export function toBulkAutoExtendSettingsModel(from: BulkAutoExtendSettingsDTO): BulkAutoExtendSettingsModel;
export function toBulkAutoExtendSettingsModel(from: BulkAutoExtendSettingsDTO[]): BulkAutoExtendSettingsModel[];

export function toBulkAutoExtendSettingsModel(
  from: BulkAutoExtendSettingsDTO | BulkAutoExtendSettingsDTO[],
): BulkAutoExtendSettingsModel | BulkAutoExtendSettingsModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toBulkAutoExtendSettingsModel(item));
  }

  return from;
}

export function toProxyExtensionPriceModel(from: ProxyExtensionPriceDTO): ProxyExtensionPriceModel;
export function toProxyExtensionPriceModel(from: ProxyExtensionPriceDTO[]): ProxyExtensionPriceModel[];

export function toProxyExtensionPriceModel(
  from: ProxyExtensionPriceDTO | ProxyExtensionPriceDTO[],
): ProxyExtensionPriceModel | ProxyExtensionPriceModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyExtensionPriceModel(item));
  }

  const { currency, ...rest } = from;

  return { ...rest, currency: currency ? toCurrency(currency) : null };
}

export function toChangeThreadsModel(from: ChangeThreadsDTO): ChangeThreadsModel;
export function toChangeThreadsModel(from: ChangeThreadsDTO[]): ChangeThreadsModel[];

export function toChangeThreadsModel(
  from: ChangeThreadsDTO | ChangeThreadsDTO[],
): ChangeThreadsModel | ChangeThreadsModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toChangeThreadsModel(item));
  }

  const { availableThreads, ...rest } = from;

  return { ...rest, options: availableThreads.map(({ id }) => id) };
}

export function toChangeUplinkSpeedModel(from: ChangeUplinkSpeedDTO): ChangeUplinkSpeedModel;
export function toChangeUplinkSpeedModel(from: ChangeUplinkSpeedDTO[]): ChangeUplinkSpeedModel[];

export function toChangeUplinkSpeedModel(
  from: ChangeUplinkSpeedDTO | ChangeUplinkSpeedDTO[],
): ChangeUplinkSpeedModel | ChangeUplinkSpeedModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toChangeUplinkSpeedModel(item));
  }

  const { availableUplinkSpeeds, ...rest } = from;

  return { ...rest, options: availableUplinkSpeeds.map(({ id }) => id) };
}

export function toIPWhitelistModel(from: IPWhitelistDTO): IPWhitelistModel;
export function toIPWhitelistModel(from: IPWhitelistDTO[]): IPWhitelistModel[];

export function toIPWhitelistModel(from: IPWhitelistDTO | IPWhitelistDTO[]): IPWhitelistModel | IPWhitelistModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toIPWhitelistModel(item));
  }

  return from;
}

export function toHostnameModel(from: HostnameDTO): HostnameModel;
export function toHostnameModel(from: HostnameDTO[]): HostnameModel[];

export function toHostnameModel(from: HostnameDTO | HostnameDTO[]): HostnameModel | HostnameModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toHostnameModel(item));
  }

  return from;
}

export function toProxyModel(from: ProxyDTO): ProxyModel;
export function toProxyModel(from: ProxyDTO[]): ProxyModel[];
export function toProxyModel(from: Paginate<ProxyDTO>): Paginate<ProxyModel>;

export function toProxyModel(
  from: ProxyDTO | ProxyDTO[] | Paginate<ProxyDTO>,
): ProxyModel | ProxyModel[] | Paginate<ProxyModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: toProxyModel(data) };
  }

  const {
    status,
    authentication,
    connection,
    metadata,
    bandwidth,
    uplinkSpeed,
    threads,
    location,
    actions,
    maintenanceWindows,
    ...rest
  } = from;

  return {
    ...rest,
    status: toProxyStatus(status, from.expiresAt),
    authentication: toProxyAuthenticationModel(authentication),
    connection: toProxyConnectionModel(connection),
    ispName: metadata.ispName,
    bandwidth: toProxyBandwidthModel(bandwidth),
    uplinkSpeed: uplinkSpeed?.value != null ? uplinkSpeed.value : null,
    threads: threads?.value != null ? threads.value : null,
    location: toProxyLocationModel(location),
    actions: [ProxyActionModel.SHOW_DETAILS, ...actions].map(toProxyActionModel),
    maintenanceWindows: toMaintenanceWindowModel(maintenanceWindows),
  };
}

export function toProxyOverviewModel(from: ProxyOverviewDTO): ProxyOverviewModel;
export function toProxyOverviewModel(from: ProxyOverviewDTO[]): ProxyOverviewModel[];
export function toProxyOverviewModel(from: Paginate<ProxyOverviewDTO>): Paginate<ProxyOverviewModel>;

export function toProxyOverviewModel(
  from: ProxyOverviewDTO | ProxyOverviewDTO[] | Paginate<ProxyOverviewDTO>,
): ProxyOverviewModel | ProxyOverviewModel[] | Paginate<ProxyOverviewModel> {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyOverviewModel(item));
  }

  if (isPaginatedDTO(from)) {
    const { data, ...rest } = from;

    return { ...rest, data: toProxyOverviewModel(data) };
  }

  const { updatedAt, activatedAt, autoExtendEnabled, autoExtendBandwidthAmount, routes, ...rest } = from;

  return {
    ...toProxyModel(rest),
    updatedAt,
    activatedAt,
    isAutoExtendEnabled: autoExtendEnabled,
    autoExtendBandwidthAmount,
    routes: toProxyRouteModel(routes),
  };
}

export function toProxySummaryModel(from: ProxySummaryDTO): ProxySummaryModel;
export function toProxySummaryModel(from: ProxySummaryDTO[]): ProxySummaryModel[];

export function toProxySummaryModel(
  from: ProxySummaryDTO | ProxySummaryDTO[],
): ProxySummaryModel | ProxySummaryModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxySummaryModel(item));
  }

  const { activeProxyCount, expiringProxyCount, inactiveProxyCount } = from;

  return { active: activeProxyCount, expiring: expiringProxyCount, inactive: inactiveProxyCount };
}

export function toProxyISPModel(from: ProxyISPDTO): ProxyISPModel;
export function toProxyISPModel(from: ProxyISPDTO[]): ProxyISPModel[];

export function toProxyISPModel(from: ProxyISPDTO | ProxyISPDTO[]): ProxyISPModel | ProxyISPModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyISPModel(item));
  }

  return from;
}

export function toChangeAuthenticationTypeModel(from: ChangeAuthenticationTypeDTO): ChangeAuthenticationTypeModel;
export function toChangeAuthenticationTypeModel(from: ChangeAuthenticationTypeDTO[]): ChangeAuthenticationTypeModel[];

export function toChangeAuthenticationTypeModel(
  from: ChangeAuthenticationTypeDTO | ChangeAuthenticationTypeDTO[],
): ChangeAuthenticationTypeModel | ChangeAuthenticationTypeModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toChangeAuthenticationTypeModel(item));
  }

  return from;
}

export function toProxyChangelogModel(from: ProxyChangelogDTO): ProxyChangelogModel;
export function toProxyChangelogModel(from: ProxyChangelogDTO[]): ProxyChangelogModel[];

export function toProxyChangelogModel(
  from: ProxyChangelogDTO | ProxyChangelogDTO[],
): ProxyChangelogModel | ProxyChangelogModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyChangelogModel(item));
  }

  return { ...from, id: uuid() };
}

export function toProxyEventModel(from: ProxyEventDTO): ProxyEventModel;
export function toProxyEventModel(from: ProxyEventDTO[]): ProxyEventModel[];

export function toProxyEventModel(from: ProxyEventDTO | ProxyEventDTO[]): ProxyEventModel | ProxyEventModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyEventModel(item));
  }

  return { ...from, id: uuid() };
}

export function toProxyAdminDetailsModel(from: ProxyAdminDetailsDTO): ProxyAdminDetailsModel;
export function toProxyAdminDetailsModel(from: ProxyAdminDetailsDTO[]): ProxyAdminDetailsModel[];

export function toProxyAdminDetailsModel(
  from: ProxyAdminDetailsDTO | ProxyAdminDetailsDTO[],
): ProxyAdminDetailsModel | ProxyAdminDetailsModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyAdminDetailsModel(item));
  }

  const { server, subnet, vendor, prices, routes, ...rest } = from;

  return {
    ...rest,
    server: server ? toProxyAdminServerModel(server) : null,
    subnet: subnet ? toProxyAdminSubnetModel(subnet) : null,
    vendor: vendor ? toProxyAdminVendorModel(vendor) : [],
    prices: prices ? toProxyAdminPricesModel(prices) : null,
    routes: toProxyRouteModel(routes),
  };
}

export function toProxySubnetModel(from: ProxySubnetDTO): ProxySubnetModel;
export function toProxySubnetModel(from: ProxySubnetDTO[]): ProxySubnetModel[];

export function toProxySubnetModel(from: ProxySubnetDTO | ProxySubnetDTO[]): ProxySubnetModel | ProxySubnetModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxySubnetModel(item));
  }

  const { cidr, countryCode, id, serverName } = from;

  return { countryCode, id, label: `${cidr} (${serverName})` };
}

export function toChangeProtocolModel(from: ChangeProtocolDTO): ChangeProtocolModel;
export function toChangeProtocolModel(from: ChangeProtocolDTO[]): ChangeProtocolModel[];

export function toChangeProtocolModel(
  from: ChangeProtocolDTO | ChangeProtocolDTO[],
): ChangeProtocolModel | ChangeProtocolModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toChangeProtocolModel(item));
  }

  return from;
}

export function toUpgradeThreadsPriceModel(from: UpgradeThreadsPriceDTO): UpgradeThreadsPriceModel[] {
  const { availableThreads } = from;

  return availableThreads.map(({ id, totalPrice }) => ({ price: Number(totalPrice), thread: id }));
}

export function toUpgradeUplinkSpeedPriceModel(from: UpgradeUplinkSpeedPriceDTO): UpgradeUplinkSpeedPriceModel[] {
  const { availableUplinkSpeeds } = from;

  return availableUplinkSpeeds.map(({ id, totalPrice }) => ({ price: Number(totalPrice), mbit: id }));
}

export function toDiagnosticRoutineModel(from: DiagnosticRoutineDTO): DiagnosticRoutineModel;
export function toDiagnosticRoutineModel(from: DiagnosticRoutineDTO[]): DiagnosticRoutineModel[];

export function toDiagnosticRoutineModel(
  from: DiagnosticRoutineDTO | DiagnosticRoutineDTO[],
): DiagnosticRoutineModel | DiagnosticRoutineModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toDiagnosticRoutineModel(item));
  }

  return from;
}

export function toProxyReplacementCheckModel(from: ProxyReplacementCheckDTO): boolean {
  return !!from.isReplacementAvailable;
}

export function toProxyReplacementDetailsModel(from: ProxyReplacementDetailsDTO): ProxyReplacementDetailsModel;
export function toProxyReplacementDetailsModel(from: ProxyReplacementDetailsDTO[]): ProxyReplacementDetailsModel[];

export function toProxyReplacementDetailsModel(
  from: ProxyReplacementDetailsDTO | ProxyReplacementDetailsDTO[],
): ProxyReplacementDetailsModel | ProxyReplacementDetailsModel[] {
  if (Array.isArray(from)) {
    return from.map((item) => toProxyReplacementDetailsModel(item));
  }

  const { status, ...rest } = from;

  return { ...rest, status: toProxyStatus(status, from.expiresAt) };
}

function toSupportedCountriesModel(from: SupportedCountry[], isUnused?: boolean): CountryOption[] {
  return from
    .map(({ amount, code }) => {
      const country = COUNTRIES.find((c) => c.value === code);

      if (!country) return null;

      return { ...country, subLabel: amount ?? 'common:outOfStock', plainAmount: amount, isUnused };
    })
    .filter((val) => !isNil(val)) as CountryOption[];
}

export function toProxyReplacementOptionsModel(
  from: ProxyReplacementOptionsDTO & { networkType: NetworkType },
): ProxyReplacementOptionsModel {
  return {
    [from.networkType]: {
      [from.isUnused ? 'premium' : 'standard']: {
        country: toSupportedCountriesModel(from.supportedCountries, from.isUnused),
        isps: toSupportedISPsModel(from.supportedISPs),
      },
    },
  };
}
