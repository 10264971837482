import { useCallback, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import * as Yup from 'yup';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useMounted } from 'hooks/useMounted';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetOrderPriceMutation } from 'store/api';
import { toOrderConfigurationPayload } from 'store/orders/v1/helpers';
import type { ProxySetupFormModel } from 'store/orders/v1/models';
import { setCouponCode } from 'store/orders/v1/reducers';
import { getValidationErrors } from 'utils/error';

import { CouponCodeForm, type CouponCodeValues } from './CouponCodeForm';

type HandleSubmit = FormikConfig<CouponCodeValues>['onSubmit'];

export type CouponCodeModalProps = {
  currentSettings?: ProxySetupFormModel;
};

export function CouponCodeModal({ currentSettings }: CouponCodeModalProps) {
  const isNewCustomer = useIsNewCustomer();
  const hideModal = useHideModal();
  const { proxySettings, selectedNetworkType } = useAppSelector((state) => state.order);
  const isMounted = useMounted();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const [getOrderPrice] = useGetOrderPriceMutation();

  const values = useMemo(() => currentSettings ?? proxySettings, [currentSettings, proxySettings]);

  const initialValues = useMemo<CouponCodeValues>(() => {
    return { couponCode: '' };
  }, []);

  const validationSchema = useMemo<Yup.Schema<CouponCodeValues>>(() => {
    return Yup.object().shape({
      couponCode: Yup.string().required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ couponCode }, { setSubmitting, setErrors }) => {
      try {
        const mappedValues = toOrderConfigurationPayload({
          ...{ ...values, networkType: selectedNetworkType },
          couponCode,
        });

        if (!mappedValues) return;

        await getOrderPrice(mappedValues).unwrap();

        if (!isMounted()) {
          return;
        }

        posthog?.capture(posthogEvents.order.codeApplied, { isNewCustomer });

        dispatch(setCouponCode(couponCode));

        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        posthog?.capture(posthogEvents.order.invalidCode, { isNewCustomer });

        setSubmitting(false);
      }
    },
    [dispatch, getOrderPrice, hideModal, isMounted, isNewCustomer, posthog, selectedNetworkType, values],
  );

  return (
    <Formik<CouponCodeValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <CouponCodeForm />
    </Formik>
  );
}
