import { useEffect, useMemo, useState } from 'react';

export function useListenZendeskChatUnreadCount() {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.zE?.('messenger:on', 'unreadMessages', (count: number) => {
        setHasUnreadMessages(!!count);
      });
    });
  }, []);

  return useMemo(() => ({ hasUnreadMessages }), [hasUnreadMessages]);
}
