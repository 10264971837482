import { useCallback } from 'react';

import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { useBalance } from 'hooks/useBalance';
import { useRouter } from 'hooks/useRouter';
import { useSession } from 'hooks/useSession';
import { useEmailVerification } from 'modules/accounts/hooks';
import { useShowModal } from 'modules/common/GlobalModals';
import { paths } from 'paths';
import { useSkipProfileDetailsMutation } from 'store/api';
import { CurrencyFormatter } from 'utils/currency';

type BalanceSummaryProps = {
  price?: number;
  isLoading?: boolean;
};

export function BalanceSummary({ price = 0, isLoading }: BalanceSummaryProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const showModal = useShowModal();
  const router = useRouter();
  const { balance, isBalanceLoading } = useBalance();
  const { isProfileDetailsValid } = useSession();
  const { shouldForceEmailVerification } = useEmailVerification();

  const [skipProfileDetails] = useSkipProfileDetailsMutation();

  const onClick = useCallback(() => {
    if (shouldForceEmailVerification()) return;

    if (!isProfileDetailsValid) {
      return showModal('account.billing.topUp.confirmation', {
        onConfirm: () => {
          router.push(paths.account.general);

          hideModal();
        },
        title: t('account.modals.beforeTopUp.title'),
        subtitle: t('account.modals.beforeTopUp.subtitle'),
        confirmLabel: t('account.modals.beforeTopUp.confirm'),
        cancelLabel: t('common:buttons.skip'),

        onCancel: async () => {
          try {
            await skipProfileDetails().unwrap();
          } catch {
            // noop
          }

          return showModal('account.billing.topUp');
        },
      });
    }

    return showModal('account.billing.topUp');
  }, [hideModal, isProfileDetailsValid, router, shouldForceEmailVerification, showModal, skipProfileDetails, t]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
          {t('common:paymentMethods.balance')}
        </Typography>

        <Link variant="body-2" color="primary.main" onClick={onClick}>
          {t('common:buttons.topUp')}
        </Link>
      </Stack>

      {isLoading || isBalanceLoading ? (
        <Skeleton height={20} width={100} />
      ) : (
        <Typography
          variant="body-2"
          color={price > balance ? 'var(--mui-palette-error-main)' : 'var(--mui-palette-text-primary)'}
        >
          {CurrencyFormatter.format(balance)}
        </Typography>
      )}
    </Stack>
  );
}
