import { useCallback, useEffect, useMemo } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useHideModal } from 'components/modals/context';
import { posthogEvents } from 'constants/posthogEvents';
import {
  useBulkChangeProtocolMutation,
  useGetChangeProtocolOptionsQuery,
  useUpdateProxyProtocolMutation,
} from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { Protocol } from 'store/proxies/types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { ChangeProxyProtocolForm, type ChangeProxyProtocolValues } from './ChangeProxyProtocolForm';

type HandleSubmit = FormikConfig<ChangeProxyProtocolValues>['onSubmit'];

export type ChangeProxyProtocolModalProps = {
  proxies: ProxyModel[];
};

export function ChangeProxyProtocolModal({ proxies = [] }: ChangeProxyProtocolModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [updateProtocolSingle] = useUpdateProxyProtocolMutation();
  const [updateProtocolBulk] = useBulkChangeProtocolMutation();
  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);
  const { data, refetch, isUninitialized } = useGetChangeProtocolOptionsQuery(proxyIds[0]);

  const hideModal = useHideModal();

  const initialValues = useMemo<ChangeProxyProtocolValues>(() => {
    if (!data || proxies.length > 1) return { protocol: null };

    return { protocol: data.currentType };
  }, [data, proxies]);

  const validationSchema = useMemo<Yup.Schema<ChangeProxyProtocolValues>>(() => {
    return Yup.object().shape({
      protocol: Yup.string().oneOf(Object.values(Protocol)).required(),
    });
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ protocol }, { setErrors, setSubmitting }) => {
      if (!protocol) return;

      try {
        if (proxyIds.length > 1) {
          await updateProtocolBulk({ ids: proxyIds, protocol }).unwrap();

          posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.success, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          await updateProtocolSingle({ newType: protocol, proxyId: proxyIds[0] }).unwrap();

          posthog?.capture(posthogEvents.proxy.changeProtocol.success, { proxyId: proxyIds[0], protocol });
        }

        ToastManager.success(t('proxies.modals.changeProtocol.success'));
        hideModal();
      } catch (error) {
        const errors = getValidationErrors(error);

        if (errors && Object.keys(errors).length > 0) {
          setErrors(errors);
        }

        if (proxyIds.length > 1) {
          posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.failed, {
            proxyIds,
            total: proxyIds.length,
          });
        } else {
          posthog?.capture(posthogEvents.proxy.changeProtocol.failed, { proxyId: proxyIds[0], protocol });
        }

        setSubmitting(false);
      }
    },
    [hideModal, posthog, proxyIds, t, updateProtocolBulk, updateProtocolSingle],
  );

  useEffect(() => {
    if (isUninitialized) return;

    refetch();
  }, [isUninitialized, refetch]);

  return (
    <Formik<ChangeProxyProtocolValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
    >
      <ChangeProxyProtocolForm proxyIds={proxyIds} availableTypes={data?.availableTypes} />
    </Formik>
  );
}
