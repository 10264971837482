import { forwardRef, useMemo } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import CountryFlag, { type ReactCountryFlagProps } from 'react-country-flag';

import type { LiteralNumberUnion } from 'types';

type FlagProps = ReactCountryFlagProps & {
  size?: LiteralNumberUnion<'small' | 'medium' | 'large'>;
};

const FlagIcon = styled(CountryFlag)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
}));

export const Flag = forwardRef<HTMLDivElement, FlagProps>(({ size = 'small', svg = true, ...props }, ref) => {
  const fontSize = useMemo(() => {
    if (typeof size === 'number') return `${size}px`;

    if (size === 'small') return 'var(--icon-fontSize-sm)';

    if (size === 'medium') return 'var(--icon-fontSize-md)';

    return 'var(--icon-fontSize-lg)';
  }, [size]);

  return (
    <Box ref={ref} display="inline-flex">
      <FlagIcon
        {...props}
        svg={svg}
        sx={{
          fontSize: `${fontSize}px !important`,
          lineHeight: `${fontSize} !important`,
          width: `${fontSize} !important`,
          height: `${fontSize} !important`,
        }}
      />
    </Box>
  );
});
