import { useCallback, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { MultiUpdatesAccordion } from 'modules/proxies/modals/components';
import { useBulkDownloadProxyCredentialsMutation } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import type { ProxyCredentialsFormat } from 'store/proxies/types';
import { downloadConfigFile } from 'utils/values';

type CredentialOption = {
  label: string;
  format: ProxyCredentialsFormat;
};

export type DownloadCredentialsModalProps = {
  proxies: ProxyModel[];
  isAllSelected: boolean;
};

const OPTIONS: CredentialOption[] = [
  { label: 'IP:PORT', format: 'IP_PORT' },
  { label: 'IP:PORT:USERNAME:PASSWORD', format: 'IP_PORT_USERNAME_PASSWORD' },
  { label: 'USERNAME:PASSWORD@IP:PORT', format: 'USERNAME_PASSWORD_IP_PORT' },
];

export function DownloadCredentialsModal({ proxies = [], isAllSelected }: DownloadCredentialsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const posthog = usePostHog();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const [downloadCredentials] = useBulkDownloadProxyCredentialsMutation();

  const footer = useMemo(
    () => (
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>
    ),
    [hideModal, t],
  );

  const handleClick = useCallback(
    async (format: ProxyCredentialsFormat) => {
      try {
        const response = await downloadCredentials({
          format,
          ids: isAllSelected ? [] : proxyIds,
        }).unwrap();

        posthog?.capture(posthogEvents.proxy.downloadCredentials.success, {
          format,
          amount: isAllSelected ? 'All' : 'Partial',
        });

        downloadConfigFile(response, 'proxy_list.txt');
      } catch {
        posthog?.capture(posthogEvents.proxy.downloadCredentials.failed, {
          format,
          amount: isAllSelected ? 'All' : 'Partial',
        });
      }
    },
    [downloadCredentials, isAllSelected, posthog, proxyIds],
  );

  return (
    <ModalContainer
      title={t('proxies.modals.downloadCredentials.title')}
      subtitle={t('proxies.modals.downloadCredentials.subtitle')}
      footer={footer}
    >
      <Stack spacing={4}>
        <Collapse in={proxyIds.length > 1 && !isAllSelected} unmountOnExit>
          <MultiUpdatesAccordion
            proxyIds={proxyIds}
            customLabel={t('proxies.my-proxies.multiaction.download', { count: proxyIds.length })}
          />
        </Collapse>

        <Stack spacing={2} divider={<Divider />}>
          {OPTIONS.map(({ label, format }) => (
            <Stack
              key={label}
              direction="row"
              spacing={2}
              alignItems="center"
              onClick={() => handleClick(format)}
              sx={{ cursor: 'pointer' }}
            >
              <Icon name="receive-square" color="var(--mui-palette-text-secondary)" />

              <Typography variant="body-1">{label}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
