import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { useUpgradeThreadsMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/types';
import { ToastManager } from 'utils/toast';

import { UpgradeThreadsForm, type UpgradeThreadsValues, type UpgradeThreadsFormProps } from './UpgradeThreadsForm';

type HandleSubmit = FormikConfig<UpgradeThreadsValues>['onSubmit'];

export type UpgradeThreadsModalProps = Pick<UpgradeThreadsFormProps, 'proxyIds'>;

export function UpgradeThreadsModal({ proxyIds }: UpgradeThreadsModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [upgradeThreads] = useUpgradeThreadsMutation();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const initialValues = useMemo<UpgradeThreadsValues>(() => {
    return { threads: 100 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ threads }) => {
      try {
        await upgradeThreads({ proxyId: proxyIds[0], threads }).unwrap();

        GA_EVENTS.proxyThreadsUpgraded({
          userId: session?.userId,
          value: singleItemPrice,
          proxy_id: proxyIds[0],
          threads,
        });

        posthog?.capture(posthogEvents.proxy.upgradeThreads.success, { proxyId: proxyIds[0], threads });

        ToastManager.success(t('proxies.modals.upgradeThreads.success'));
        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.message && typeof err.message === 'string') {
          ToastManager.error(t(`errors:${err.message}`));
        } else {
          ToastManager.error(t('proxies.modals.upgradeThreads.failure'));
        }

        posthog?.capture(posthogEvents.proxy.upgradeThreads.failed, { proxyId: proxyIds[0], threads });
      }
    },
    [hideModal, posthog, proxyIds, session?.userId, singleItemPrice, t, upgradeThreads],
  );

  return (
    <Formik<UpgradeThreadsValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <UpgradeThreadsForm proxyIds={proxyIds} price={singleItemPrice} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
