import { Children } from 'react';

import type { Components, Theme } from '@mui/material/styles';
import { tableBodyClasses } from '@mui/material/TableBody';
import { tableRowClasses } from '@mui/material/TableRow';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      '--TableCell-backgroundColor': 'inherit',
      '--TableCell-backgroundImage': 'inherit',
      '--TableCell-borderColor': 'var(--mui-palette-divider)',
      '--TableCell-paddingBlock': '16px',
      '--TableCell-paddingInline': '8px',

      '--TableCell-minWidth': 'unset',
      '--TableCell-width': 'unset',

      backgroundColor: 'var(--TableCell-backgroundColor)',
      backgroundImage: 'var(--TableCell-backgroundImage)',

      borderBottom: 'var(--TableCell-borderWidth, 1px) solid var(--TableCell-borderColor)',
      color: 'var(--TableCell-color)',
      fontSize: 'var(--TableCell-fontSize, 14px)',
      fontWeight: 'var(--TableCell-fontWeight, 400)',
      lineHeight: 'var(--TableCell-lineHeight, 20px)',
      letterSpacing: 'var(--TableCell-letterSpacing)',

      paddingBlock: 'var(--TableCell-paddingBlock)',
      paddingInline: 'var(--TableCell-paddingInline)',

      minWidth: 'var(--TableCell-minWidth)',
      width: 'var(--TableCell-width)',

      position: 'relative',
      transition: theme.transitions.create('all', { duration: 225 }),

      paddingRight: 'var(--TableCell-paddingRight, 8px)',
      ...((Children.toArray(ownerState.children).length === 0 ||
        Children.toArray(ownerState.children).includes('\u00A0')) && {
        '--TableCell-paddingBlock': 0,
        '--TableCell-paddingInline': 0,
        width: '0 !important',
      }),

      '&:last-of-type': {
        '--TableCell-paddingRight': '16px',
      },

      [`.${tableBodyClasses.root} .${tableRowClasses.root}:last-of-type &`]: {
        border: 'none',
      },
    }),
  },
};
