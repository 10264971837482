import { useCallback, useMemo, useState } from 'react';

import { Formik, type FormikConfig } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useSession } from 'hooks/useSession';
import { useUpgradeUplinkSpeedMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/types';
import { ToastManager } from 'utils/toast';

import {
  UpgradeUplinkSpeedForm,
  type UpgradeUplinkSpeedValues,
  type UpgradeUplinkSpeedFormProps,
} from './UpgradeUplinkSpeedForm';

type HandleSubmit = FormikConfig<UpgradeUplinkSpeedValues>['onSubmit'];

export type UpgradeUplinkSpeedModalProps = UpgradeUplinkSpeedFormProps;

export function UpgradeUplinkSpeedModal({ proxyIds }: UpgradeUplinkSpeedModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [upgradeUplinkSpeed] = useUpgradeUplinkSpeedMutation();
  const hideModal = useHideModal();
  const { session } = useSession();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  const initialValues = useMemo<UpgradeUplinkSpeedValues>(() => {
    return { uplinkSpeed: 100 };
  }, []);

  const onSubmit = useCallback<HandleSubmit>(
    async ({ uplinkSpeed }) => {
      try {
        await upgradeUplinkSpeed({ proxyId: proxyIds[0], uplinkSpeed }).unwrap();

        GA_EVENTS.proxyBandwidthSpeedUpgraded({
          userId: session?.userId,
          value: singleItemPrice,
          proxy_id: proxyIds[0],
          bandwidthSpeed: uplinkSpeed,
        });

        posthog?.capture(posthogEvents.proxy.upgradeBandwidthSpeed.success, {
          proxyId: proxyIds[0],
          uplinkSpeed,
        });

        ToastManager.success(t('proxies.modals.upgradeBandwidthSpeed.success'));
        hideModal();
      } catch (error) {
        const err = error as AxiosBaseQueryError;

        if (err.message && typeof err.message === 'string') {
          ToastManager.error(t(`errors:${err.message}`));
        } else {
          ToastManager.error(t('proxies.modals.upgradeBandwidthSpeed.failure'));
        }

        posthog?.capture(posthogEvents.proxy.upgradeBandwidthSpeed.failed, {
          proxyId: proxyIds[0],
          uplinkSpeed,
        });
      }
    },
    [hideModal, posthog, proxyIds, session?.userId, singleItemPrice, t, upgradeUplinkSpeed],
  );

  return (
    <Formik<UpgradeUplinkSpeedValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      <UpgradeUplinkSpeedForm proxyIds={proxyIds} price={singleItemPrice} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}
