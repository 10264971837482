import { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';

export type ProxyNoteValues = {
  note: string;
};

export function ProxyNoteForm() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { dirty, isSubmitting, isValid, handleSubmit } = useFormikContext<ProxyNoteValues>();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="confirm" type="submit" fullWidth disabled={!isValid || !dirty} loading={isSubmitting}>
        {t('common:buttons.confirm')}
      </Button>,
    ];
  }, [dirty, hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.note.title.edit')}
      subtitle={t('proxies.modals.note.subtitle')}
      actions={actions}
    >
      <FormikInput name="note" multiline rows={6} fullWidth placeholder={t('common:form.note')} />
    </ModalContainer>
  );
}
