import { Zendesk } from 'components/Zendesk';
import { useAppSelector } from 'store';

function isChatEnabled() {
  const isEnabled = process.env.REACT_APP_ZENDESK_CHAT_ENABLED;

  return isEnabled?.toLowerCase() !== 'false';
}

export function useZendesk() {
  // TODO: We need to resolve this issue asap
  // ! This is just a quick workaround for compiler
  useAppSelector((state) => state.auth);

  return isChatEnabled() ? <Zendesk /> : null;
}
