import { useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';

import { ResultItem } from './ResultItem';
import { useDiagnosticModalContext } from '../context';

type ResultsStepProps = {
  onClose: () => void;
};

export function ResultsStep({ onClose }: ResultsStepProps) {
  const { t } = useTranslation();

  const { results, setStep } = useDiagnosticModalContext();

  const isEmpty = useMemo(() => !results.length, [results]);

  const content = useMemo(() => {
    if (isEmpty) {
      return (
        <Typography variant="body-2" textAlign="center">
          {t('proxies.overview.admin.actions.diagnostic.results.empty')}
        </Typography>
      );
    }

    return results.map((item, i) => <ResultItem key={i} withDivider={i !== results.length - 1} {...item} />);
  }, [isEmpty, results, t]);

  return (
    <Stack spacing={isEmpty ? 4 : 2}>
      <Stack spacing={2} maxHeight={368} overflow="auto">
        {content}
      </Stack>

      <Stack direction="row" spacing={2}>
        <Button fullWidth color="secondary" onClick={onClose}>
          {t('common:buttons.cancel')}
        </Button>

        {isEmpty && (
          <Button fullWidth onClick={() => setStep('setup')}>
            {t('common:buttons.runAgain')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
