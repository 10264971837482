import { useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { SUPPORTED_VPN_MONTHS } from 'modules/orders/v1/OrderVPNPage/consts';
import { BalanceSummary } from 'modules/proxies/modals/components';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

export type ExtendPrice = { months: number; totalPrice: number };

type VPNExtendPeriodFormProps = {
  prices?: ExtendPrice[];
  isLoading: boolean;
};

export type VPNExtendPeriodValues = {
  periodInMonths: number;
};

export function VPNExtendPeriodForm({ prices = [], isLoading }: VPNExtendPeriodFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { isSubmitting, isValid, values, handleSubmit } = useFormikContext<VPNExtendPeriodValues>();

  const price = useMemo(() => {
    return prices?.find(({ months }) => months === values.periodInMonths)?.totalPrice ?? 0;
  }, [prices, values.periodInMonths]);

  const periodOptions = useMemo<Array<Option<number>>>(() => {
    return SUPPORTED_VPN_MONTHS.map((count) => ({ value: count, label: t('common:form.month', { count }) }));
  }, [t]);

  const actions = useMemo(
    () => [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isLoading}>
        {t('common:buttons.extend')}
      </Button>,
    ],
    [hideModal, isSubmitting, isValid, isLoading, t],
  );

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendPeriod.title')}
      subtitle={t('proxies.modals.extendPeriod.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        <FormikAutocomplete
          size="small"
          disableClearable
          options={periodOptions}
          label={t('common:form.timePeriod')}
          name="periodInMonths"
        />

        <Stack spacing={2}>
          <BalanceSummary isLoading={isLoading} price={price} />

          <Divider />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isLoading ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(price)}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
