import type { Components, Theme } from '@mui/material/styles';

import { Icon } from 'components/Icon';

function getSoftVars(color: string): Record<string, string> {
  const vars = {
    '--Chip-soft-background': `var(--mui-palette-${color}-100)`,
    '--Chip-soft-color': `var(--mui-palette-${color}-700)`,
  };

  return vars;
}

function getOutlinedVars(color: string): Record<string, string> {
  const vars = {
    '--Chip-outlined-background': 'var(--mui-palette-common-white)',
    '--Chip-outlined-color': `var(--mui-palette-${color}-main)`,
    '--Chip-outlined-boxShadow': `0px 0px 0px 1px var(--mui-palette-${color}-500)`,
  };

  if (color === 'neutral') {
    vars['--Chip-outlined-boxShadow'] = 'var(--mui-shadows-2)';
  }

  return vars;
}

function getFilledVars(color: string): Record<string, string> {
  const vars = {
    '--Chip-background': `var(--mui-palette-${color}-300)`,
    '--Chip-color': 'var(--mui-palette-text-primary)',
  };

  if (color === 'primary') {
    vars['--Chip-background'] = 'var(--mui-palette-primary-main)';
    vars['--Chip-color'] = 'var(--mui-palette-primary-contrastText)';
  }

  if (color === 'black') {
    vars['--Chip-background'] = 'var(--mui-palette-neutral-950)';
    vars['--Chip-color'] = 'var(--mui-palette-common-white)';
  }

  if (color === 'neutral') {
    vars['--Chip-background'] = 'var(--mui-palette-neutral-100)';
    vars['--Chip-color'] = 'var(--mui-palette-text-primary)';
  }

  return vars;
}

// TODO: Verify every Chip in the application
export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    color: 'neutral',
    variant: 'filled',
    size: 'small',
    deleteIcon: <Icon name="cross" size="small" color="var(--mui-palette-neutral-500)" />,
  },
  styleOverrides: {
    root: {
      '--Chip-borderRadius': '16px',
      '--Chip-minWidth': 'unset',
      '--Chip-paddingBlock': '4px',
      '--Chip-paddingInline': '0px',

      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '8px',

      height: 'auto',

      paddingBlock: 'var(--Chip-paddingBlock)',
      paddingInline: 'var(--Chip-paddingInline)',
      borderRadius: 'var(--Chip-borderRadius)',
      minHeight: 'var(--Chip-minHeight)',
      minWidth: 'var(--Chip-minWidth)',

      textTransform: 'uppercase',
      borderColor: 'var(--Chip-borderColor)',
      backgroundColor: 'var(--Chip-background)',
      color: 'var(--Chip-color)',
    },

    filledPrimary: getFilledVars('primary'),
    filledSecondary: getFilledVars('secondary'),
    filledNeutral: getFilledVars('neutral'),
    filledSuccess: getFilledVars('success'),
    filledError: getFilledVars('error'),
    filledWarning: getFilledVars('warning'),
    filledInfo: getFilledVars('info'),
    filledBlack: getFilledVars('black'),

    soft: {
      backgroundColor: 'var(--Chip-soft-background)',
      color: 'var(--Chip-soft-color)',
    },
    softPrimary: getSoftVars('primary'),
    softSecondary: getSoftVars('secondary'),
    softNeutral: getSoftVars('neutral'),
    softSuccess: getSoftVars('success'),
    softInfo: getSoftVars('info'),
    softWarning: getSoftVars('warning'),
    softError: getSoftVars('error'),

    outlined: {
      backgroundColor: 'var(--Chip-outlined-background)',
      color: 'var(--Chip-outlined-color)',
      boxShadow: 'var(--Chip-outlined-boxShadow)',
      border: 'none',
    },
    outlinedPrimary: getOutlinedVars('primary'),
    outlinedSecondary: getOutlinedVars('secondary'),
    outlinedNeutral: getOutlinedVars('neutral'),
    outlinedSuccess: getOutlinedVars('success'),
    outlinedInfo: getOutlinedVars('info'),
    outlinedWarning: getOutlinedVars('warning'),
    outlinedError: getOutlinedVars('error'),

    label: {
      paddingBlock: 'var(--Chip-label-paddingBlock)',
      paddingInline: 'var(--Chip-label-paddingInline)',

      fontSize: 'var(--Chip-label-fontSize)',
      fontWeight: 'var(--Chip-label-fontWeight)',
      lineHeight: 'var(--Chip-label-lineHeight)',
      letterSpacing: 'var(--Chip-label-letterSpacing)',
    },
    sizeSmall: {
      '--Chip-minHeight': '18px',
      '--Chip-paddingBlock': '2px',
      '--Chip-paddingInline': '0px',
      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '8px',

      '--Chip-label-fontSize': '10px',
      '--Chip-label-fontWeight': '500',
      '--Chip-label-lineHeight': '14px',
      '--Chip-label-letterSpacing': '0.08em',
    },
    sizeMedium: {
      '--Chip-minHeight': '24px',
      '--Chip-paddingBlock': '4px',
      '--Chip-paddingInline': '0px',
      '--Chip-label-paddingBlock': '0px',
      '--Chip-label-paddingInline': '12px',

      '--Chip-label-fontSize': '14px',
      '--Chip-label-fontWeight': '600',
      '--Chip-label-lineHeight': '20px',
      '--Chip-label-letterSpacing': '0',
    },
  },
};
