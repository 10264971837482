import { useMemo, type ReactNode } from 'react';

import Badge, { badgeClasses } from '@mui/material/Badge';
import { boxClasses } from '@mui/material/Box';
import MuiMenuItem, { type MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import Stack, { stackClasses } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon, isIconName, type IconName } from 'components/Icon';
import type { LiteralNodeUnion, Option, Optional } from 'types';

export type OptionItemProps<T, Parameters extends Optional<object> = undefined> = MuiMenuItemProps & {
  option: Option<T, Parameters>;
  isSelected?: boolean;

  isBadgeVisible?: boolean;

  getItemLabel: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;
  getItemSubLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;
  getItemLeftAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;
  getItemRightAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;
};

export function OptionItem<T, Parameters extends Optional<object> = undefined>({
  option,
  isSelected,

  isBadgeVisible,

  getItemLabel,
  getItemSubLabel,
  getItemLeftAdornment,
  getItemRightAdornment,
  ...props
}: OptionItemProps<T, Parameters>) {
  const isHoverable = useMemo(() => !isSelected, [isSelected]);

  const label = useMemo(() => {
    const renderedValue = getItemLabel(option, isSelected);

    if (typeof renderedValue === 'string') {
      return (
        <Typography component="span" variant="body-2" color="inherit">
          {renderedValue}
        </Typography>
      );
    }

    return renderedValue;
  }, [getItemLabel, isSelected, option]);

  const subLabel = useMemo(() => {
    const renderedValue = getItemSubLabel?.(option, isSelected);

    if (typeof renderedValue === 'string') {
      return (
        <Typography component="span" variant="caption" color="text.secondary" whiteSpace="break-spaces">
          {renderedValue}
        </Typography>
      );
    }

    return renderedValue;
  }, [getItemSubLabel, isSelected, option]);

  const leftAdornment = useMemo(() => {
    const adornment = getItemLeftAdornment?.(option, isSelected);

    if (isIconName(adornment)) {
      return <Icon name={adornment} />;
    }

    return adornment;
  }, [getItemLeftAdornment, isSelected, option]);

  const rightAdornment = useMemo(() => {
    const adornment = getItemRightAdornment?.(option, isSelected);

    if (isIconName(adornment)) {
      return <Icon name={adornment} />;
    }

    return adornment;
  }, [getItemRightAdornment, isSelected, option]);

  return (
    <MuiMenuItem
      {...props}
      sx={(theme) => ({
        '--MenuItem-divider': 'none',

        color: 'var(--mui-palette-text-primary)',
        transition: theme.transitions.create('all', { duration: 300 }),

        [`& > .${stackClasses.root} > .${stackClasses.root} > .${badgeClasses.root} >  .${boxClasses.root}`]: {
          transition: theme.transitions.create('all', { duration: 300 }),
        },

        [`& > .${stackClasses.root} > .${stackClasses.root} > .${boxClasses.root}`]: {
          transition: theme.transitions.create('all', { duration: 300 }),
        },

        ...(isSelected && {
          color: 'var(--mui-palette-text-primary)',

          [`& > .${stackClasses.root} > .${stackClasses.root} > .${badgeClasses.root} >  .${boxClasses.root}`]: {
            color: 'var(--mui-palette-primary-main)',
          },

          [`& > .${stackClasses.root} > .${stackClasses.root} > .${boxClasses.root}`]: {
            color: 'var(--mui-palette-primary-main)',
          },
        }),

        ...(isHoverable && {
          '&:hover': {
            color: 'var(--mui-palette-text-primary)',

            [`& > .${stackClasses.root} > .${stackClasses.root} > .${badgeClasses.root} >  .${boxClasses.root}`]: {
              color: 'var(--mui-palette-primary-main)',
            },

            [`& > .${stackClasses.root} > .${stackClasses.root} > .${boxClasses.root}`]: {
              color: 'var(--mui-palette-primary-main)',
            },
          },
        }),
      })}
    >
      <Stack direction="row" flex="1 1 auto" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {!!leftAdornment && <Badge invisible={!isBadgeVisible}>{leftAdornment}</Badge>}

          <Stack>
            {label}
            {subLabel}
          </Stack>
        </Stack>

        {rightAdornment}
      </Stack>
    </MuiMenuItem>
  );
}
