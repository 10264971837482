import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useBlackFridayExperiment } from 'modules/common/hooks';
import { OrderProxyButton } from 'modules/orders/v1/OrderProxyButton';
import { paths } from 'paths';
import { PersistService } from 'utils/persist';

import { Balance } from './Balance';
import { LogoButton } from './LogoButton';
import { NavItem } from './NavItem';
import { SidebarBanners } from './SidebarBanners';
import { useNavigationItems } from './useNavigationItems';

type SidebarProps = {
  isOpen: boolean;
  offset?: number;
  onClose: () => void;
};

export function Sidebar({ isOpen, offset, onClose }: SidebarProps) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const navItems = useNavigationItems();

  const shouldDisplaySoftLogout = useMemo(() => !!PersistService.getImpersonatedUser(), []);

  const { isBlackFridayAllowedForUser, isExperimentLoading } = useBlackFridayExperiment();

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      variant={isMobile ? 'temporary' : 'permanent'}
      PaperProps={{
        sx: {
          bgcolor: 'var(--Sidebar-background)',
          borderRight: 'var(--Sidebar-border)',
          color: 'var(--Sidebar-color)',
          width: 'var(--Sidebar-width)',
        },
      }}
    >
      <Box
        position="relative"
        top={offset}
        sx={(theme) => ({
          transition: theme.transitions.create('all', { duration: 225 }),
        })}
      >
        <LogoButton />

        <Stack spacing={4} p={2}>
          <Stack spacing={2}>
            <SidebarBanners />

            <Balance />

            <OrderProxyButton
              size="large"
              isBlackFridayAllowedForUser={isBlackFridayAllowedForUser}
              isExperimentLoading={isExperimentLoading}
            />
          </Stack>

          <Stack component="nav" spacing={4} flex="1 1 auto">
            {Object.entries(navItems).map(([key, navGroup]) => (
              <Stack key={key} spacing={1}>
                <Typography
                  variant="badge"
                  color="var(--NavGroup-title-color)"
                  textOverflow="ellipsis"
                  noWrap
                  whiteSpace="nowrap"
                >
                  {t(`common:sidebar.${key}`)}
                </Typography>

                <Stack component="ul" sx={{ listStyle: 'none', m: 0, p: 0 }}>
                  {navGroup.map(({ title, ...config }) => (
                    <NavItem key={title} title={title} onClose={onClose} {...config} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          {shouldDisplaySoftLogout && (
            <Button
              fullWidth
              color="secondary"
              startIcon="logout"
              onClick={() => {
                PersistService.remove('X-Switch-User');

                window.location.href = paths.index;
              }}
            >
              {t('common:buttons.resetToMyUser')}
            </Button>
          )}
        </Stack>
      </Box>
    </Drawer>
  );
}
