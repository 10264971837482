import { useCallback, useEffect } from 'react';

export function Zendesk() {
  const loadScript = useCallback(() => {
    const id = 'ze-snippet';

    if (document.getElementById(id)) return;

    const script = document.createElement('script');

    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=d13e103f-27f6-40b8-a586-70f1edfd4b47';
    script.id = id;

    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    loadScript();
  }, [loadScript]);

  return null;
}
