import { forwardRef, useMemo } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';
import type { DashboardMaintenanceWindow } from 'store/common/types';
import type { Nullable } from 'types';

type MaintenanceStatusProps = {
  isVisible: boolean;
  maintenance?: Nullable<DashboardMaintenanceWindow>;
  isReplacementAvailable?: boolean;
  hardcoded?: DashboardMaintenanceWindow;
};

export const MaintenanceStatus = forwardRef<HTMLDivElement, MaintenanceStatusProps>(
  ({ isVisible, isReplacementAvailable, hardcoded, maintenance }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const date = useMemo(() => {
      if (!maintenance) return '';

      const start = dayjs(maintenance.start);
      const end = dayjs(maintenance.end);

      return `${start.format('YYYY-MM-DD HH:mm')} - ${end.format('YYYY-MM-DD HH:mm')}`;
    }, [maintenance]);

    const { title, subtitle, action } = useMemo(() => {
      if (hardcoded) {
        return {
          title: t('common:maintenance.title'),
          subtitle: hardcoded.note,
        };
      }

      if (isReplacementAvailable) {
        return {
          title: t('common:replacement.title'),
          subtitle: maintenance?.replacementNote ?? maintenance?.note ?? t('common:replacement.subtitle'),
          action: (
            <Button component={RouterLink} href={paths.proxies.replace} size="medium">
              {t('common:replacement.action')}
            </Button>
          ),
        };
      }

      return {
        title: t('common:maintenance.title'),
        subtitle: maintenance?.note ?? t('common:maintenance.subtitle', { date }),
      };
    }, [date, hardcoded, isReplacementAvailable, maintenance?.note, maintenance?.replacementNote, t]);

    if (isMobile && isReplacementAvailable) return null;

    return (
      <Box
        position="fixed"
        top={0}
        right={0}
        left={0}
        zIndex={{ xs: 'var(--Maintenance-zIndex-xs)', md: 'var(--Maintenance-zIndex-md)' }}
      >
        <Collapse in={isVisible} mountOnEnter unmountOnExit>
          <Stack
            ref={ref}
            direction={{ xs: 'column', lg: 'row' }}
            spacing={{ xs: 2, lg: 0 }}
            alignItems="center"
            justifyContent={{ xs: 'center', lg: 'space-between' }}
            p={2}
            bgcolor="var(--Maintenance-background)"
          >
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
            >
              <Icon name="danger-dark" />

              <Stack textAlign={{ xs: 'center', lg: 'start' }}>
                <Typography component="span" variant="title" color="var(--mui-palette-neutral-100)">
                  {title}
                </Typography>

                <Typography
                  component="span"
                  variant="body-1"
                  fontSize={{ xs: '14px', lg: '16px' }}
                  lineHeight={{ xs: '20px', lg: '24px' }}
                  color="var(--mui-palette-neutral-100)"
                >
                  {subtitle}
                </Typography>
              </Stack>
            </Stack>

            {action}
          </Stack>
        </Collapse>
      </Box>
    );
  },
);
