import { type MouseEvent, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikNumberInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import type { CreditCardModel } from 'store/accounts/models';
import { CurrencyFormatter } from 'utils/currency';

export type AutoTopUpValues = {
  isEnabled?: boolean;
  amount?: number;
  threshold?: number;
};

type ManageCreditCardFormProps = {
  card: CreditCardModel;
  onCardRemove: (e: MouseEvent<HTMLAnchorElement>) => void;
};

export function ManageCreditCardForm({ card, onCardRemove }: ManageCreditCardFormProps) {
  const { t } = useTranslation();
  const { isSubmitting, isValid, values, handleSubmit, setFieldValue } = useFormikContext<AutoTopUpValues>();
  const hideModal = useHideModal();

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="confirm" type="submit" fullWidth loading={isSubmitting} disabled={!isValid}>
        {t('common:buttons.save')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('account.modals.manage.title')}
      subtitle={t('account.modals.manage.subtitle')}
      actions={actions}
    >
      <Stack spacing={4} divider={<Divider />}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Stack direction="row" spacing={0.4}>
              <Typography variant="title">{t(`common:paymentVendors.${card.brand}`)}</Typography>
              <Typography variant="body-1">{t('account.payments.creditCard.endingWith')}</Typography>
              <Typography variant="title">{card.lastFour}</Typography>
            </Stack>

            <Typography variant="body-2" color="text.secondary">
              {t('account.payments.creditCard.added', {
                date: dayjs.utc(card.addedAt).format('YYYY-MM-DD HH:mm:ss'),
              })}
            </Typography>
          </Stack>

          <Link
            variant="body-2"
            color="var(--mui-palette-error-main)"
            onClick={onCardRemove}
            sx={{ cursor: 'pointer' }}
          >
            {t('common:buttons.remove')}
          </Link>
        </Stack>

        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title">{t('account.payments.creditCard.autoTopUp.title')}</Typography>

            <Switch checked={values.isEnabled} onChange={(_, checked) => setFieldValue('isEnabled', checked)} />
          </Stack>

          <Collapse in={values.isEnabled} unmountOnExit>
            <Stack spacing={2}>
              <FormikNumberInput
                name="amount"
                prefix="$ "
                label={t('common:form.currencyAmount', { currency: 'USD' })}
                placeholder={CurrencyFormatter.format(5)}
                helperText={t('account.modals.manage.amountHelperText')}
              />

              <FormikNumberInput
                name="threshold"
                prefix="$ "
                label={t('common:form.threshold')}
                placeholder={CurrencyFormatter.format(5)}
                helperText={t('account.modals.manage.thresholdHelperText')}
              />
            </Stack>
          </Collapse>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
