import { useCallback, type ReactNode } from 'react';

import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { type SelectChangeEvent, type SelectProps as MuiSelectProps } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import type { IconName } from 'components/Icon';
import { OptionItem } from 'components/OptionItem';
import type { LiteralNodeUnion, Option, Optional } from 'types';

export type SelectProps<T, Parameters extends Optional<object> = undefined> = Omit<
  MuiSelectProps<T>,
  'error' | 'id' | 'name' | 'value' | 'onChange'
> & {
  /**
   * Input's name
   */
  name: string;

  /**
   * Input's label
   */
  label?: string;

  /**
   * Input's placeholder
   */
  placeholder?: string;

  /**
   * Input's options
   */
  options: Array<Option<T, Parameters>>;

  /**
   * On change event callback
   */
  onChange: (value: SelectChangeEvent<T>) => void;

  /**
   * Input's value
   */
  value: T;

  /**
   * Determines if the input should display error
   */
  hasError?: boolean;

  /**
   * Input's error message
   */
  error?: string;

  /**
   * Input's helper text
   */
  helperText?: string;

  /**
   * Determines if the option's label and subLabel should be translated
   * Once enabled - the label and subLabel from options are being wrapped with `TFunction`
   *
   * @default true
   */
  shouldTranslateOptions?: boolean;

  renderCustomValue?: (value: T) => ReactNode;
  renderCustomOption?: (option: Option<T, Parameters>, isSelected: boolean) => ReactNode;

  /**
   * Helper function to render the option's label
   */
  getListItemLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's subLabel
   */
  getListItemSubLabel?: (option: Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's left adornment
   */
  getListItemLeftAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;

  /**
   * Helper function to render the option's right adornment
   */
  getListItemRightAdornment?: (option: Option<T, Parameters>, isSelected?: boolean) => LiteralNodeUnion<IconName>;
};

export function Select<T, Parameters extends Optional<object> = undefined>({
  name,
  label,

  fullWidth,
  disabled,

  options,
  value,
  hasError,
  error,
  helperText,

  shouldTranslateOptions = true,
  variant = 'outlined',

  renderCustomValue,
  renderCustomOption,

  getListItemLabel,
  getListItemSubLabel,
  getListItemLeftAdornment,
  getListItemRightAdornment,
  ...props
}: SelectProps<T, Parameters>) {
  const { t } = useTranslation();

  const renderListItemLabel = useCallback(
    (option: Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemLabel) return getListItemLabel(option, isSelected);

      if (!shouldTranslateOptions) return option.label;

      return typeof option.value === 'number' ? t(option.label, { count: option.value }) : t(option.label);
    },
    [getListItemLabel, shouldTranslateOptions, t],
  );

  const renderListItemSubLabel = useCallback(
    (option: Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemSubLabel) return getListItemSubLabel(option, isSelected);

      if (!option.subLabel) return null;

      if (!shouldTranslateOptions) return option.subLabel;

      return t(option.subLabel);
    },
    [getListItemSubLabel, shouldTranslateOptions, t],
  );

  const renderItem = useCallback(
    (option: Option<T, Parameters>, isSelected: boolean, i: number) => {
      if (renderCustomOption) {
        return (
          <MenuItem key={`select-option-${i}-${option.label}`} value={option.value as string}>
            {renderCustomOption(option, isSelected)}
          </MenuItem>
        );
      }

      return (
        <OptionItem
          key={`select-option-${i}-${option.label}`}
          value={option.value as string}
          option={option}
          isSelected={isSelected}
          getItemLabel={renderListItemLabel}
          getItemSubLabel={renderListItemSubLabel}
          getItemLeftAdornment={getListItemLeftAdornment}
          getItemRightAdornment={getListItemRightAdornment}
        />
      );
    },
    [
      getListItemLeftAdornment,
      getListItemRightAdornment,
      renderCustomOption,
      renderListItemLabel,
      renderListItemSubLabel,
    ],
  );

  const renderValue = useCallback(
    (selected: T) => {
      if (renderCustomValue) return renderCustomValue(selected);

      const option = options.find((o) => isEqual(o.value, selected));

      if (!option) return null;

      return (
        <Stack direction="row" flex="1 1 auto" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center" flex="1 1 auto">
            {getListItemLeftAdornment?.(option, true)}

            <Stack>
              {renderListItemLabel(option, true)}
              {renderListItemSubLabel(option, true)}
            </Stack>
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center">
            {getListItemRightAdornment?.(option, true)}
          </Stack>
        </Stack>
      );
    },
    [
      getListItemLeftAdornment,
      getListItemRightAdornment,
      options,
      renderCustomValue,
      renderListItemLabel,
      renderListItemSubLabel,
    ],
  );

  return (
    <FormControl error={hasError} fullWidth={fullWidth}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <MuiSelect
        {...props}
        id={name}
        name={name}
        aria-labelledby={name}
        value={value}
        error={hasError}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={variant}
        renderValue={renderValue}
      >
        {options.map((option, i) => {
          const isSelected = isEqual(option.value, value);

          return renderItem(option, isSelected, i);
        })}
      </MuiSelect>

      <Collapse in={hasError || !!helperText} unmountOnExit>
        <FormHelperText error={hasError}>{hasError ? error?.toString() : helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
}
