import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { OrderSetupValues } from 'modules/orders/v2/types';
import type { OrderSetupModel, OrderSetupPriceModel } from 'store/orders/v2/models';
import type { OrderStep, PlanID, ServiceID } from 'store/orders/v2/types';
import type { Nullable } from 'types';

type State = {
  /**
   * Determines if details for mobile neither be open or not
   */
  areServiceDetailsOpen: boolean;

  /**
   * Current obtained configuration - used as a workaround for validation purposes
   *
   * @default null
   */
  configuration: Nullable<OrderSetupModel>;

  /**
   * Determines which service details should be currently visible
   *
   * @default null
   */
  currentServiceDetailsId: Nullable<ServiceID>;

  /**
   * User's provided coupon code
   *
   * @default null
   */
  couponCode: Nullable<string>;

  /**
   * Determines if price is loading
   *
   * @default false
   */
  isPriceLoading: boolean;

  /**
   * Currently loaded price
   *
   * @default null
   */
  price: Nullable<OrderSetupPriceModel>;

  /**
   * Determines selected plan
   *
   * @default 'standard'
   */
  selectedPlanId: Nullable<PlanID>;

  /**
   * Determines selected service
   *
   * @default 'static-residential-ipv4'
   */
  selectedServiceId: ServiceID;

  /**
   * The configured setup
   *
   * @default null
   */
  selectedSetup: Nullable<OrderSetupValues>;

  /**
   * Determines current setup step
   *
   * @default 'setup'
   */
  step: OrderStep;
};

type Actions = {
  closeServiceDetails: () => void;
  openServiceDetails: (serviceId: ServiceID) => void;

  setConfiguration: (configuration: Nullable<OrderSetupModel>) => void;

  setCouponCode: (couponCode: Nullable<string>) => void;

  setSelectedServiceId: (serviceId: ServiceID) => void;
  setSelectedPlanId: (planId: Nullable<PlanID>) => void;

  setSelectedSetup: (setup: Nullable<OrderSetupValues>) => void;
  setStep: (step: OrderStep) => void;

  setPrice: (price: Nullable<OrderSetupPriceModel>) => void;
  setPriceLoading: (isPriceLoading: boolean) => void;

  /**
   * Function responsible for setting up the proper values for each service
   */
  selectService: (serviceId: ServiceID, planId: Nullable<PlanID>) => void;

  /**
   * Function responsible for going to the previous step
   */
  onPrevClick: () => void;

  /**
   * Function responsible for going to the next step
   */
  onNextClick: (step: OrderStep, setup?: Nullable<OrderSetupValues>) => void;

  resetOrder: () => void;
};

type OrderSetupState = State & Actions;

const defaultState: State = {
  areServiceDetailsOpen: false,
  currentServiceDetailsId: null,

  configuration: null,

  couponCode: null,

  selectedServiceId: 'static-residential-ipv4',
  selectedPlanId: 'standard',

  selectedSetup: null,
  step: 'setup',

  price: null,
  isPriceLoading: false,
};

export const useOrderState = create<OrderSetupState>()(
  persist(
    (set) => ({
      ...defaultState,

      closeServiceDetails: () => set(() => ({ areServiceDetailsOpen: false, currentServiceDetailsId: null })),
      openServiceDetails: (serviceId) => set(() => ({ areDetailsOpen: true, currentServiceDetailsId: serviceId })),

      setConfiguration: (configuration) => set(() => ({ configuration })),

      setCouponCode: (couponCode) => set(() => ({ couponCode })),

      setSelectedServiceId: (selectedServiceId) => set(() => ({ selectedServiceId })),
      setSelectedPlanId: (selectedPlanId) => set(() => ({ selectedPlanId })),

      setSelectedSetup: (selectedSetup) => set(() => ({ selectedSetup })),
      setStep: (step) => set(() => ({ step })),

      setPrice: (price) => set(() => ({ price })),
      setPriceLoading: (isPriceLoading) => set(() => ({ isPriceLoading })),

      selectService: (serviceId, planId) => {
        return set(() => ({ selectedServiceId: serviceId, selectedPlanId: planId, selectedSetup: null }));
      },

      onPrevClick: () => {
        return set((prev) => ({ ...prev, step: 'setup' }));
      },

      onNextClick: (step, setup) => {
        if (!setup) return set(() => ({ step }));

        return set(() => ({ step, selectedSetup: setup }));
      },

      resetOrder: () => set(defaultState),
    }),
    {
      name: 'order.setup.state',
      version: 0,

      partialize: (state) =>
        // eslint-disable-next-line compat/compat
        Object.fromEntries(Object.entries(state).filter(([key]) => !['areDetailsOpen'].includes(key))),
    },
  ),
);
