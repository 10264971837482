import { useCallback, useState } from 'react';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { RouterLink } from 'components/RouterLink';
import { useTopUpModalContext } from 'modules/billing/modals/TopUpModal/context';
import { useStripeContext } from 'modules/billing/Stripe';
import { paths } from 'paths';
import { isAxiosBaseQueryError } from 'store/utils/errors';
import type { Nullable } from 'types';
import { CurrencyFormatter } from 'utils/currency';

export function PaymentElementForm() {
  const { error, createPaymentMethod, handlePayment, handleSubmit } = useStripeContext();
  const { fee, isTopUpLoading, values, handleTopUp, setStep } = useTopUpModalContext();

  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isFormReady, setFormReady] = useState(false);
  const [internalError, setInternalError] = useState<Nullable<string>>(null);

  /**
   * Handles the back action for PaymentElement form
   */
  const handleBack = useCallback(() => setStep('top-up-form'), [setStep]);

  /**
   * Handles the form submit start
   */
  const handleStart = useCallback(() => {
    setInternalError(null);
    setLoading(true);
  }, []);

  /**
   * Handles the POST /account/billing/top-up request error
   */
  const handleTopUpError = useCallback((topUpError: unknown) => {
    setLoading(false);

    if (!isAxiosBaseQueryError(topUpError)) return;

    setInternalError(topUpError.stripeError ?? null);
  }, []);

  /**
   * Handles the <PaymentElement /> submit success
   */
  const handleSubmitSuccess = useCallback(async () => {
    if (!values) return;

    const paymentMethod = await createPaymentMethod(values.type);

    if (!paymentMethod) {
      return setLoading(false);
    }

    return handleTopUp({
      values,
      paymentMethodId: paymentMethod.id,
      onError: handleTopUpError,
      onSuccess: ({ data }) =>
        handlePayment(data, {
          onFailure: () => setLoading(false),
          onSuccess: () => setStep('status'),
        }),
    });
  }, [createPaymentMethod, handlePayment, handleTopUpError, handleTopUp, setStep, values]);

  return (
    <form
      onSubmit={(event) =>
        handleSubmit({
          event,
          onStart: handleStart,
          onFailure: () => setLoading(false),
          onSuccess: handleSubmitSuccess,
        })
      }
      noValidate
    >
      <Stack spacing={4}>
        {fee && (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="title">{t('common:topUpAmount')}</Typography>

            <Typography variant="body-1" color="neutral.500">
              {CurrencyFormatter.format(fee.finalPrice)}
            </Typography>
          </Stack>
        )}

        <Stack spacing={2}>
          <PaymentElement onReady={() => setFormReady(true)} />

          <Collapse in={Boolean(error ?? internalError)} unmountOnExit>
            <Alert>{internalError ?? error?.message}</Alert>
          </Collapse>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button color="secondary" fullWidth onClick={handleBack} disabled={isLoading || isTopUpLoading}>
            {t('common:back')}
          </Button>

          <Button type="submit" fullWidth disabled={!isFormReady} loading={isLoading || isTopUpLoading}>
            {t('common:buttons.continue')}
          </Button>
        </Stack>

        <Stack alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {t('order.checkout.trust')}
          </Typography>

          <Typography variant="caption" color="text.secondary">
            <Trans i18nKey="order.checkout.stripe">
              All payment methods are safe and processed by
              <Link component={RouterLink} href={paths.external.stripe} target="_blank">
                Stripe
              </Link>
            </Trans>
          </Typography>
        </Stack>
      </Stack>
    </form>
  );
}
