import { useCallback, useMemo, useState } from 'react';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { closeQuiz } from 'store/auth/reducers';

type OptionValue = 'experienced' | 'new';

export function ExperienceCheckModal() {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const [selectedOption, setSelectedOption] = useState<OptionValue>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(closeQuiz());

    return hideModal();
  }, [dispatch, hideModal]);

  const onContinue = useCallback(() => {
    if (selectedOption === 'experienced') {
      navigate({ pathname: paths.order.index, search });
      posthog?.capture(posthogEvents.order.experiencedUser);
    }

    if (selectedOption === 'new') {
      navigate({ pathname: paths.quiz.index, search });
      posthog?.capture(posthogEvents.order.newbieUser);
    }

    hideModal();
  }, [selectedOption, hideModal, navigate, search, posthog]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={onClose}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="continue" fullWidth onClick={onContinue} disabled={!selectedOption}>
        {t('common:buttons.continue')}
      </Button>,
    ];
  }, [onClose, onContinue, selectedOption, t]);

  const options = useMemo(
    () => [
      {
        value: 'experienced',
        title: t('order.modals.experienceCheck.experienced.title'),
        subtitle: t('order.modals.experienceCheck.experienced.subtitle'),
      },
      {
        value: 'new',
        title: t('order.modals.experienceCheck.new.title'),
        subtitle: t('order.modals.experienceCheck.new.subtitle'),
      },
    ],
    [t],
  );

  return (
    <ModalContainer
      title={t('order.modals.experienceCheck.title')}
      subtitle={t('order.modals.experienceCheck.subtitle')}
      actions={actions}
    >
      <RadioGroup name="experience" onChange={(_, value) => setSelectedOption(value as OptionValue)}>
        <Stack spacing={2} divider={<Divider />}>
          {options.map(({ value, title, subtitle }) => (
            <FormControl key={value}>
              <FormControlLabel
                value={value}
                control={<Radio size="medium" sx={{ '--Radio-padding': 0 }} />}
                label={
                  <Stack>
                    <Typography variant="title" color="var(--mui-palette-text-primary)">
                      {title}
                    </Typography>

                    <Typography variant="body-2" color="var(--mui-palette-neutral-500)">
                      {subtitle}
                    </Typography>
                  </Stack>
                }
              />
            </FormControl>
          ))}
        </Stack>
      </RadioGroup>
    </ModalContainer>
  );
}
