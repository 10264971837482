import type { Components, Theme } from '@mui/material/styles';

export const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      '--AccordionDetails-padding': '0px',

      padding: 'var(--AccordionDetails-padding)',
    },
  },
};
