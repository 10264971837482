import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';

export function ReplacementBanner() {
  const { t } = useTranslation();

  return (
    <Stack mt="var(--Content-paddingBlock)" p={2}>
      <Stack spacing={2} bgcolor="var(--mui-palette-neutral-950)" p={3} borderRadius={2} alignItems="center">
        <Icon name="danger-dark" size="large" />

        <Stack spacing={1} textAlign="center">
          <Typography variant="title" color="error.main">
            {t('common:replacement.title')}
          </Typography>

          <Typography variant="body-2" color="var(--mui-palette-neutral-100)">
            {t('common:replacement.subtitle')}
          </Typography>
        </Stack>

        <Button component={RouterLink} href={paths.proxies.replace} size="medium">
          {t('common:replacement.action')}
        </Button>
      </Stack>
    </Stack>
  );
}
