import { useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { FormikAutocomplete } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { BalanceSummary } from 'modules/proxies/modals/components';
import { useGetUpgradeThreadsPriceQuery } from 'store/api';
import type { Option } from 'types';
import { CurrencyFormatter } from 'utils/currency';

export type UpgradeThreadsValues = {
  threads: number;
};

export type UpgradeThreadsFormProps = {
  proxyIds: number[];
  price: number;
  setPriceForSingle: (price: number) => void;
};

export function UpgradeThreadsForm({ proxyIds, price, setPriceForSingle }: UpgradeThreadsFormProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { values, setFieldValue, handleSubmit, isSubmitting, isValid } = useFormikContext<UpgradeThreadsValues>();

  const {
    data: threadsPrices,
    isFetching: isPriceFetching,
    refetch,
  } = useGetUpgradeThreadsPriceQuery(proxyIds[0], { skip: proxyIds.length > 1 });

  const threadsOptions = useMemo<Array<Option<number>>>(() => {
    return (
      threadsPrices?.map(({ thread }) => {
        return { value: thread, label: thread === 0 ? t('common:unlimited') : String(thread) };
      }) ?? []
    );
  }, [t, threadsPrices]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.upgrade')}
      </Button>,
    ];
  }, [hideModal, isPriceFetching, isSubmitting, isValid, t]);

  useEffect(() => {
    // no cached data for new instance, prevent errors when calling refetching without initial fetch
    if (threadsPrices) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (threadsPrices?.length) {
      const foundPrice = threadsPrices.find(({ thread }) => thread === values.threads);

      if (!foundPrice) {
        setFieldValue('threads', threadsPrices[0].thread);
      }
    }
  }, [setFieldValue, threadsPrices, values.threads]);

  useEffect(() => {
    if (threadsPrices) {
      const foundPrice = threadsPrices.find(({ thread }) => thread === values.threads);

      if (foundPrice) {
        setPriceForSingle(+foundPrice.price.toFixed(2));
      }
    }
  }, [setPriceForSingle, threadsPrices, values.threads]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.upgradeThreads.title')}
      subtitle={t('proxies.modals.upgradeThreads.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        <FormikAutocomplete size="small" disableClearable options={threadsOptions} name="threads" />

        <Stack spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isPriceFetching} price={price} />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(price || 0)}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
