import { type ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { Button } from 'components/Button';
import { FormikInput } from 'components/form-partials/formik';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { BalanceSummary, MultiUpdatesAccordion } from 'modules/proxies/modals/components';
import { useGetProxyExtendBandwidthPriceQuery, useGetBulkProxyExtendBandwidthPriceQuery } from 'store/api';
import type { ProxyModel } from 'store/proxies/models';
import { CurrencyFormatter } from 'utils/currency';

export type ExtendBandwidthValues = {
  bandwidth: number;
};

type ExtendBandwidthFormProps = {
  proxies: ProxyModel[];
  setPriceForSingle: (price: number) => void;
};

export function ExtendBandwidthForm({ proxies, setPriceForSingle }: ExtendBandwidthFormProps) {
  const { t } = useTranslation();
  const { values, setFieldValue, handleSubmit, isValid, isSubmitting } = useFormikContext<ExtendBandwidthValues>();
  const hideModal = useHideModal();

  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);

  const { data: extensionPriceSingle, isFetching: isPriceSingleFetching } = useGetProxyExtendBandwidthPriceQuery(
    { proxyId: proxyIds[0], bandwidth: values.bandwidth },
    { skip: proxyIds.length > 1 },
  );

  const { data: extensionPriceBulk, isFetching: isPriceBulkFetching } = useGetBulkProxyExtendBandwidthPriceQuery(
    { ids: proxyIds, trafficInGB: values.bandwidth },
    { skip: proxyIds.length <= 1 },
  );

  const extensionPrice = useMemo(() => {
    return proxyIds.length > 1 ? extensionPriceBulk : extensionPriceSingle;
  }, [extensionPriceBulk, extensionPriceSingle, proxyIds.length]);

  const isPriceFetching = useMemo(
    () => isPriceBulkFetching || isPriceSingleFetching,
    [isPriceBulkFetching, isPriceSingleFetching],
  );

  const priceForSingle = useMemo(() => {
    if (extensionPriceSingle) {
      return +extensionPriceSingle.finalPrice.toFixed(2);
    }

    if (!extensionPrice?.finalPrice) {
      return 0;
    }

    return proxyIds.length > 1 ? +(extensionPrice.finalPrice / proxyIds.length).toFixed(2) : extensionPrice.finalPrice;
  }, [extensionPrice?.finalPrice, extensionPriceSingle, proxyIds.length]);

  const actions = useMemo(() => {
    return [
      <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
        {t('common:buttons.cancel')}
      </Button>,
      <Button key="verify" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || isPriceFetching}>
        {t('common:buttons.extend')}
      </Button>,
    ];
  }, [hideModal, isSubmitting, isValid, t, isPriceFetching]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseFloat(e.target.value.trim());

      setFieldValue('bandwidth', Number.isNaN(parsedValue) ? 1 : parsedValue);
    },
    [setFieldValue],
  );

  useEffect(() => {
    setPriceForSingle(priceForSingle);
  }, [priceForSingle, setPriceForSingle]);

  return (
    <ModalContainer
      component="form"
      containerProps={{ onSubmit: handleSubmit, noValidate: true }}
      title={t('proxies.modals.extendBandwidth.title')}
      subtitle={t('proxies.modals.extendBandwidth.subtitle')}
      actions={actions}
    >
      <Stack spacing={4}>
        {proxyIds.length > 1 && <MultiUpdatesAccordion proxyIds={proxyIds} />}

        <NumericFormat
          fullWidth
          name="bandwidth"
          suffix=" GB"
          value={values.bandwidth}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          customInput={Field}
          component={FormikInput}
        />

        <Stack spacing={2} divider={<Divider />}>
          <BalanceSummary isLoading={isPriceFetching} price={extensionPrice?.finalPrice} />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="title" color="var(--mui-palette-neutral-500)">
              {t('common:form.total')}
            </Typography>

            {isPriceFetching ? (
              <Skeleton height={32} width={100} />
            ) : (
              <Typography variant="headline-2">{CurrencyFormatter.format(extensionPrice?.finalPrice ?? 0)}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ModalContainer>
  );
}
