import type {
  OrderConfigurationPayload,
  OrderConfigurationResponse,
  OrderExecutePayload,
  OrderExecuteResponse,
  OrderPricePayload,
} from 'store/orders/v1/dtos';
import type { AppEndpointBuilder } from 'store/types';

import { toOrderConfigurationModel, toOrderModel, toOrderPriceModel } from './helpers';
import type { OrderConfigurationModel, OrderModel, OrderPriceModel } from './models';

export const orderEndpoints = (builder: AppEndpointBuilder) => ({
  getOrderConfiguration: builder.mutation<OrderConfigurationModel, OrderConfigurationPayload>({
    query: (data) => ({
      url: 'order/configuration',
      method: 'POST',
      params: { errorFormat: 'codes' },
      data,
    }),
    transformResponse: (data: OrderConfigurationResponse) => toOrderConfigurationModel(data),
  }),

  getOrderPrice: builder.mutation<OrderPriceModel, OrderPricePayload>({
    query: (data) => ({
      url: 'order/price',
      method: 'POST',
      params: { errorFormat: 'codes' },
      data,
    }),
    transformResponse: toOrderPriceModel,
  }),

  executeOrder: builder.mutation<OrderExecuteResponse, OrderExecutePayload>({
    query: ({ transactionId, ...data }) => ({
      url: 'order/execute',
      method: 'POST',
      params: { errorFormat: 'codes', eventId: transactionId },
      data,
    }),
    invalidatesTags: ['Account-Balance', 'Proxy-Summary', 'Proxy', 'Session', 'Experiments'],
  }),

  getOrder: builder.query<OrderModel, string>({
    query: (id) => ({
      url: `orders/${id}`,
      method: 'GET',
    }),
    transformResponse: toOrderModel,
  }),
});
