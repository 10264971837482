import { useCallback, useEffect, useMemo, useState } from 'react';

import type { SelectChangeEvent } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Select } from 'components/form-partials/base';
import { Icon } from 'components/Icon';
import { SelectionBox } from 'components/SelectionBox';
import { Tooltip } from 'components/Tooltip';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { toPaymentOption } from 'modules/billing/helpers';
import { useTopUpModalContext } from 'modules/billing/modals/TopUpModal/context';
import type { TopUpValues, TopUpOption } from 'modules/billing/modals/TopUpModal/types';
import type { TopUpPaymentOptionType } from 'modules/billing/types';
import type { Nullable, Option } from 'types';
import { CurrencyFormatter, CurrencyFormatterOptionalDecimals } from 'utils/currency';

import { AmountField } from './AmountField';
import { PaymentMethod } from './PaymentMethod';

const selectionBoxOptions = [10, 25, 50, 100, 200].map<Option<number>>((value) => ({
  value,
  label: CurrencyFormatterOptionalDecimals.format(value),
}));

type TopUpFormProps = {
  options: TopUpOption[];
};

export function TopUpForm({ options }: TopUpFormProps) {
  const { t } = useTranslation();
  const { isValid, values, setFieldValue, setValues } = useFormikContext<TopUpValues>();
  const { fee, isCardLoading, isFeeLoading, isTopUpLoading, handleCancelModal } = useTopUpModalContext();

  const [selectedPriceOption, setSelectedPriceOption] = useState<Nullable<number>>(null);

  const { isPaymentMethodEnabled } = useFeatureFlag();

  const enabledOptions = useMemo<Array<Option<TopUpPaymentOptionType>>>(
    () =>
      options
        .filter(({ type }) => isPaymentMethodEnabled(toPaymentOption(type)))
        .map(({ paymentFee, type }) => ({ label: type, subLabel: paymentFee, value: type })),
    [isPaymentMethodEnabled, options],
  );

  const subtotal = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.subtotal), [fee]);
  const paymentFee = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.paymentFee), [fee]);
  const formattedPrice = useMemo(() => CurrencyFormatter.format(!fee ? 0 : fee.finalPrice), [fee]);

  const onPaymentMethodSelect = useCallback(
    (e: SelectChangeEvent<TopUpPaymentOptionType>) => {
      const selectedOption = enabledOptions.find(({ value }) => value === e.target.value);

      if (!selectedOption) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      setValues((prev) => ({ ...prev, type: selectedOption.value, paymentFee: selectedOption.subLabel as string }));
    },
    [enabledOptions, setValues],
  );

  useEffect(() => {
    const foundAmountOption = selectionBoxOptions.find(({ value }) => value === values.amount);

    if (foundAmountOption?.value !== selectedPriceOption) {
      setSelectedPriceOption(values.amount);
    }
  }, [selectedPriceOption, values.amount]);

  return (
    <Form noValidate>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <AmountField />

          <SelectionBox
            size="small"
            data={selectionBoxOptions}
            value={selectedPriceOption}
            onItemClick={(option) => {
              setSelectedPriceOption(option.value);
              setFieldValue('amount', option.value);
            }}
            getOptionLabel={(item) => item.label}
          />
        </Stack>

        <Select
          name="type"
          label={t('common:form.paymentMethod')}
          value={values.type}
          onChange={onPaymentMethodSelect}
          options={enabledOptions}
          renderCustomOption={(option, isSelected) => <PaymentMethod {...option} isSelected={isSelected} />}
          renderCustomValue={(value) => {
            const option = enabledOptions.find((o) => o.value === value);

            if (!option) return null;

            return <PaymentMethod {...option} />;
          }}
          sx={{ '--Input-paddingBlock': '8px', '--Input-paddingInline': '12px' }}
        />

        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body-2" color="neutral.500">
              {t('common:form.subtotal')}
            </Typography>

            <Typography variant="body-2">{subtotal}</Typography>
          </Stack>

          <Collapse in={Boolean(fee && fee.paymentFee !== 0)} unmountOnExit>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body-2" color="neutral.500">
                  {t('common:form.paymentFee', { paymentFee: values.paymentFee })}
                </Typography>

                <Tooltip title={t('common:tooltips.fee')}>
                  <IconButton>
                    <Icon name="question" color="var(--mui-palette-neutral-500)" size="small" />
                  </IconButton>
                </Tooltip>
              </Stack>

              <Typography variant="body-2">{paymentFee}</Typography>
            </Stack>
          </Collapse>

          {!!fee && <Divider />}

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="title" color="neutral.500">
              {t('common:form.total')}
            </Typography>

            <Typography variant="headline-2">{formattedPrice}</Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button color="secondary" fullWidth onClick={handleCancelModal} disabled={isTopUpLoading}>
            {t('common:buttons.cancel')}
          </Button>

          <Button
            type="submit"
            fullWidth
            loading={isCardLoading || isFeeLoading || isTopUpLoading}
            disabled={!isValid || !fee}
          >
            {t('common:buttons.continue')}
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
